import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import ListField from './fields/ListField'
import CheckBoxField from './fields/CheckBoxField'
import MultipleAutocompleteField from './fields/MultipleAutocompleteField'
import { type Control } from 'react-hook-form'
import { type GetUserQuery } from '@gqlTypes'

interface Props {
	control: Control
	defaultValues?: GetUserQuery['getUser']
	availableOffers: { value: string; label: string }[]
	availablePositions: { value: string; label: string }[]
}

export function RestrictionsCard({ control, defaultValues, availablePositions, availableOffers }: Props): JSX.Element {
	const { t } = useTranslation()
	const locationItems = defaultValues?.organization?.locations.map((location: string) => ({
		id: location,
		name: location
	}))
	const businessUnitItems = defaultValues?.organization?.businessUnits.map((bu: string) => ({ id: bu, name: bu }))

	return (
		<CardLayout title={t('organizationUsers:details.cards.restrictions')}>
			<TableLayout>
				<CheckBoxField
					name="isRestrictedAccess"
					control={control}
					defaultValue={defaultValues?.isRestrictedAccess ?? undefined}
				/>
				<ListField
					isHiddenOnEmpty
					isClearable
					name="location"
					control={control}
					items={locationItems ?? []}
					defaultValue={defaultValues?.location ?? undefined}
				/>
				<ListField
					isHiddenOnEmpty
					isClearable
					name="businessUnit"
					control={control}
					items={businessUnitItems ?? []}
					defaultValue={defaultValues?.businessUnit ?? undefined}
				/>
				<MultipleAutocompleteField
					isHiddenOnEmpty
					name="offersAccessIds"
					control={control}
					items={availableOffers}
					defaultValue={defaultValues?.offersAccess ?? []}
					selectableLimit={10}
				/>
				<MultipleAutocompleteField
					isHiddenOnEmpty
					name="positionsAccessIds"
					control={control}
					items={availablePositions}
					defaultValue={defaultValues?.positionsAccess ?? []}
					selectableLimit={10}
				/>
			</TableLayout>
		</CardLayout>
	)
}

import { Table } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	children: ReactNode
}

export const TableLayout = ({ children }: Props): ReactElement => {
	return (
		<Table>
			<tbody>{children}</tbody>
		</Table>
	)
}

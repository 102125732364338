import { type GetIntegrationsProgressionsQuery } from '@gqlTypes'
import { Card, CardContent, Chip } from '@mui/material'
import { type ReactElement } from 'react'
import { LineChart, Line, ResponsiveContainer, YAxis, Tooltip } from 'recharts'
import theme from '@ui/theme'
import { ProgressionTooltip } from './ProgressionTooltip'

interface Props {
	progression: GetIntegrationsProgressionsQuery['getIntegrationsProgressions']['progressions'][0]
}

export const ProgressionCard = ({ progression }: Props): ReactElement | null => {
	if (progression.filledScores.length === 0) return null
	return (
		<Card elevation={3} sx={{ width: 270, height: 250, borderRadius: 3, overflow: 'visible' }}>
			<CardContent sx={{ height: '100%' }}>
				<Chip
					size="small"
					label={progression.skill.label}
					sx={{ '& .MuiChip-label': { color: 'common.white' }, backgroundColor: progression.skill.color, mb: 2 }}
				/>
				<ResponsiveContainer height={180}>
					<LineChart data={progression.filledScores}>
						<YAxis type="number" domain={[0, 10]} hide />
						<Line
							type="linear"
							dataKey="autoEvaluationScore"
							stroke={theme.palette.primary.main}
							strokeWidth={2}
							strokeDasharray="4 4 2"
							dot={false}
						/>
						<Line
							type="linear"
							dataKey="evaluationScore"
							stroke={theme.palette.primary.main}
							strokeWidth={3}
							dot={false}
						/>
						<Tooltip
							wrapperStyle={{ zIndex: 100 }}
							allowEscapeViewBox={{ x: false, y: true }}
							position={{ x: 0, y: 200 }}
							content={(data) => <ProgressionTooltip active={data.active} payload={data.payload} />}
						/>
					</LineChart>
				</ResponsiveContainer>
			</CardContent>
		</Card>
	)
}

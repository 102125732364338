import { type TrackType, useExportTracksLazyQuery, useMeQuery } from '@gqlTypes'
import { IconButton } from '@mui/material'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import DownloadIcon from '@mui/icons-material/Download'

interface Props {
	trackType: TrackType
	trackId: string
}

export const TrackExport = ({ trackType, trackId }: Props): ReactElement | null => {
	const variables = { trackType, trackIds: [trackId] }
	const { data: me } = useMeQuery()

	const [exportTracks, { loading }] = useExportTracksLazyQuery({
		onCompleted: (res) => {
			const blob = new Blob([res.exportTracks.fileContent], { type: 'application/json' })
			const href = URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = href
			link.download = res.exportTracks.fileName
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			URL.revokeObjectURL(href)
		}
	})

	const isSuperAdmin = me?.me?.isSuperAdmin ?? false
	if (me == null || !isSuperAdmin) return null

	return (
		<LocalLoader load={loading}>
			<IconButton
				style={{ cursor: 'pointer' }}
				// eslint-disable-next-line
        onClick={() => exportTracks({ variables })}
			>
				<DownloadIcon />
			</IconButton>
		</LocalLoader>
	)
}

import TrackButton from './TrackButton'
import { ROUTE_TRACKS_OFFER } from '../../../../router/routes'
import { useTranslation } from 'react-i18next'

const CandidacyTrackButton = (): JSX.Element => {
	const { t } = useTranslation()

	return <TrackButton title={t('tracks:list.bar.filter.candidacy')} url={ROUTE_TRACKS_OFFER} />
}

export default CandidacyTrackButton

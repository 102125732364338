import { type ReactElement } from 'react'
import { Box, Chip, type ChipProps, Stack, type StackProps } from '@mui/material'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props extends StackProps {
	items: Item[]
	isLoading?: boolean
	withOrder?: boolean
	withCount?: boolean
	onValueChange?: (id: string) => void
	selectedItems?: string[]
	isEditable?: boolean
	chipProps?: ChipProps
	stackProps?: StackProps
	isSortedAlphabetically?: boolean
}
interface Item {
	id: string
	label: string
	color?: string
	isPositive?: boolean
	count?: number
}

export const ChipList = ({
	items,
	isLoading = false,
	withOrder = false,
	withCount = false,
	onValueChange,
	selectedItems = [],
	isEditable = true,
	chipProps = {},
	isSortedAlphabetically = false,
	...stackProps
}: Props): ReactElement | null => {
	if (isLoading) return <LocalLoader />
	if (items.length === 0) return null

	const displayedItems = [...items]
	if (isSortedAlphabetically) displayedItems.sort((a, b) => a.label.localeCompare(b.label))

	return (
		<Stack gap={1} direction="row" sx={{ flexWrap: 'wrap' }} {...stackProps}>
			{displayedItems.map((item) => {
				const isSelected = selectedItems.includes(item.id)
				const selectedIndex = selectedItems.indexOf(item.id) + 1
				const color = item.isPositive == null ? item.color : item.isPositive ? 'secondary.main' : 'warning.main'
				return (
					<Chip
						key={item.id}
						label={
							(withOrder && isSelected) || withCount ? (
								<Stack direction="row" gap="3px">
									<Box sx={{ backgroundColor: '#00000033', borderRadius: 10, width: '17px', textAlign: 'center' }}>
										{withCount ? item.count : selectedIndex}
									</Box>
									{item.label}
								</Stack>
							) : (
								item.label
							)
						}
						variant={isSelected ? 'filled' : 'outlined'}
						sx={{ mx: isSelected ? '1px' : 0, backgroundColor: isSelected ? color : null }}
						color="primary"
						onClick={() => {
							if (!isEditable) return
							if (onValueChange != null) onValueChange(item.id)
						}}
						clickable={isEditable}
						{...chipProps}
					/>
				)
			})}
		</Stack>
	)
}

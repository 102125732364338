import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Center from '../styles/Center'
import { LoadingButton } from '@mui/lab'

interface Props {
	hasNextPage: boolean
	isLoading: boolean
	onClick: () => void
}

export const ButtonMore = ({ hasNextPage, isLoading, onClick }: Props): ReactElement | null => {
	const { t } = useTranslation()

	if (!hasNextPage) return null

	return (
		<Center>
			<LoadingButton variant="outlined" loading={isLoading} onClick={onClick}>
				{t('translation:moreResults')}
			</LoadingButton>
		</Center>
	)
}

import { useGetOfferOrganizationsQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const OfferOrganizationFilter = (): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useGetOfferOrganizationsQuery()
	if (isLoading || data?.getOfferOrganizations == null) return null
	const status = data.getOfferOrganizations.map((status) => ({
		value: status.organizationId,
		label: status.organizationName
	}))

	return (
		<InputFilter keyName="organizationId" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter options={status} label={t('lists:filter.company.label')} />
		</InputFilter>
	)
}

import { type ReactElement } from 'react'
import { Dialog, DialogContent, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Props {
	isOpen: boolean
	setIsOpen: (state: boolean) => void
	route: string
	firstname: string
}

export const LikeByOrgaModal = ({ isOpen, setIsOpen, route, firstname }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const history = useHistory()
	const handleClose = (): void => {
		setIsOpen(false)
		history.push(route)
	}

	if (!isOpen) return null

	return (
		<Dialog open fullWidth maxWidth="lg" onClose={handleClose} PaperProps={{ sx: { borderRadius: 3 } }}>
			<DialogContent>
				<Stack direction="column" justifyContent="center" textAlign="center" gap={2}>
					<img src="/assets/img/likedByOrganization.png" style={{ objectFit: 'contain', height: 350 }} />
					<Typography fontWeight="bold" fontSize={30} color={'lightBlue.light'}>
						{t('offers:jobMatching.browser.popup.likedByOrganization.title')}
					</Typography>
					<Typography fontSize={20}>
						{t('offers:jobMatching.browser.popup.likedByOrganization.subtitle', { firstname })}
					</Typography>
					<Stack direction="row" justifyContent="center" gap={2}>
						<Button variant="contained">{t('offers:jobMatching.browser.popup.likedByOrganization.discussion')}</Button>
						<Button variant="contained" color={'secondary'} onClick={handleClose}>
							{t('offers:jobMatching.browser.popup.likedByOrganization.continue')}
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

import { type ReactElement } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import { useGetOfferListQuery } from '@gqlTypes'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { OfferCard } from './card/OfferCard'
import { OfferListToolbar } from './OfferListToolbar'
import { useFilters } from '@shared/hooks/useFilters'
import { EmptyOfferList } from './EmptyOfferList'

export const OfferList = (): ReactElement => {
	const { t } = useTranslation()
	useDocumentTitle(t('offers:documentTitle'))
	const { filters } = useFilters()
	const variables = { cursor: '', limit: 12, filters }
	const { loading, data, refetch, fetchMore } = useGetOfferListQuery({
		variables,
		fetchPolicy: 'cache-and-network'
	})
	useDefaultFilter({ refetch })

	const result = data?.getOfferList
	const nodes = result?.nodes ?? []
	const cursor = result?.cursor ?? ''
	const hasNextPage = result?.hasNextPage ?? false
	const count = result?.count ?? 0

	return (
		<Container maxWidth="xl">
			<CardList
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				toolbar={<OfferListToolbar refetch={refetch} />}
				emptyStateLabel={t('offers:empty')}
				renderItem={(item, isSelected, selectRow) => (
					<OfferCard key={item.id} offer={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={t('offers:title', { count })}
				emptyComponent={<EmptyOfferList />}
			/>
		</Container>
	)
}

import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useGetMacroSoftSKillsQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const SoftskillFilter = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useGetMacroSoftSKillsQuery()
	const items = (data?.getMacroSoftSKills ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.label ?? ''
	}))
	if (items.length <= 1 || loading) return null

	return (
		<InputFilter keyName={'topSoftSkillIds'} operation={filterOperation.CONTAIN_ANY}>
			<AutoCompleteFilter
				options={items}
				label={t('offers:resumeLibrary.filters.softskills.label')}
				allValueLabel={t('offers:resumeLibrary.filters.softskills.allLabel')}
				width="100%"
				multiple
			/>
		</InputFilter>
	)
}

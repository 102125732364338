import { useTranslation } from 'react-i18next'
import { OrganizationType, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'

export const PromotionFilter: React.FC = () => {
	const { t } = useTranslation()

	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return null
	const promotions = data.me.organization.promotions.map((promotion) => ({ value: promotion, label: promotion }))
	const isCompany = data.me.organization.type !== OrganizationType.School
	if (promotions.length === 0 || isCompany) return null

	return (
		<InputFilter keyName="promotion" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={promotions}
				defaultValue=""
				label={t('lists:filter.promotion.label')}
				allValueLabel={t('lists:filter.promotion.allLabel')}
				width="100%"
			/>
		</InputFilter>
	)
}

import { JobMatchingStatusId } from '@gqlTypes'
import { type ReactElement } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import { useTranslation } from 'react-i18next'
import { JobMatchingBrowserActionButton } from './JobMatchingBrowserActionButton'

interface Props {
	jobMatchingStatusId: JobMatchingStatusId
	onClick: (nextState: LikeNextStatus) => void
}

export type LikeNextStatus = JobMatchingStatusId.LikedByOrganization | JobMatchingStatusId.Selected

const states: Partial<Record<JobMatchingStatusId, LikeNextStatus>> = {
	[JobMatchingStatusId.Proposed]: JobMatchingStatusId.LikedByOrganization,
	[JobMatchingStatusId.LikedByCandidate]: JobMatchingStatusId.LikedByOrganization,
	[JobMatchingStatusId.LikedByOrganization]: JobMatchingStatusId.Selected,
	[JobMatchingStatusId.Matched]: JobMatchingStatusId.Selected,
	[JobMatchingStatusId.Applied]: JobMatchingStatusId.Selected
}

export const LikeButton = ({ jobMatchingStatusId, onClick }: Props): ReactElement | null => {
	const { t } = useTranslation()

	const nextStatus = states[jobMatchingStatusId]
	if (nextStatus == null) return null

	return (
		<JobMatchingBrowserActionButton
			title={t(`offers:jobMatching.browser.nextAction.${nextStatus}`)}
			color="success"
			onClick={() => {
				onClick(nextStatus)
			}}
		>
			{nextStatus === JobMatchingStatusId.LikedByOrganization ? <ThumbUpIcon /> : <DoneIcon />}
		</JobMatchingBrowserActionButton>
	)
}

import { useState } from 'react'

export const useArrayItem = <T>(index = 0, array: T[]): [currentItem: T, nextItem: () => T, previousItem: () => T] => {
	const [currentIndex, setCurrentIndex] = useState(index)

	const currentItem = array[currentIndex]

	const nextItem = (): T => {
		const newIndex = currentIndex + 1
		const curratedIndex = newIndex > array.length - 1 ? 0 : newIndex
		setCurrentIndex(curratedIndex)
		return array[curratedIndex]
	}

	const previousItem = (): T => {
		const newIndex = currentIndex - 1
		const curratedIndex = newIndex < 0 ? array.length - 1 : newIndex
		setCurrentIndex(curratedIndex)
		return array[curratedIndex]
	}

	return [currentItem, previousItem, nextItem]
}

import { IconButton, Tooltip } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'

interface Props {
	color: 'error' | 'success'
	title: string
	onClick: () => void
	children: ReactNode
}

export const JobMatchingBrowserActionButton = ({ color, title, onClick, children }: Props): ReactElement | null => {
	return (
		<Tooltip title={title}>
			<IconButton size="large" color={color} sx={{ backgroundColor: 'white' }} onClick={onClick}>
				{children}
			</IconButton>
		</Tooltip>
	)
}

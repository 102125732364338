import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { LabelField } from '@domains/interviews/create/LabelField'
import { DateField } from '@domains/interviews/create/DateField'
import { InterviewCustomFields } from '../create/InterviewCustomFields'
import { FormProvider, useForm } from 'react-hook-form'
import { DialogSection } from '@shared/components/modal/dialogSection'
import {
	type CreateManyInterviewsMutationVariables,
	type FilterInput,
	type IntegrationCategory,
	type TrackType,
	useCreateManyInterviewsMutation,
	useMeQuery
} from '@gqlTypes'
import Center from '@shared/components/styles/Center'
import { InterviewTypeField } from '@domains/interviews/create/InterviewTypeField'
import { Severities } from '@application/snackbar/Severities'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { ErrorHandler } from '#helpers/error.helper'
import { useFilters } from '@shared/hooks/useFilters'
import { type ReactElement } from 'react'
import { searchQuery } from '@features/lists/components/Search'
import { LoadingButton } from '@mui/lab'

interface Props {
	isOpen: boolean
	setOpen: (isOpen: boolean) => void
	ids: string[]
	trackType: TrackType
	onCreation: (severity: Severities, message: string) => void
	integrationCategory: IntegrationCategory
}

interface FormInputs {
	targetDate: string
	label: string
	interviewTypeId: string
	customFields: Record<string, string>
}

export const MassInterviewsCreationModal = ({
	isOpen,
	setOpen,
	ids,
	trackType,
	onCreation,
	integrationCategory
}: Props): ReactElement => {
	const [createManyInterviews, { loading }] = useCreateManyInterviewsMutation()
	const { t } = useTranslation()
	const { filters } = useFilters()
	const { loading: isMeLoading, data } = useMeQuery()

	const interviewTypeFilter = filters.find((filter: FilterInput) => filter.key.startsWith('interviewTypeState'))
	const interviewTypeId = interviewTypeFilter?.key.split('.').slice(-1)[0]
	const defaultValues = { interviewTypeId, label: '', customFields: {} }
	const formMethods = useForm<FormInputs>({ mode: 'onChange', defaultValues })
	const { formState, handleSubmit, errors } = formMethods

	if (isMeLoading || data?.me == null) return <LocalLoader />
	const interviewTypes = data.me.organization.interviewTypes
	const hasManyTypes = interviewTypes.length > 1
	const defaultType = interviewTypes[0]
	const isInterviewLabelAvailable = data.me.organization.isInterviewLabelAvailable

	const onSubmit = (data: FormInputs): void => {
		;(async () => {
			const { targetDate, label, interviewTypeId, customFields } = data
			const variables: CreateManyInterviewsMutationVariables = {
				createInterviewInput: {
					targetDate,
					label: label ?? '',
					customFields: customFields ?? {},
					interviewTypeId: hasManyTypes ? interviewTypeId : defaultType.id
				},
				userIntegrationsIds: ids,
				trackType,
				filters,
				integrationCategory,
				searchQuery: searchQuery()
			}
			const result = await createManyInterviews({ variables })
			const hasErrors = result.errors != null
			const createdInterviewsCount = result.data?.createManyInterviews ?? 0
			const severity = hasErrors ? Severities.error : Severities.success
			const message = hasErrors
				? t('modal:massInterviewCreation.notification.error')
				: t('modal:massInterviewCreation.notification.success', { count: createdInterviewsCount })
			onCreation(severity, message)
			setOpen(false)
		})().catch(ErrorHandler)
	}

	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setOpen(false)
			}}
		>
			<FormProvider {...formMethods}>
				<DialogTitle>
					<>{t('modal:massInterviewCreation.title')}</>
				</DialogTitle>
				<Divider />
				<form>
					{hasManyTypes ? (
						<DialogSection>
							<InterviewTypeField />
						</DialogSection>
					) : null}
					{isInterviewLabelAvailable ? (
						<DialogSection>
							<LabelField />
						</DialogSection>
					) : null}
					<DialogSection>
						<DateField name="targetDate" label={t('drawer:interview.date')} />
					</DialogSection>

					<DialogSection>
						<InterviewCustomFields errors={errors} />
					</DialogSection>
					<Center>
						<LoadingButton
							loading={loading}
							variant="contained"
							onClick={handleSubmit(onSubmit)}
							disabled={!formState.isValid}
						>
							{t('modal:massInterviewCreation.create')}
						</LoadingButton>
					</Center>
				</form>
			</FormProvider>
		</Dialog>
	)
}

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { TextSection } from '../sections/TextSection'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { type ReactElement } from 'react'

interface Props {
	isOpen: boolean
	setOpen: (isOpen: boolean) => void
	reminderCount: number
	itemCount: number
	isLoading: boolean
	sendReminders: () => Promise<void>
	translationKey: 'integration' | 'interview'
	isSaving: boolean
}

export const MassReminderModal = ({
	isOpen,
	setOpen,
	reminderCount,
	itemCount,
	isLoading,
	sendReminders,
	translationKey,
	isSaving
}: Props): ReactElement => {
	const { t } = useTranslation(['translation', 'lists'])
	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				setOpen(false)
			}}
		>
			<DialogTitle>
				{t(`modal:massReminder.${translationKey}.title`, { count: reminderCount, total: itemCount })}
			</DialogTitle>
			<Divider />
			{isLoading ? (
				<DialogSection style={{ height: 415.5 }}>
					<LocalLoader />
				</DialogSection>
			) : (
				<TextSection
					onValidate={sendReminders}
					bodyText={
						reminderCount === 0
							? t(`modal:massReminder.${translationKey}.unneeded`)
							: t(`modal:massReminder.${translationKey}.description`, { uncompleted: reminderCount })
					}
					buttonText={t('modal:massReminder.send')}
					disabled={reminderCount === 0}
					isSaving={isSaving}
				/>
			)}
		</Dialog>
	)
}

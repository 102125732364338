import { Divider, Select, type SelectProps } from '@mui/material'
import CountryCodes, { FavoriteCountryIndices, IndexByCode } from './coutry-codes'
import { Emoji } from '@ui/core/Emoji'
import { CountryCodeItem } from './CountryCodeItem'
import { type ReactElement, type ReactNode } from 'react'

export const CountryCodeSelector = (props: SelectProps<string>): ReactElement => {
	const favoritesItems = FavoriteCountryIndices.map((el) => CountryCodes[el]).map((el) => (
		<CountryCodeItem key={'favorite' + el.code} {...el} />
	))
	const items = CountryCodes.filter((_, i) => !FavoriteCountryIndices.includes(i)).map((el) => (
		<CountryCodeItem key={el.code} {...el} />
	))

	const renderValue = (val: string): ReactNode => {
		const index = IndexByCode[val]
		const country = CountryCodes[index]
		if (country == null) return <></>

		return <Emoji>{country.emoji ?? country.name}</Emoji>
	}

	return (
		<Select
			{...props}
			renderValue={renderValue}
			variant="outlined"
			sx={{
				boxShadow: 'none',
				'.MuiOutlinedInput-notchedOutline': { borderWidth: '0 !important' }
			}}
		>
			{favoritesItems}
			<Divider />
			{items}
		</Select>
	)
}

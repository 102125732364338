export function calculateCompletion(steps) {
	if (!steps) return 0

	const stepsValue = Object.values(steps).filter((step) => step === true || step === false)

	const totalSteps = stepsValue.length
	if (totalSteps === 0) return 0

	const completeSteps = stepsValue.filter((step) => step === true).length
	return Math.round((completeSteps / totalSteps) * 100)
}

import { type ReactElement, type ReactNode } from 'react'
import { Stack } from '@mui/material'

interface Props {
	children: ReactNode
}

export const FiltersContent = ({ children }: Props): ReactElement => {
	return <Stack sx={{ width: 330, gap: 2, m: 3 }}>{children}</Stack>
}

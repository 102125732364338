import Button from '@mui/material/Button'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	value: string
	field: string
	onValidate: (field: string, value: string) => void
}

export const ValidateButton = ({ value, field, onValidate }: Props): ReactElement => {
	const { t } = useTranslation(['lists'])

	return (
		<Button
			disabled={value === ''}
			onClick={() => {
				onValidate(field, value)
			}}
		>
			{value === '' ? t('modal:massUpdate.update') : t('modal:massUpdate.update')}
		</Button>
	)
}

import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'
import { InputTextFieldForm } from '@shared/components/forms/fields/inputTextFieldForm'

export const InterviewInformationField = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<InputTextFieldForm
			fullWidth
			name="information"
			label={t('drawer:interview.information')}
			size="small"
			multiline
			rows={3}
		/>
	)
}

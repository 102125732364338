import { type ReactElement } from 'react'
import { type GetUserInterviewListNodesFragment } from '@gqlTypes'
import { Stack } from '@mui/material'
import { StepsOverview } from '@features/lists/components/cells/StepsOverview'
import { ROUTE_INTERVIEW_DETAIL, generateRoute } from '../../../router/routes'
import { useTranslation } from 'react-i18next'
import { UserCard } from '@shared/components/cards/UserCard'
import { InterviewCardActions } from './InterviewCardActions'
import { InterviewAssessor } from './InterviewAssessor'
import { InterviewLabel } from './InterviewLabel'
import { InterviewTargetDate } from './InterviewTargetDate'

interface Props {
	interview: GetUserInterviewListNodesFragment
	isSelected?: boolean
	selectRow?: () => void
	isInterviewLabelAvailable?: boolean
	children?: ReactElement
}

export const InterviewCard = ({
	interview,
	isSelected,
	selectRow,
	isInterviewLabelAvailable = false,
	children
}: Props): ReactElement => {
	const steps = [...interview.steps].map(({ id, icon, organizationLabel }) => ({
		isCompleted: interview.stepsState[id],
		icon,
		label: organizationLabel
	}))
	const { t } = useTranslation()
	const isMissedInterview = interview.missedInterview ?? false
	const { typeLabel, label, targetDate, interviewDate, isScheduled, hasStartTime } = interview

	const renderActions = (isHovered: boolean): ReactElement => {
		return (
			<InterviewCardActions interview={interview} isSelected={isSelected} selectRow={selectRow} isHovered={isHovered} />
		)
	}

	return (
		<UserCard
			userRef={interview.userRef}
			actions={renderActions}
			to={generateRoute(ROUTE_INTERVIEW_DETAIL, { interviewId: interview.id })}
			linkLabel={t('interviews:card.open')}
			title={interview.reverseFullname}
		>
			<Stack spacing={1}>
				<InterviewAssessor assessorFullName={interview.assessorFullName} />
				<StepsOverview steps={steps} />
				<InterviewLabel
					typeLabel={typeLabel}
					label={label ?? ''}
					isInterviewLabelAvailable={isInterviewLabelAvailable}
				/>
				<InterviewTargetDate
					{...{
						interviewDate,
						isScheduled,
						isMissedInterview,
						targetDate: targetDate ?? '',
						interviewStepState: interview.stepsState.interview as unknown as boolean,
						hasStartTime
					}}
				/>
			</Stack>
			{children != null ? <Stack>{children}</Stack> : null}
		</UserCard>
	)
}

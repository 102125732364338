import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeQuery } from '@gqlTypes'
import AutoCompleteFilter from '../Filters/AutoCompleteFilter'

interface Props {
	value: string
	onSelect: (parentId: string, fieldId: string, value: string) => void
	userPositionId: string
	isSchool?: boolean
}

export const BusinessUnitSelect = ({ value, onSelect, userPositionId, isSchool = false }: Props): ReactElement => {
	const { t } = useTranslation()

	const translationKey = isSchool ? 'students' : 'employees'
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return <></>
	const availableBusinessUnits = data.me.organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu }))

	return (
		<AutoCompleteFilter
			options={availableBusinessUnits}
			noneValueLabel={t(`${translationKey}:cell.businessUnit.none`)}
			value={value}
			onChange={(businessUnit: { value: string }) => {
				onSelect(userPositionId, 'businessUnit', businessUnit.value)
			}}
		/>
	)
}

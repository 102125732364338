import { type BaseTextFieldProps, InputAdornment, type SelectChangeEvent } from '@mui/material'
import CountryCodes, { defaultCountryIndex, IndexByCode } from './coutry-codes'
import { TextField } from '../core/TextField'
import { type ChangeEvent, type ReactElement, useState } from 'react'
import { CountryCodeSelector } from './CountryCodeSelector'
import { useTheme } from '@mui/styles'

interface Props extends BaseTextFieldProps {
	label?: string
	value?: string
	onChange?: (value: string) => void
}

export const PhoneInput = ({ value = '', onChange, label = 'Phone number', ...otherProps }: Props): ReactElement => {
	const [country, setCountry] = useState(CountryCodes[defaultCountryIndex])
	const theme = useTheme()

	let valueWithoutPrefix = value
	const dialCode = country.dialCode ?? ''

	if (dialCode !== '') {
		valueWithoutPrefix = valueWithoutPrefix.replace(new RegExp('^' + country.dialCode.replace('+', '\\+')), '')
	}

	const setValue = (newValue: string, forcePrefix?: string): void => {
		const prefix = forcePrefix ?? dialCode
		newValue = newValue.replace(/^0+/, '').replace(' ', '')
		let fullValue = prefix + '' + newValue
		if (newValue === '') fullValue = newValue
		if (onChange != null) onChange(fullValue)
	}

	const setNewCountry = (countryCode: string): void => {
		const country = CountryCodes[IndexByCode[countryCode]]
		setValue(valueWithoutPrefix, country.dialCode)
		setCountry(country)
	}

	const startAdornment = (
		<InputAdornment position="start">
			<CountryCodeSelector
				value={country?.code ?? ''}
				onChange={(e: SelectChangeEvent<string>) => {
					setNewCountry(e.target.value)
				}}
			/>{' '}
			<span
				style={{
					color: theme.palette.grays.gray3
				}}
			>
				{country.dialCode}
			</span>
		</InputAdornment>
	)

	return (
		<TextField
			{...otherProps}
			InputProps={{ startAdornment }}
			onChange={(e: ChangeEvent<HTMLInputElement>) => {
				setValue(e.target.value)
			}}
			value={valueWithoutPrefix}
			sx={{ backgroundColor: 'blue' }}
		/>
	)
}

import { type MotivationQuestionFragment, MotivationQuestionType } from '@gqlTypes'
import { MotivationQuestion } from './MotivationQuestion'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Stack from '@mui/material/Stack'
import { type ReactElement } from 'react'
import { MotivationLabelGroup } from '../label/MotivationLabelGroup'

interface Props {
	questions: MotivationQuestionFragment[]
	isEditable?: boolean
	title: string
}

export const MotivationQuestions = ({ questions, isEditable = false, title }: Props): ReactElement | null => {
	if (questions.length === 0) return null

	return (
		<CardLayout title={title}>
			<InfoCardContent>
				<Stack spacing={2}>
					{questions.map((question) => {
						if (question.type === MotivationQuestionType.Text) {
							return <MotivationQuestion key={question.id} isEditable={isEditable} {...question} />
						}
						if (question.type === MotivationQuestionType.Icon) {
							return (
								<MotivationLabelGroup
									key={question.id}
									id={question.id}
									groupLabel={question.question}
									isEditable={isEditable}
									labels={question.labels}
									hasMultipleAnswers={question.hasMultipleAnswers}
								/>
							)
						}
						return <></>
					})}
				</Stack>
			</InfoCardContent>
		</CardLayout>
	)
}

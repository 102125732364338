import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import BackButton from '@shared/components/buttons/BackButton'
import { type ReactElement } from 'react'

interface Props {
	title: string
	backRoute?: string
	backToolTip: string
	children?: ReactElement
}

export const TopBar = ({ title, backRoute, backToolTip, children }: Props): ReactElement => {
	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			justifyContent="space-between"
			sx={{
				borderBottom: '1px solid #0000001F',
				mb: 3,
				backgroundColor: 'backgroundBlue.main',
				zIndex: 1,
				position: 'sticky',
				top: 0
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1}>
				{backRoute != null ? <BackButton route={backRoute} tooltipTitle={backToolTip} /> : null}
				<Typography variant="h5" color="primary">
					{title}
				</Typography>
			</Stack>
			{children}
		</Stack>
	)
}

import { Checkbox, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material'
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined'
import { makeStyles } from 'tss-react/mui'
import { type SelectableSkillItem } from '@gqlTypes'

interface Props {
	label: string
	color: string
	skills: SelectableSkillItem[]
	selectedItem: Record<string, SelectableSkillItem>
	setIsSelected: (id: string, isSelected: boolean) => void
}

export default function SkillGroupSelector(props: Props): JSX.Element {
	const { label, skills, selectedItem, setIsSelected, color } = props
	const { classes } = useStyles({ color })

	return (
		<div className={classes.container}>
			<FormLabel>
				<Typography className={classes.title}>{label}</Typography>
			</FormLabel>
			<FormGroup>
				{skills.map((skill) => (
					<FormControlLabel
						key={skill.id}
						checked={selectedItem[skill.id]?.isSelected ?? false}
						control={
							<Checkbox
								classes={{
									root: classes.root,
									checked: classes.checked
								}}
								checkedIcon={<CheckBoxOutlinedIcon />}
								onChange={(e, checked) => {
									setIsSelected(skill.id, checked)
								}}
							/>
						}
						label={skill.label}
					/>
				))}
			</FormGroup>
		</div>
	)
}

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 10,
		borderLeft: `3px solid ${color}`
	},
	title: {
		color,
		fontWeight: 'bold',
		marginBottom: '5px'
	},
	root: {
		paddingTop: '2px',
		paddingBottom: '2px',
		'&, & + .MuiFormControlLabel-label': {
			color: theme.palette.grey[400]
		},
		'&$checked': {
			'&, & + .MuiFormControlLabel-label': {
				color: theme.palette.grey[600]
			}
		}
	},
	checked: {}
}))

import { type ChangeEvent, type ReactElement, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { Dialog, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { DialogSection } from '@shared/components/modal/dialogSection'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import { type Exact, TrackType, useImportTracksMutation, useMeQuery } from '@gqlTypes'
import { type ApolloQueryResult } from '@apollo/client'

interface Props {
	refetch: (params?: Partial<Exact<{ cursor: string; limit: number }>>) => Promise<ApolloQueryResult<unknown>>
}

export const TrackImport = ({ refetch }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [importTrack, { data }] = useImportTracksMutation()
	const { data: me } = useMeQuery()

	const ref = useRef<HTMLInputElement>(null)
	const handleClick = (): void => {
		if (ref.current == null) return
		ref.current.click()
	}

	const onFileSelect = (e: ChangeEvent<HTMLInputElement>): void => {
		e.preventDefault()
		if (e.target.files == null) return

		const reader = new FileReader()
		const file = e.target.files[0]

		reader.onload = async () => {
			await importTrack({ variables: { trackType: TrackType.Position, fileContent: reader.result as string } })
			await refetch()
			setIsOpen(true)
		}
		reader.readAsText(file)
	}

	const isSuccess = data?.importTracks?.isSuccess ?? false
	const isSuperAdmin = me?.me?.isSuperAdmin ?? false
	if (me == null || !isSuperAdmin) return null

	return (
		<>
			<input type="file" ref={ref} style={{ display: 'none' }} onChange={onFileSelect} />
			<Button variant="contained" onClick={handleClick}>
				{t('tracks:list.bar.button.import')}
			</Button>
			<Dialog open={isOpen} onClose={close}>
				<IconButton
					aria-label="close"
					onClick={() => {
						setIsOpen(false)
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: 'grays.gray2'
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					<>{t('modal:tracksImport.title')}</>
				</DialogTitle>
				<Divider />
				<DialogSection>
					{isSuccess ? (
						<CheckCircleOutlineIcon sx={{ fontSize: 160 }} color="success" />
					) : (
						<ErrorOutlineIcon sx={{ fontSize: 160 }} color="error" />
					)}

					<Stack>
						<Typography>{t('modal:tracksImport.createdCount', { count: data?.importTracks.createdCount })}</Typography>
						<Typography>{t('modal:tracksImport.updatedCount', { count: data?.importTracks.updatedCount })}</Typography>
						<Typography>{t('modal:tracksImport.errorCount', { count: data?.importTracks.errorCount })}</Typography>
					</Stack>
				</DialogSection>
			</Dialog>
		</>
	)
}

import { storage } from '../firebase/firebase.helper'

interface FileUploaded {
	fileName: string
	filePath: string
	fileUrl: string
}

interface FileUploadedResult {
	newFiles: FileUploaded[]
	hasUploadingError: boolean
}

interface UseStorage {
	uploadFiles: (files: FileList | null, filePath: string, hasTimeStamp?: boolean) => Promise<FileUploadedResult>
	getStorageUrl: (path: string) => string
}

export const useStorage = (): UseStorage => {
	const pathWithoutStartSlash = (path: string): string => {
		return path[0] === '/' ? path.substring(1) : path
	}

	const getStorageUrl = (path: string): string => {
		if (path === '') return ''

		const formatedPath = pathWithoutStartSlash(path)
		const firebaseStoragePath = 'https://firebasestorage.googleapis.com/v0/b'
		const bucketName = storage().ref().bucket
		return `${firebaseStoragePath}/${bucketName}/o/${encodeURIComponent(formatedPath)}?alt=media`
	}

	const uploadFiles = async (
		files: FileList | null,
		filePath: string,
		hasTimeStamp: boolean
	): Promise<FileUploadedResult> => {
		const filesArray = files !== null ? Array.from(files) : []

		try {
			const newFiles = await Promise.all(
				filesArray.map(async (file) => {
					const fileName = hasTimeStamp ? new Date().getTime().toString() + file.name : file.name
					const storageRef = storage().ref(`${filePath}/${fileName}`)
					const snapshot = await storageRef.put(file)
					const fileUrl = await snapshot.ref.getDownloadURL()

					return { fileName, filePath, fileUrl }
				})
			)
			return { newFiles, hasUploadingError: false }
		} catch (error) {
			return { newFiles: [], hasUploadingError: true }
		}
	}

	return { uploadFiles, getStorageUrl }
}

import { generateRoute } from '../../../router/routes'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import NavButtonIcon from '../buttons/NavButtonIcon'
import { useMeQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	hasLink: boolean
	route: string
	routeParams: string
	tooltipTitle: string
}

export const AvatarLink = ({ hasLink, route, routeParams, tooltipTitle }: Props): ReactElement => {
	const { data } = useMeQuery()
	const hasUserIntegrationDetailsAccess = data?.me?.organization.hasUserIntegrationDetailsAccess ?? false

	return (
		<NavButtonIcon
			route={generateRoute(route, routeParams)}
			icon={<AccountBoxIcon />}
			tooltipTitle={tooltipTitle}
			disabled={!(hasLink && hasUserIntegrationDetailsAccess)}
		/>
	)
}

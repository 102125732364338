import { type ReactElement } from 'react'
import { Typography } from '@mui/material'

interface Props {
	typeLabel: string
	label: string
	isInterviewLabelAvailable: boolean
}

export const InterviewLabel = ({ typeLabel, label, isInterviewLabelAvailable }: Props): ReactElement => {
	const fullLabel = []
	if (typeLabel !== '') fullLabel.push(typeLabel)
	if (isInterviewLabelAvailable) fullLabel.push(label)

	return (
		<Typography
			sx={{ fontSize: '14px', color: 'grays.gray3' }}
			whiteSpace="nowrap"
			overflow="hidden"
			textOverflow="ellipsis"
		>
			{fullLabel.join(' • ')}
		</Typography>
	)
}

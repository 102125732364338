import { Divider, List } from '@mui/material'
import { type ReactElement } from 'react'
import { type StepItemProps, StepItem } from './StepItem'

interface Props {
	steps: StepItemProps[]
}

export const StepList = ({ steps }: Props): ReactElement => {
	return (
		<>
			<Divider sx={{ mt: 1 }} />
			<List sx={{ p: 0 }}>
				{steps.map((step) => (
					<StepItem {...step} key={step.id} />
				))}
			</List>
		</>
	)
}

import { type ReactElement, type CSSProperties, type ReactNode } from 'react'
import { makeStyles } from 'tss-react/mui'

const useDialogSectionStyle = makeStyles()(() => ({
	root: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingTop: 20,
		paddingBottom: 20,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 2,
		maxWidth: 550,
		width: '80vw',
		'& .main-dialog-element': {
			flex: 2
		},
		'& > *': {
			marginLeft: 5,
			marginRight: 5,
			flex: 1
		}
	},
	horizontal: {
		'& > :first-child': {
			minWidth: 100,
			flexShrink: 0
		}
	},
	vertical: {
		flexDirection: 'column',
		'& > *': {
			marginBottom: 5
		}
	}
}))

interface Props {
	vertical?: boolean
	style?: CSSProperties
	children: ReactNode
}

export const DialogSection = ({ vertical = false, style, children, ...otherProps }: Props): ReactElement => {
	const { classes } = useDialogSectionStyle()

	return (
		<div
			style={style}
			className={`${classes.root} ${vertical ? classes.vertical : classes.horizontal}`}
			{...otherProps}
		>
			{children}
		</div>
	)
}

import { type GqlFormErrors, handleGQLErrors } from '#helpers/form.helper'
import { type ApolloError } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Severities } from '@application/snackbar/Severities'
import {
	useCreateTrackMutation,
	useGetOfferTrackDetailsQuery,
	useGetPositionTrackDetailsQuery,
	useUpdateTrackMutation,
	TrackType,
	type GetOfferTrackDetailsQuery,
	type GetPositionTrackDetailsQuery,
	type TrackInput,
	type TranslatedStringInput
} from '@gqlTypes'
import { ROUTE_TRACKS_OFFER, ROUTE_TRACKS_POSITION } from '../../../router/routes'

type GetIntegrationTrackDetailsQuery = GetOfferTrackDetailsQuery | GetPositionTrackDetailsQuery

export type TrackInputToSave = Omit<TrackInput, 'notifyTo'> & {
	isDefaultInterviewCreationMessage?: boolean
	notifyTo: string
	locations: string
}

interface Hook {
	offerStatus: GetOfferTrackDetailsQuery['getOfferStatus']
	organizationIntegration:
		| GetOfferTrackDetailsQuery['getTrackDetails']
		| GetPositionTrackDetailsQuery['getTrackDetails']
		| undefined
	trackUseCases: GetOfferTrackDetailsQuery['getTrackUseCases']
	categories: GetIntegrationTrackDetailsQuery['getTrackCategories']
	offerTypes: GetIntegrationTrackDetailsQuery['getOfferType']
	userFunctions: GetIntegrationTrackDetailsQuery['getUserFunctions']
	isLoading: boolean
	isSaving: boolean
	gqlFormErrors: GqlFormErrors
	saveTrack: (trackInput: TrackInputToSave) => Promise<void>
}

export default function useTracksDetails(isOffer: boolean): Hook {
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation()
	const history = useHistory()
	const { organizationIntegrationId: trackId } = useParams<{ organizationIntegrationId: string }>()
	const trackType = isOffer ? TrackType.Offer : TrackType.Position
	const isCreateForm = trackId === 'new'
	const variables = { trackId, trackType }
	const { data, loading: isLoading } = isOffer
		? useGetOfferTrackDetailsQuery({ variables })
		: useGetPositionTrackDetailsQuery({ variables })

	let gqlFormErrors: GqlFormErrors = {}
	const onError = (errors: ApolloError): void => {
		gqlFormErrors = handleGQLErrors(errors)
		enqueueSnackbar(t('tracks:details.gql.error'), { variant: Severities.error, autoHideDuration: 3000 })
	}
	const onCompleted = (): void => {
		enqueueSnackbar(t('tracks:details.gql.success'), { variant: Severities.success, autoHideDuration: 3000 })
	}

	const [updateTrackMutation, { loading: isUpdating }] = useUpdateTrackMutation({ onError, onCompleted })
	const [createTrackMutation, { loading: isCreating }] = useCreateTrackMutation({ onError, onCompleted })

	const isSaving = isCreateForm ? isCreating : isUpdating

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const saveTrack = async (trackInput: any): Promise<void> => {
		trackInput.steps = trackInput.steps ?? []
		trackInput.interviewSteps = trackInput.interviewSteps ?? []
		trackInput.motivationsLabelsGroups = trackInput.motivationsLabelsGroups ?? []
		trackInput.motivationsQuestions = trackInput.motivationsQuestions ?? []
		trackInput.interviewQuestionIds = trackInput.interviewQuestionIds ?? []
		trackInput.skills =
			trackInput.skills != null ? trackInput.skills.map((skillString: string) => JSON.parse(skillString)) : []
		trackInput.notifyTo = trackInput.notifyTo !== '' ? [trackInput.notifyTo] : []
		trackInput.locations = trackInput.locations !== '' ? [trackInput.locations] : []
		trackInput.statusInterviewPlaned = trackInput.statusInterviewPlaned ?? ''
		trackInput.statusInterviewDone = trackInput.statusInterviewDone ?? ''
		trackInput.brandFeedbackRequestTemplateId = trackInput.brandFeedbackRequestTemplateId ?? ''
		trackInput.hasNotMemberButton = trackInput.hasNotMemberButton ?? false
		trackInput.hasCareerSearch = trackInput.hasCareerSearch ?? false
		trackInput = getInterviewCreationMessage(trackInput)

		if (isCreateForm) {
			await createTrackMutation({ variables: { trackType, trackInput } })
			const nextRoute = isOffer ? ROUTE_TRACKS_OFFER : ROUTE_TRACKS_POSITION
			history.push(nextRoute)
		} else {
			await updateTrackMutation({ variables: { ...variables, trackInput } })
		}
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getInterviewCreationMessage = (trackInput: any): TranslatedStringInput => {
		const {
			isDefaultInterviewCreationMessage: isDefault,
			interviewCreationMessage: message
		}: { isDefaultInterviewCreationMessage: boolean; interviewCreationMessage: string } = trackInput
		trackInput.interviewCreationMessage = message
		if (isDefault) trackInput.interviewCreationMessage = { fr: '', en: '' }
		delete trackInput.isDefaultInterviewCreationMessage
		return trackInput
	}

	return {
		isLoading,
		isSaving,
		saveTrack,
		organizationIntegration: data?.getTrackDetails,
		categories: data?.getTrackCategories,
		offerTypes: data?.getOfferType,
		userFunctions: data?.getUserFunctions,
		offerStatus: data?.getOfferStatus ?? [],
		gqlFormErrors,
		trackUseCases: data?.getTrackUseCases ?? []
	}
}

import { useMeQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { ChipSelector } from '@shared/components/forms/fields/ChipSelector'
import { type ReactElement } from 'react'

export const EducationLevelSelector = (): ReactElement | null => {
	const { data, loading } = useMeQuery()
	const items = data?.me?.organization?.matchingRestrictions?.educationLevels ?? []
	if (items.length <= 1 || loading) return null

	return (
		<TableLayoutRow translationKey="offers:details.main.educationLevel">
			<ChipSelector name="educationLevelIds" items={items} isLoading={loading} minSelectableItem={1} />
		</TableLayoutRow>
	)
}

import { PresentationLink } from '../../../features/details/components/informationsTab/PresentationLink'
import { useTranslation } from 'react-i18next'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { type ReactElement } from 'react'

interface Props {
	link: string
}

export const PresentationLinkCard = ({ link }: Props): ReactElement | null => {
	const { t } = useTranslation(['translation'])
	if (link === '') return null

	return (
		<CardLayout title={t('translation:candidates.informations.cardTitle.video')}>
			<InfoCardContent>
				<PresentationLink link={link} />
			</InfoCardContent>
		</CardLayout>
	)
}

import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import EventIcon from '@mui/icons-material/Event'

interface Props {
	jobDurationLabels: string[]
	startMonthLabel: string
}

export const DurationLabel = ({ jobDurationLabels, startMonthLabel }: Props): ReactElement => {
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<EventIcon sx={{ width: 18 }} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>
				{[...jobDurationLabels, startMonthLabel].filter((str) => str !== '').join(' • ')}
			</Typography>
		</Stack>
	)
}

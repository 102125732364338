import { type GetCareerMatchingsQuery } from '@gqlTypes'
import { Stack } from '@mui/material'
import { type ReactElement } from 'react'
import { CareerMatchingDetailScore } from './CareerMatchingDetailScore'

interface Props {
	detailScores: GetCareerMatchingsQuery['getCareerMatchings'][0]['scoreDetails']
}

export const CareerMatchingDetailScores = ({ detailScores }: Props): ReactElement => {
	const { personality, softskills, interestCapabilities, interestWishes, interestDomains } = detailScores
	return (
		<Stack justifyContent="space-between" direction="row" sx={{ height: '100%' }}>
			<CareerMatchingDetailScore score={personality} iconName={'account'} scoreName={'personality'} />
			<CareerMatchingDetailScore score={softskills} iconName={'emoticon-happy-outline'} scoreName={'softskills'} />
			<CareerMatchingDetailScore
				score={interestCapabilities}
				iconName={'arm-flex'}
				scoreName={'interestCapabilities'}
			/>
			<CareerMatchingDetailScore score={interestWishes} iconName={'heart'} scoreName={'interestWishes'} />
			<CareerMatchingDetailScore score={interestDomains} iconName={'domain'} scoreName={'interestDomains'} />
		</Stack>
	)
}

import { indexById } from '#helpers/array.helper'
import { ObjectiveTypeId, useGetObjectiveTypesQuery } from '@gqlTypes'
import { ToggleButton, ToggleButtonGroup, type ToggleButtonGroupProps } from '@mui/material'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { useController } from 'react-hook-form'

export const ObjectiveTypeToggle = (props: ToggleButtonGroupProps): ReactElement | null => {
	const {
		field: { onChange, value }
	} = useController<Record<string, ObjectiveTypeId>>({ name: 'typeId' })

	const { data, loading } = useGetObjectiveTypesQuery()
	if (loading) return <LocalLoader />
	if (data == null) return null
	const indexedTypes = indexById(data.getObjectiveTypes)
	return (
		<ToggleButtonGroup color="primary" value={value} exclusive onChange={onChange} {...props}>
			<ToggleButton value={ObjectiveTypeId.Quantity}>{indexedTypes[ObjectiveTypeId.Quantity].label}</ToggleButton>
			<ToggleButton value={ObjectiveTypeId.Quality}>{indexedTypes[ObjectiveTypeId.Quality].label}</ToggleButton>
		</ToggleButtonGroup>
	)
}

import { routes } from '../router/routes'

/**
 * Check if path match to public route
 *
 * Usage :
 * const location = useLocation();
 * const isPublic = isPublic(location.pathname));
 *
 * @param currentPath the path to check ( need to be like /path/:param/path )
 * @returns {boolean} is public route
 */
export function isPublicPath(currentPath) {
	if (!currentPath) {
		return false
	}
	const currentPathSplit = splitRoute(currentPath)
	const publicRoutes = routes.filter((route) => route.public)
	for (const publicRoute of publicRoutes) {
		const publicRouteSplit = splitRoute(publicRoute.path)
		if (publicRouteSplit.length < currentPathSplit.length) {
			// declared route can't match with smaller path
			continue
		} else if (!publicRoute.notExact && publicRouteSplit.length !== currentPathSplit.length) {
			// exact route must have the same split length
			continue
		}

		let match = true
		// publicRouteSplit has at least the same size than currentPathSplit
		for (let i = 0; i < publicRouteSplit.length; i++) {
			const publicRoutePart = publicRouteSplit[i]
			const currentPathPart = currentPathSplit[i]
			// no null check, all items exists, check if you touch t
			if (publicRoutePart.startsWith(':')) {
				// parameter exists is enough
				continue
			} else if (publicRoutePart !== currentPathPart) {
				match = false
			}
		}

		if (match) {
			return true
		}
	}
	return false
}

function splitRoute(route) {
	if (route) {
		const split = route.split('/')
		return split.filter((routePart) => routePart && routePart !== '')
	}
	return route
}

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'
import { type ReactElement } from 'react'

interface Props {
	min: number
	startLabel: string
	value: number
	endLabel: string
	id: string
}

export const SliderLabel = ({ min, startLabel, value, endLabel, id }: Props): ReactElement => {
	const theme = useTheme()

	return (
		<Box
			sx={{
				marginBottom: '-8px',
				display: 'flex',
				alignItems: 'flex-end',
				justifyContent: 'space-between'
			}}
		>
			{startLabel != null ? (
				<Typography
					sx={{
						wordBreak: 'break-word',
						color: min < 0 && value > 0 ? theme.palette.grays.gray2 : theme.palette.grays.gray5,
						fontWeight: min < 0 && value <= 0 ? 'bold' : 'normal'
					}}
					component="label"
					variant="body2"
					htmlFor={id}
				>
					{startLabel}
				</Typography>
			) : null}
			{endLabel != null ? (
				<Typography
					component="label"
					variant="body2"
					htmlFor={id}
					sx={{
						color: min < 0 && value <= 0 ? theme.palette.grays.gray2 : theme.palette.grays.gray5,
						fontWeight: min < 0 && value > 0 ? 'bold' : 'normal'
					}}
				>
					{endLabel}
				</Typography>
			) : null}
		</Box>
	)
}

import { type ChangeEvent, type ReactElement, useRef } from 'react'
import { LoadingButton } from '@mui/lab'

interface Props {
	onSelectFile: (files: FileList | null) => void
	label: string
	accept: string
	isUploading?: boolean
}

export const ImportFileButton = ({ onSelectFile, label, accept, isUploading = false }: Props): ReactElement => {
	const fileInputRef = useRef(null)

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
		onSelectFile(event.target.files)
		event.target.value = ''
	}

	return (
		<LoadingButton loading={isUploading} variant="contained" component="label">
			{label}
			<input ref={fileInputRef} hidden accept={accept} multiple={false} type="file" onChange={handleFileChange} />
		</LoadingButton>
	)
}

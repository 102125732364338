import { useGetPositionRiskHistoryQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { Typography, Grid, Divider, DialogContent, DialogTitle } from '@mui/material'
import { RiskEntry } from './RiskEntry'
import { useTranslation } from 'react-i18next'

interface Props {
	positionId: string
}

export const RiskHistory = ({ positionId }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useGetPositionRiskHistoryQuery({ variables: { id: positionId } })
	if (data == null || loading) return null
	const history = data.getUserPosition?.riskHistory ?? []
	if (history.length === 0) return null
	return (
		<>
			<Divider />
			<DialogTitle>
				<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>{t('risk:modal.history.title')}</Typography>
			</DialogTitle>
			<DialogContent>
				<Grid container rowSpacing={3} sx={{ maxHeight: 200 }}>
					{history.map((entry, index) => (
						<RiskEntry entry={entry} key={index} />
					))}
				</Grid>
			</DialogContent>
		</>
	)
}

import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import { type ApolloQueryResult, gql } from '@apollo/client'
import { useMeQuery, useUpdateManyUserInterviewsMutation } from '@gqlTypes'
import { MassUpdatesGql } from '../../../features/modals/pages/MassUpdatesGql'
import { NewInterviewDrawer } from '@domains/interviews/create/NewInterviewDrawer'
import { MassReminders } from '@features/modals/pages/MassReminders'
import { LeftBar } from '@features/lists/components/LeftBar'
import { ZipInterviewsPdf } from './ZipInterviewsPdf'
import { useRole } from '@shared/hooks/useRole'
import { type ReactElement } from 'react'
import { InterviewsFilters } from './InterviewsFilters'

const INTERVIEW_EXPORT = gql`
	query InterviewExport($filters: [FilterInput], $organizationId: String, $ids: [String]) {
		exportCSV: interviewExport(filters: $filters, organizationId: $organizationId, ids: $ids)
	}
`

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
	isAllRowsSelected: boolean
	dataList: { id: string }[]
	count: number
	hasPdfExport: boolean
}
export const InterviewsFilterBar = ({
	refetch,
	isAllRowsSelected,
	dataList,
	count,
	hasPdfExport
}: Props): ReactElement | null => {
	const { screens } = useRole()

	const { data } = useMeQuery()
	const [updateManyUserInterviews, { loading: isLoading }] = useUpdateManyUserInterviewsMutation()

	const role = data?.me?.role
	if (role == null) return null
	const { isExternal } = role

	const massAction = {
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<NewInterviewDrawer reset={refetch} />
			{screens.interviews.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserInterviews}
					refetch={refetch}
					isLoading={isLoading}
					count={count}
				/>
			) : null}

			<MassReminders {...massAction} isInterview />
			{isExternal ? null : (
				<>
					<ExportGQL {...massAction} QUERY={INTERVIEW_EXPORT} />
					<ZipInterviewsPdf {...massAction} hasPdfExport={hasPdfExport} />
				</>
			)}

			<InterviewsFilters refetch={refetch} />
		</LeftBar>
	)
}

import { useTranslation } from 'react-i18next'
import { useGetTrackPositionsLabelQuery, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	keyLabel?: string
	isMultiple?: boolean
}

export const TrackPositionFilter = ({
	keyName,
	keyLabel = 'poste',
	isMultiple = false
}: Props): ReactElement | null => {
	const { t } = useTranslation()

	const { data: dataMe } = useMeQuery()
	const { data, loading } = useGetTrackPositionsLabelQuery()
	if (loading || dataMe?.me == null || data?.getTrackPositionsLabel == null) return null
	const positionsAccess = dataMe.me.positionsAccess.map(({ id, label }) => {
		return { value: id, label }
	})
	const availablePositions = (data.getTrackPositionsLabel ?? []).map((position: { label: string; value: string }) => ({
		value: position.value,
		label: position.label
	}))
	const hasRestriction = dataMe.me.positionsAccess.length === 1

	if (hasRestriction) return null

	return (
		<InputFilter keyName={keyName} operation={isMultiple ? filterOperation.IN_ARRAY : filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={positionsAccess.length === 0 ? availablePositions : positionsAccess}
				defaultValue=""
				label={t(`lists:filter.${keyLabel}.label`)}
				allValueLabel={t(`lists:filter.${keyLabel}.allLabel`)}
				multiple={isMultiple}
				width="100%"
			/>
		</InputFilter>
	)
}

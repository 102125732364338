import { useMeQuery, type CommentBySkill } from '@gqlTypes'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { type ReactElement } from 'react'

interface Props {
	commentsBySkills: CommentBySkill[]
}

export const GroupedComments = ({ commentsBySkills }: Props): ReactElement => {
	const { data } = useMeQuery()
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false

	return (
		<>
			{commentsBySkills.map((commentBySkill) => (
				<Box key={commentBySkill.skillId}>
					<Typography sx={{ fontWeight: 'bold' }}>{commentBySkill.skillLabel}</Typography>

					{commentBySkill.comments.map((comment, index) => {
						const title = isBrandFeedbackAnonymous
							? `${moment(comment.creationDate).format('DD/MM/YYYY')}`
							: `${comment.senderDisplayName} - ${moment(comment.creationDate).format('DD/MM/YYYY')}`
						return (
							<Box key={`${commentBySkill.skillId}-${index}`}>
								<Typography sx={{ fontSize: 16 }}>&quot;{comment.message}&quot;</Typography>
								<Typography gutterBottom sx={{ fontSize: 14, color: 'grey.500', textAlign: 'right' }}>
									{title}
								</Typography>
								<Typography gutterBottom sx={{ fontSize: 14, color: 'grey.500', textAlign: 'right' }}>
									{comment.businessUnit}
								</Typography>
							</Box>
						)
					})}
				</Box>
			))}
		</>
	)
}

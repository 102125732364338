import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const OfferTypeFilter = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	const items = (data?.me?.organization?.matchingRestrictions?.offerTypes ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.name ?? ''
	}))
	if (items.length <= 1 || loading) return null

	return (
		<InputFilter keyName={'offerTypeId'} operation={filterOperation.IN_ARRAY}>
			<AutoCompleteFilter
				options={items}
				label={t('offers:resumeLibrary.filters.offerType.label')}
				allValueLabel={t('offers:resumeLibrary.filters.offerType.allLabel')}
				width="100%"
				multiple
			/>
		</InputFilter>
	)
}

import { type StepInterview } from '@gqlTypes'
import { type ReactElement } from 'react'
import { StepList } from './StepList'
import { generateRoute } from '../../../../router/routes'
import { FeedbackRequestPath } from '@domains/feedbackRequest/FeedbackRequest.router'
import { useHistory } from 'react-router-dom'
import { type StepItemProps } from './StepItem'

interface Props {
	id: string
	steps: StepInterview[]
	stepsState: Record<string, boolean>
}

export const InterviewStepList = ({ id, steps, stepsState }: Props): ReactElement | null => {
	const history = useHistory()

	const navigations: Record<string, { route: string; params: Record<string, string> }> = {
		returnsRequests: {
			route: FeedbackRequestPath.interviewForm,
			params: { interviewId: id }
		},
		fullRequests: {
			route: FeedbackRequestPath.interviewForm,
			params: { interviewId: id, fullrequest: 'fullrequest' }
		}
	}

	const displayedSteps: StepItemProps[] = steps
		.filter((step) => Object.keys(navigations).includes(step.id))
		.map((step) => ({
			id: step.id,
			label: step.label,
			isValidated: stepsState[step.id] ?? false,
			onClick: () => {
				history.push(generateRoute(navigations[step.id]?.route ?? '', navigations[step.id]?.params ?? {}))
			}
		}))
	if (displayedSteps.length === 0) return null
	return <StepList steps={displayedSteps} />
}

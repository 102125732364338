import { type Risk } from '@gqlTypes'
import { type ReactElement } from 'react'
import FlagIcon from '@mui/icons-material/Flag'
import { Stack, Typography } from '@mui/material'

interface Props {
	risk: Risk
	displayLabel?: boolean
	color?: string
}

export const RiskLabel = ({ risk, displayLabel = true, color = '' }: Props): ReactElement => {
	return (
		<Stack direction="row">
			<FlagIcon sx={{ color: color !== '' ? color : risk.color }} />
			{displayLabel ? <Typography color={risk.color}>{risk.label}</Typography> : null}
		</Stack>
	)
}

import { Box, CircularProgress, type CircularProgressProps } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'

interface Props extends CircularProgressProps {
	children: ReactNode
	value: number
}

export const CircularProgressWithContent = ({ value = 0, size = 50, children, ...props }: Props): ReactElement => {
	return (
		<Box sx={{ position: 'relative', display: 'inline-flex' }}>
			<CircularProgress size={size} variant="determinate" value={value} color="secondary" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				{children}
			</Box>
		</Box>
	)
}

import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { type ReactElement, type ReactNode, useState } from 'react'
import { ValidateButton } from '../buttons/ValidateButton'
import { useMeQuery } from '@gqlTypes'

interface Item {
	value: string
	label: string
}

interface Props {
	onValidate: (field: string, newValue: string | boolean) => Promise<void>
	items: Item[]
	children?: ReactNode
	field: string
	title: string
}

export const SelectorUpdater = ({ onValidate, items, children = null, field, title }: Props): ReactElement | null => {
	const [value, setValue] = useState('')
	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	if (loading || data?.me == null) return null

	const handleSelect = (event: SelectChangeEvent<string>): void => {
		setValue(event.target.value)
	}

	const isDisabled = items.length < 2 && (data.me.isRestrictedAccess ?? false)

	return (
		<DialogSection>
			<label htmlFor="massUpdate-status">{title}</label>
			<Select
				id="massUpdate-status"
				value={value}
				onChange={handleSelect}
				className="main-dialog-element"
				disabled={isDisabled}
			>
				{children ??
					items.map((item) => (
						<MenuItem key={item.value} value={item.value}>
							{item.label}
						</MenuItem>
					))}
			</Select>
			<ValidateButton onValidate={onValidate} value={value} field={field} />
		</DialogSection>
	)
}

import { type GraphDimension } from '@gqlTypes'
import { MeasureArea } from './MeasureArea'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	dimensions: GraphDimension[]
	size: GraphRadarSize
}

export const AllMeasureAreas = ({ dimensions, size }: Props): ReactElement => {
	return (
		<>
			{dimensions.map((dimension, dimensionIndex) => (
				<g key={dimension.id}>
					<MeasureArea
						measureIndex={dimensionIndex}
						measuresCount={dimensions.length}
						valueBase10={dimension.selfScore ?? 0}
						color={dimension.color}
						isShadowMeasure
						size={size}
					/>
					<MeasureArea
						measureIndex={dimensionIndex}
						measuresCount={dimensions.length}
						valueBase10={dimension.feedbackScore ?? 0}
						color={dimension.color}
						size={size}
					/>
				</g>
			))}
		</>
	)
}

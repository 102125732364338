import { useTranslation } from 'react-i18next'
import { FilterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	operation?: FilterOperation
	isMultiple?: boolean
	defaultValue?: string[] | string
}

export const AssessorsFilter = ({
	keyName,
	operation = FilterOperation.EQUAL,
	isMultiple = false,
	defaultValue = ''
}: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = (data?.getAssessors ?? []).map(({ id, reverseFullName }) => ({
		value: id,
		label: reverseFullName
	}))
	if (assessors.length === 0) return null

	return (
		<InputFilter keyName={keyName} operation={operation}>
			<AutoCompleteFilter
				options={assessors}
				defaultValue={defaultValue}
				label={t('lists:filter.assessor.label')}
				allValueLabel={t('lists:filter.assessor.allLabel')}
				width="100%"
				multiple={isMultiple}
			/>
		</InputFilter>
	)
}

import { Route, Switch } from 'react-router-dom'
import { NotFound } from '../Pages/NotFound'
import { routes } from './routes'
import { type ReactElement } from 'react'

export const RouterHandler = (): ReactElement => {
	return (
		<Switch>
			{routes.map((route) => {
				return (
					<Route path={route.path} exact={!(route?.notExact ?? false)} key={route.path}>
						{route.children}
					</Route>
				)
			})}
			<Route path="*">
				<NotFound />
			</Route>
		</Switch>
	)
}

import { Tooltip } from '@mui/material'

export default function EnvIndicator(): JSX.Element {
	const releaseStage = import.meta.env.VITE_RELEASE_STAGE
	const isDevApp = releaseStage !== 'production'
	const backgroundColor = releaseStage === 'developpment' ? '#0A2E56' : '#1ED37B'

	return (
		<>
			{isDevApp && (
				<Tooltip className="no-print" title={import.meta.env.VITE_GRAPHQL_ENDPOINT}>
					<div
						style={{
							position: 'fixed',
							bottom: 0,
							right: 2,
							paddingLeft: 10,
							paddingRight: 10,
							borderRadius: 4,
							justifyContent: 'center',
							display: 'flex',
							backgroundColor,
							color: 'white'
						}}
					>
						API {releaseStage}
					</div>
				</Tooltip>
			)}
		</>
	)
}

import { type Control, Controller } from 'react-hook-form'
import i18next from 'i18next'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { type ReactHookFormRules } from '#types/ReactHookFormRules'
import { type DateTime } from 'luxon'
import { TextField } from '@mui/material'

interface Props {
	control: Control
	rules?: ReactHookFormRules
	name: string
	defaultValue?: unknown
	disabled?: boolean
	timestamp?: boolean
}

export default function DatepickerField({
	control,
	rules,
	defaultValue,
	name,
	disabled = false,
	timestamp = true
}: Props): JSX.Element {
	const inputFormat = i18next.language === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'

	return (
		<Controller
			control={control}
			rules={rules}
			name={name}
			defaultValue={defaultValue}
			render={({ onChange, value }) => {
				return (
					<MobileDatePicker
						disabled={disabled}
						value={value}
						onChange={(newValue: DateTime | null) => {
							const serializedDate =
								newValue != null ? (timestamp ? Date.parse(newValue.toJSDate().toString()) : newValue.toJSDate()) : null
							onChange(serializedDate)
						}}
						inputFormat={inputFormat}
						renderInput={(params) => <TextField {...params} size="small" fullWidth disabled={disabled} />}
					/>
				)
			}}
		/>
	)
}

import { type FilterInput } from '@gqlTypes'

export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESET_FILTERS'

interface SetFiltersProps {
	filters: FilterInput[]
	location: string
}

interface SetFilters {
	type: string
	payload: SetFiltersProps
}

interface ResetFilters {
	type: string
	payload: {
		location?: string
		filters?: FilterInput[]
	}
}

export function setFilters({ filters, location }: SetFiltersProps): SetFilters {
	return {
		type: SET_FILTERS,
		payload: { filters, location }
	}
}

export function resetFilters(): ResetFilters {
	return {
		type: RESET_FILTERS,
		payload: {}
	}
}

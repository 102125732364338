import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

interface Props {
	translationKey: string
	count: number
}

export const UserImportResultCount = ({ translationKey, count }: Props): ReactElement | null => {
	const { t } = useTranslation()
	return <Typography>{t(`modal:userImport.${translationKey}`, { count })}</Typography>
}

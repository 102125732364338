import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback'
import Bugsnag from '@bugsnag/js'
import { type ReactElement, type ReactNode } from 'react'
interface Props {
	children: ReactNode
}

export const ErrorBoundary = ({ children }: Props): ReactElement => {
	return (
		<ReactErrorBoundary
			onError={(error, info) => {
				if (import.meta.env.NODE_ENV !== 'development') {
					Bugsnag.leaveBreadcrumb('Error boundary info', info, 'error')
					Bugsnag.notify(error)
				} else {
					console.error('ErrorBoundary Catch')
					console.log(error)
					console.log(info)
				}
			}}
			FallbackComponent={ErrorBoundaryFallback}
		>
			{children}
		</ReactErrorBoundary>
	)
}

import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ChipSelector } from '@shared/components/forms/fields/ChipSelector'
import { useGetLanguagesQuery } from '@gqlTypes'
import { ColumnLayout } from '@shared/components/layout/ColumnLayout'

export const LanguagesSelector = (): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useGetLanguagesQuery()

	return (
		<ColumnLayout
			title={t('offers:details.expectedSkills.languages.title')}
			tooltip={t('offers:details.expectedSkills.languages.tooltip')}
		>
			<ChipSelector name="requiredLanguageIds" items={data?.getLanguages ?? []} isLoading={isLoading} />
		</ColumnLayout>
	)
}

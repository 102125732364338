import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { SoftskillsIndicatorsLegend } from './SoftskillsIndicatorsLegend'
import { StarLabel } from './StarLabel'
import { type Indicator, Indicators } from './Indicators'
import { Box, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { type GetProfileQuery } from '@gqlTypes'

type Sharing = NonNullable<GetProfileQuery['getUserIntegration']>['sharing']

interface Props {
	isLoading: boolean
	aboveIndicators: Indicator[]
	belowIndicators: Indicator[]
	unevaluatedIndicators?: Indicator[]
	hasLegend?: boolean
	alignment: number[]
	sharedSoftSkillsTest: Sharing['sharedSoftSkillsTest']
	sharedSoftSkillsReturn: Sharing['sharedSoftSkillsReturn']
	interviewScore?: number
	isUnavailable?: boolean
	organizationSkillsAverage?: number
	title: string
}

export const SoftskillsIndicatorsCard = ({
	isLoading,
	aboveIndicators,
	belowIndicators,
	unevaluatedIndicators = [],
	hasLegend = false,
	alignment,
	sharedSoftSkillsTest,
	sharedSoftSkillsReturn,
	interviewScore,
	isUnavailable = false,
	organizationSkillsAverage,
	title
}: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<Box sx={{ pageBreakBefore: 'always' }}>
			<CardLayout title={title}>
				<LocalLoader load={isLoading}>
					<>
						{isUnavailable ? (
							<InfoCardContent>
								<Typography>{t('translation:candidates.profile.softSkills.unavailable')}</Typography>
							</InfoCardContent>
						) : (
							<TableLayout>
								{alignment != null ? (
									<StarLabel
										title={t('translation:candidates.profile.softSkills.alignment')}
										value={alignment?.length - 1}
										isAvailable={sharedSoftSkillsTest ?? false}
										unAvailableLabel={t('translation:candidates.profile.softSkills.unshared')}
									/>
								) : null}
								{interviewScore != null ? (
									<StarLabel
										title={t('translation:candidates.profile.softSkills.evaluation')}
										isAvailable
										value={interviewScore}
									/>
								) : null}
								{organizationSkillsAverage != null ? (
									<StarLabel
										title={t('translation:candidates.profile.softSkills.synthesis')}
										isAvailable
										value={organizationSkillsAverage}
									/>
								) : null}
								<Indicators
									title={t('translation:candidates.profile.softSkills.mainStrength')}
									indicators={aboveIndicators}
									color="secondary"
									delimiter
								/>
								<Indicators
									title={t('translation:candidates.profile.softSkills.mainWeaknesses')}
									indicators={belowIndicators}
									color="primary"
									delimiter
								/>
								<Indicators
									title={t('translation:candidates.profile.softSkills.unevaluated')}
									indicators={unevaluatedIndicators}
									delimiter
								/>
							</TableLayout>
						)}
						{hasLegend && (
							<SoftskillsIndicatorsLegend
								isSoftskillReturnShared={sharedSoftSkillsReturn ?? false}
								isSoftskillTestShared={sharedSoftSkillsTest ?? false}
							/>
						)}
					</>
				</LocalLoader>
			</CardLayout>
		</Box>
	)
}

import { type CSSProperties, type ReactElement } from 'react'
import Link from '@mui/material/Link'

interface Props {
	children?: JSX.Element | string
	url: string
	style?: CSSProperties
}

export const ExternalLink = ({ children, url, style }: Props): ReactElement => {
	return (
		<Link href={url} target="_blank" rel="noreferrer" underline="none" style={style}>
			{children ?? url}
		</Link>
	)
}

export const INTEGRATION_TYPE = {
	POSITION: 'position',
	CANDIDACY: 'candidacy',
	DISCUSSION: 'discussion'
}

export const INTEGRATION_NAME = {
	STUDENT: 'student',
	EMPLOYEE: 'employee',
	CANDIDATE: 'candidate',
	DISCUSSION: 'discussion'
}

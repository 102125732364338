export enum RoutePath {
	Home = '/',
	Candidates = '/candidates',
	Students = '/students',
	Employees = '/employees',
	CommunityMembers = '/community-members',
	Interviews = '/interview',
	Discussions = '/discussions',
	OrgaReturns = '/orgareturns',
	MyInterview = '/my-interviews',
	resumeLibrary = '/resumeLibrary',
	fixInterviewPdf = '/fixInterviewPdf'
}

import { Chip, Stack } from '@mui/material'
import { useOrganization } from '@domains/organization/useOrganization'
import { type Dispatch, type ReactElement, type SetStateAction } from 'react'

interface Props {
	skills: Record<string, boolean>
	setSkills: Dispatch<SetStateAction<Record<string, boolean>>>
}

export const OrgaSkillSection = ({ skills, setSkills }: Props): ReactElement => {
	const { orgaSkillsData } = useOrganization()
	const isSelected = (skill: string): boolean => skills[skill] != null && skills[skill]

	const onSelect = (skill: string): void => {
		const currentSkill = skills[skill]
		setSkills({ ...skills, [skill]: !currentSkill })
	}

	return (
		<Stack direction="row" gap={1} flexWrap="wrap">
			{orgaSkillsData.map((skill) => (
				<Chip
					key={skill.id}
					label={skill.label}
					color="primary"
					onClick={() => {
						onSelect(skill.id)
					}}
					variant={isSelected(skill.id) ? 'filled' : 'outlined'}
				/>
			))}
		</Stack>
	)
}

import { type ReactElement } from 'react'
import { Dialog } from '@mui/material'
import { InterviewSoftSkillRestitution } from './InterviewSoftSKillRestitution'

interface Props {
	interviewId: string
	skillId: string
	open: boolean
	setOpen: (open: boolean) => void
}

export const ImageReturnDialog = ({ interviewId, skillId, open, setOpen }: Props): ReactElement | null => {
	const handleClose = (): void => {
		setOpen(false)
	}

	if (!open) return null

	return (
		<Dialog open fullWidth maxWidth="lg" onClose={handleClose} PaperProps={{ sx: { borderRadius: 3 } }}>
			<InterviewSoftSkillRestitution {...{ interviewId, skillId, handleClose }} />
		</Dialog>
	)
}

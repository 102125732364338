import { useEffect, useState } from 'react'
import { findSoftSkillsIndicators } from '../../helper/evaluatedSkills.helper'
import { useErrorHandler } from 'react-error-boundary'

export default function useSoftskillsIndicators(infos) {
	const errorHandler = useErrorHandler()

	const [isSoftSkillLoading, setIsSoftSkillLoading] = useState(true)
	const [belowIndicators, setBelowIndicators] = useState([])
	const [aboveIndicators, setAboveIndicators] = useState([])

	// Get data for softkills indicators
	useEffect(() => {
		if (infos != null) {
			const { sharedSoftskillsRestitution, sharedSoftSkillsEvaluated, track, latestInterview } = infos
			setIsSoftSkillLoading(true)

			const offerSkills = track.trackSkills

			let below = []
			let above = []
			findSoftSkillsIndicators(
				offerSkills,
				sharedSoftSkillsEvaluated,
				sharedSoftskillsRestitution,
				latestInterview?.softSkills ?? []
			).forEach((indicator) => {
				indicator.isBelowExpectation ? (below = [...below, indicator]) : (above = [...above, indicator])
			})

			setBelowIndicators(below)
			setAboveIndicators(above)
			setIsSoftSkillLoading(false)
		}
	}, [errorHandler, infos])

	return {
		isSoftSkillLoading,
		aboveIndicators,
		belowIndicators
	}
}

export function findSoftSkillsIndicators(offerSkillList, evaluatedSkillList, skillRestitutions, latestInterview) {
	return offerSkillList.map((offerSkill) => {
		const skillData = {}
		let selfEvaluationScore = null
		let restitutionScore = null
		let interviewScore = null
		let interview = null
		const evaluated = evaluatedSkillList.find((skill) => skill.id === offerSkill.id)
		const restitution = skillRestitutions.find((skill) => skill.id === offerSkill.id)
		if (latestInterview) interview = latestInterview.find((skill) => skill.id === offerSkill.id)

		if (evaluated) {
			selfEvaluationScore = evaluated.score
		}

		if (restitution) {
			restitutionScore = restitution.value
		}

		if (interview) {
			interviewScore = interview.score
		}

		skillData.id = offerSkill.id
		skillData.delimiter = offerSkill.expectedScore
		skillData.selfEvaluationScore = selfEvaluationScore
		skillData.restitutionScore = restitutionScore
		skillData.score = interviewScore
		skillData.label = offerSkill.label
		skillData.isBelowExpectation = calculateScoreAverage(offerSkill.expectedScore, [
			selfEvaluationScore,
			restitutionScore,
			interviewScore
		])

		return skillData
	})
}

function calculateScoreAverage(expectedScore, scores) {
	const filteredScores = scores.filter((score) => score !== null)
	const sum = filteredScores.reduce((x, y) => x + y, 0)
	const avgScore = sum / filteredScores.length || 0

	return avgScore <= expectedScore
}

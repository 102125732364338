import { VideoEmbed } from '@ui/core/VideoEmbed'
import { Box, IconButton, Typography, Paper, Stack, Tooltip } from '@mui/material'
import { File } from './FIle'
import { type File as FileType, useSetHasWebOnboardingOpenMutation } from '@gqlTypes'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { firebaseAuth } from '../../../firebase/firebase.helper'
import { useTranslation } from 'react-i18next'
import { ErrorHandler } from '#helpers/error.helper'
import SupportIcon from '@mui/icons-material/Support'
import i18next from 'i18next'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import { type ReactElement } from 'react'
import { ParagraphWithBreakLine } from '@shared/components/ParagraphWithBreakLine'

interface Props {
	message: string
	video: string
	files: FileType[]
	isOpen: boolean
	firstname: string
}

export const Onboarding = ({ message, video, files, isOpen, firstname }: Props): ReactElement => {
	const hasWebOnboardingOpen = !isOpen
	const variables = { hasWebOnboardingOpen }
	const optimisticResponse = {
		setHasWebOnboardingOpen: {
			id: firebaseAuth()?.currentUser?.uid ?? '',
			__typename: 'User' as const,
			hasWebOnboardingOpen
		}
	}

	const [toogleIsOpenMutation] = useSetHasWebOnboardingOpenMutation({ variables, optimisticResponse })
	const { t } = useTranslation()

	const toogleIsOpen = (): void => {
		;(async () => {
			await toogleIsOpenMutation()
		})().catch(ErrorHandler)
	}
	const language = i18next.language.toUpperCase()
	return (
		<Box sx={{ position: 'absolute', right: 0, top: 0, zIndex: 20 }}>
			<Stack direction="row" sx={{ alignItems: 'start', mt: 0 }}>
				<Tooltip title={t('onboardingHelp:supportTooltip')}>
					<IconButton
						target="_blank"
						href={`https://forms.clickup.com/2550882/f/2dv32-2223/9D9Q7XE1I0BET3RLO3?Langue=${language}`}
					>
						<SupportIcon color="primary" />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('onboardingHelp:newsTooltip')}>
					<IconButton target="_blank" href="https://emage-me.com/newsletter">
						<MDIIcon color={'primary'} name="light-bulb-outline" />
					</IconButton>
				</Tooltip>
				{isOpen ? null : (
					<Tooltip title={t('onboardingHelp:onboardingTooltip')}>
						<IconButton sx={{ mr: 1 }} onClick={toogleIsOpen}>
							<HelpOutlineIcon color="primary" />
						</IconButton>
					</Tooltip>
				)}
				{isOpen ? (
					<Paper elevation={10} sx={{ width: 400, backgroundColor: '#f9fcff', minHeight: '100%' }}>
						<IconButton sx={{ mr: 1, mt: 1, position: 'absolute', right: 0 }} onClick={toogleIsOpen}>
							<CloseIcon color="primary" />
						</IconButton>
						<Box sx={{ width: 400, backgroundColor: '#f9fcff', minHeight: '100%' }}>
							<Box sx={{ padding: 4 }}>
								<Typography color="primary" variant="h4" sx={{ mr: 3 }}>
									{t('onboardingHelp:welcome', { firstname })}
								</Typography>
								<ParagraphWithBreakLine>{message}</ParagraphWithBreakLine>
								{video !== '' ? (
									<>
										<Typography color="primary" variant="h5" sx={{ mt: 3, mb: 1 }}>
											{t('onboardingHelp:video')}
										</Typography>
										<VideoEmbed src={video} style={{ height: 200 }} />
									</>
								) : null}
								{files.length > 0 ? (
									<>
										<Typography color="primary" variant="h5" sx={{ mt: 3, mb: 2 }}>
											{t('onboardingHelp:files')}
										</Typography>
										{files.map(({ name, link }) => (
											<File key={link} {...{ name, link }} />
										))}
									</>
								) : null}
							</Box>
						</Box>
					</Paper>
				) : null}
			</Stack>
		</Box>
	)
}

import { firestore } from '../../firebase/firebase.helper'
import { version } from '../../../package.json'
import { useEffect, useState } from 'react'
import { versionFactory } from './Version.factory'

interface Return {
	hasMinimumVersion: boolean
}

export const useMinimumVersion = (): Return => {
	const [hasMinimumVersion, setHasMinimumVersion] = useState(true)
	useEffect(() => {
		const minimumVersionRef = firestore().collection('Sys_ServerStatus').doc('MINIMUM_WEB_VERSION_FLAG')
		const unsubscribe = minimumVersionRef.onSnapshot(async (versionSnapshot) => {
			const minVersionValue = (versionSnapshot.data()?.value as string) ?? ''
			setHasMinimumVersion(versionFactory.build(version).isNewer(minVersionValue))
		})
		return () => {
			unsubscribe()
		}
	}, [])

	return { hasMinimumVersion }
}

import { SET_SELECTEDROWS, INITIALIZE_SELECTEDROWS } from '../actions/selectedRows.actions'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = []

const selectedRows = function (state = initialState, action) {
	switch (action.type) {
		case SET_SELECTEDROWS: {
			return action.payload
		}
		case INITIALIZE_SELECTEDROWS:
		case LOCATION_CHANGE: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export default selectedRows

import { type ReactElement } from 'react'
import { AllMeasureAreas } from './components/AllMeasureArea'
import { AllSelfAssessAreas } from './components/AllSelfAssessAreas'
import { BackgroundCircle } from './components/BackgroundCircle'
import { GraphCircle } from './components/GraphCircle'
import { GraphGrid } from './components/GraphGrid'
import { GraphIcons } from './components/GraphIcons'
import { useGraphRadar } from './hook/useGraphRadar'
import { type GraphDimension } from '@gqlTypes'
import { GraphRadarSize } from './GraphRadarSize'
import { Stack } from '@mui/material'

interface Props {
	dimensions: GraphDimension[]
	size?: GraphRadarSize
}

export const GraphRadar = ({ dimensions, size = GraphRadarSize.normal }: Props): ReactElement => {
	const { frameWidth, centerBlank, blankColor } = useGraphRadar(size)

	return (
		<Stack sx={{ alignItems: 'center' }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={frameWidth}
				height={frameWidth}
				viewBox={`0 0 ${frameWidth} ${frameWidth}`}
				fill="none"
			>
				<BackgroundCircle size={size} />
				<AllMeasureAreas dimensions={dimensions} size={size} />
				<GraphGrid dimensionCount={dimensions.length} size={size} />
				<AllSelfAssessAreas dimensions={dimensions} size={size} />
				<GraphCircle radius={centerBlank} fillColor={blankColor} size={size} />
				<GraphIcons dimensions={dimensions} size={size} />
			</svg>
		</Stack>
	)
}

import { DashboardSection } from '@application/dashboard/DashboardSection'
import { Layout } from '@application/dashboard/Layout'
import { Mode, ModeToggle } from '@application/dashboard/ModeToggle'
import { useGetInterviewsDashboardQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { Stack } from '@mui/material'
import { useFilters } from '@shared/hooks/useFilters'
import { InterviewsFilters } from '../list/InterviewsFilters'
import { InterviewsRouter } from '../Interviews.router'

export const InterviewsDashboard = (): ReactElement | null => {
	const { filters } = useFilters()
	const { data, loading, refetch } = useGetInterviewsDashboardQuery({
		variables: { filters: filters.filter((filter) => filter.value !== 'empty') }
	})
	const history = useHistory()
	const { t } = useCustomTranslation()

	if (loading || data == null) return null
	const sections = data.getInterviewsDashboard.sections ?? []
	const total = sections[0]?.tiles[0]?.indicators[0]?.value ?? 0

	return (
		<Layout
			title={t('interviews:title', { count: Number(total) })}
			toolbar={<InterviewsFilters refetch={refetch} />}
			headerLeftComponent={
				<ModeToggle
					mode={Mode.Dashboard}
					onToggle={() => {
						history.push(InterviewsRouter.paths.list)
					}}
				/>
			}
		>
			<Stack spacing={3}>
				{sections.map((section) => (
					<DashboardSection label={section.label} tiles={section.tiles} key={section.id} />
				))}
			</Stack>
		</Layout>
	)
}

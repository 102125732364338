import { type ReactElement } from 'react'
import { type InterviewSkill } from '@features/details/hooks/useInterviewSkills'
import { InterviewSkillEvaluation } from './InterviewSkillEvaluation'

interface Props {
	title: string
	skillList: InterviewSkill[]
	isReadOnly: boolean
	onClick: (skillId: string) => void
	isAssesseePreparation: boolean
	hasDetails?: boolean
}

export const InterviewEvaluationCard = ({
	skillList,
	isReadOnly,
	onClick,
	isAssesseePreparation,
	hasDetails = false
}: Props): ReactElement | null => {
	if (skillList.length === 0) return null

	return (
		<>
			{skillList.map((skill) => (
				<InterviewSkillEvaluation
					key={skill.id}
					skill={skill}
					onClick={onClick}
					isReadOnly={isReadOnly}
					isAssesseePreparation={isAssesseePreparation}
					hasDetails={hasDetails}
				/>
			))}
		</>
	)
}

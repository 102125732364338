import { FilterSortingDirection } from '@gqlTypes'
import { useLocation } from 'react-router-dom'
import { makeVar, useReactiveVar } from '@apollo/client'

export interface UseSortQuery {
	sortBy: FilterSortingItem[]
	setSortBy: (value: FilterSortingItem[]) => void
}

export interface FilterSortingItem {
	fieldName: string
	direction: FilterSortingDirection
}

export const interviewlist = [{ fieldName: 'targetDate', direction: FilterSortingDirection.Desc }]

const initialSortingItems = localStorage.getItem('filterSortingItems')
const sorts: Record<string, FilterSortingItem[]> = { interviewlist }
const sortingItemsByList = makeVar(
	initialSortingItems != null ? (JSON.parse(initialSortingItems) as Record<string, FilterSortingItem[]>) : sorts
)

export const useSortQuery = (): UseSortQuery => {
	const location = useLocation()
	const pathname = location.pathname.replaceAll('/', '')
	const sortBy = useReactiveVar(sortingItemsByList)[pathname] ?? []

	const setSortBy = (newSortItems: FilterSortingItem[]): void => {
		const newValue = { ...sortingItemsByList(), [pathname]: newSortItems }
		sortingItemsByList(newValue)
		localStorage.setItem('filterSortingItems', JSON.stringify(newValue))
	}

	return { sortBy, setSortBy }
}

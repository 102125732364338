import { ObjectiveTypeId, type GetInterviewObjectivesQuery } from '@gqlTypes'
import { Divider, Stack } from '@mui/material'
import { type ReactElement } from 'react'
import { InterviewObjectiveQualityRow } from './InterviewObjectiveQualityRow'
import { InterivewObjectiveQuantityRow } from './InterviewObjectiveQuantityRow'

interface Props {
	interviewId: string
	objectives: GetInterviewObjectivesQuery['getInterviewObjectives'][0][]
	isReadOnly: boolean
}

export const InterviewObjectiveList = ({ objectives, interviewId, isReadOnly }: Props): ReactElement => {
	return (
		<>
			<Stack p={4}>
				{objectives.map((objective, index) => (
					<>
						{objective.type.id === ObjectiveTypeId.Quality ? (
							<InterviewObjectiveQualityRow {...objective} interviewId={interviewId} isReadOnly={isReadOnly} />
						) : (
							<InterivewObjectiveQuantityRow {...objective} interviewId={interviewId} isReadOnly={isReadOnly} />
						)}
						{index < objectives.length - 1 ? <Divider /> : null}
					</>
				))}
			</Stack>
		</>
	)
}

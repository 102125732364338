export function getRowsData(indexList, rowList) {
	return indexList.map((index) => {
		return rowList[index]
	})
}

export function getRowIds(indexList, rowList) {
	return indexList.map((index) => {
		return rowList[index].id
	})
}

export const massUpdate = (rowList, updateFunction, payload) => {
	return Promise.all(rowList.map((row) => updateFunction(row.id, payload)))
}

// find selected rows and update the value specified in the mass action
export const updateDataTable = (selectedRowList, rowList, key, newValue) => {
	const selectedCopy = deepCopyFunction(selectedRowList)
	const rowsCopy = deepCopyFunction(rowList)

	const rowIdList = selectedCopy.map((row) => row.id)
	return rowsCopy.map((row) => {
		if (rowIdList.find((element) => element === row.id)) {
			return { ...row, [key]: newValue }
		} else {
			return { ...row }
		}
	})
}

export const deepCopyFunction = (inObject) => {
	let outObject, value, key

	if (typeof inObject !== 'object' || inObject === null) {
		return inObject // Return the value if inObject is not an object
	}

	// Create an array or object to hold the values
	// eslint-disable-next-line prefer-const
	outObject = Array.isArray(inObject) ? [] : {}

	for (key in inObject) {
		value = inObject[key]

		// Recursively (deep) copy for nested objects, including arrays
		outObject[key] = deepCopyFunction(value)
	}

	return outObject
}

import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import useDiscussion from '../../../features/details/hooks/useDiscussion'
import { DiscussionContent } from '../DiscussionContent'
import { type useDetailsReturns } from '@features/details/hooks/useDetails'
import { type ReactElement } from 'react'

interface Props {
	infos: useDetailsReturns
}

export const Discussion = ({ infos }: Props): ReactElement => {
	const { t } = useTranslation(['translation'])
	const discussionUser = useDiscussion(infos.integrationInfo)
	const displayName = infos.integrationInfo?.userInfo?.displayName

	useDocumentTitle(t('translation:candidates.discussion.title', { name: displayName }))

	return (
		<DiscussionContent
			discussionUser={discussionUser.discussionUser}
			setDiscussionUser={discussionUser.setDiscussionUser}
			userId={infos.userInfo?.userId ?? ''}
			displayName={displayName ?? ''}
		/>
	)
}

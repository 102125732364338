import { type GraphDimension } from '@gqlTypes'
import { GraphIcon } from './GraphIcon'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	dimensions: GraphDimension[]
	size: GraphRadarSize
}

export function GraphIcons({ dimensions, size }: Props): ReactElement {
	return (
		<>
			{dimensions.map((dimension, index) => (
				<GraphIcon
					key={index}
					measureIndex={index}
					measuresCount={dimensions.length}
					icon={dimension.icon}
					color={dimension.color}
					size={size}
				/>
			))}
		</>
	)
}

import { type ReactElement } from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { OffersPath } from '../../OffersRouter'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const EmptyJobMatchingList = (): ReactElement => {
	const { t } = useTranslation()
	return (
		<Stack gap={3} alignItems="center" mt={5}>
			<Typography>{t('offers:empty')}</Typography>
			<img src="/assets/img/offers.svg" />
			<Button component={Link} to={OffersPath.create} color="secondary" variant="contained">
				{t('offers:createButton')}
			</Button>
		</Stack>
	)
}

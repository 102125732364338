import InputTextField from '@shared/components/forms/fields/inputTextField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: string
}

export default function UrlField({ control, defaultValue }: Props): JSX.Element {
	const { t } = useTranslation()
	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.url.title')}
			tooltip={t('tracks:details.formFields.url.tooltip')}
		>
			<InputTextField control={control} name="url" defaultValue={defaultValue} />
		</TableLayoutRow>
	)
}

import { type ReactElement, useEffect, useState } from 'react'
import { Stack, ThemeProvider } from '@mui/material'
import theme from './ui/theme'
import { GlobalLoader } from './shared/components/layout/GlobalLoader'
import MyStoreProvider, { history } from './shared/store/MyStoreProvider'
import { GlobalLoaderFeedback } from './shared/components/layout/GlobalLoaderFeedback'
import EnvIndicator from './shared/components/EnvIndicator'
import { RouterHandler } from './router/RouterHandler'
import initTranslations from './translations/translation.helper'
import { LocalLoader } from './shared/components/layout/LocalLoader'
import { ErrorBoundary } from './shared/components/errors/ErrorBoundary'
import { Auth } from './features/login/Auth'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ConnectedRouter } from 'connected-react-router'
import { version } from '../package.json'
import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	ApolloProvider,
	from,
	ApolloLink,
	type FieldPolicy,
	type FieldFunctionOptions,
	type StoreObject
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { ErrorHandler } from './helper/error.helper'
import i18next from 'i18next'
import { firebaseAuth } from './firebase/firebase.helper'
import { MainToolbar } from './domains/mainToolbar/MainToolbar'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { MuiSnackbarProvider } from './application/snackbar/MuiSnackbarProvider'
import { MinimumVersionProvider } from './application/version/MinimumVersionProvider'

const uri = import.meta.env.VITE_GRAPHQL_ENDPOINT
const httpLink = createHttpLink({ uri })
const removeTypenameLink = removeTypenameFromVariables()

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
	if (graphQLErrors != null) {
		const { operationName, variables } = operation
		Bugsnag.leaveBreadcrumb(`GQL ${operationName}`, { variables }, 'error')
		graphQLErrors.forEach(({ message, path, extensions }) => {
			const pathString = path?.join('.') ?? ''
			console.error({ message, path })
			Bugsnag.notify(new Error(message), function (event) {
				event.errors[0].errorClass = `GraphQL ${pathString}`
				const stacktrace = (extensions.stacktrace as string[]) ?? []
				event.errors[0].stacktrace = stacktrace.map((row) => {
					return { file: row }
				})
			})
		})
	}
	if (networkError != null) ErrorHandler('Network error', networkError)
})

const bugsnagLink = new ApolloLink((operation, forward) => {
	const { operationName, variables } = operation
	Bugsnag.leaveBreadcrumb(`GQL ${operationName}`, { variables }, 'request')
	return forward(operation)
})

const authLink = setContext(async (_, { headers }) => {
	let token = ''
	const user = firebaseAuth().currentUser
	if (user != null) token = await user.getIdToken()

	return {
		headers: {
			...headers,
			authorization: token !== '' ? `Bearer ${token}` : '',
			language: i18next.language
		}
	}
})

interface Incoming {
	cursor: string
	hasNextPage: boolean
	nodes: StoreObject[]
	count: number
}

interface Existing {
	cursor: string
	hasNextPage: boolean
	nodes: Record<string, StoreObject>
	count: number
}

const paginate: FieldPolicy<
	Existing,
	Incoming,
	unknown,
	FieldFunctionOptions<Record<string, unknown>, Record<string, unknown>>
> = {
	merge(existing, incoming, { readField, args }) {
		const mergedNodes: Record<string, unknown> = existing != null ? { ...existing.nodes } : {}
		incoming.nodes.forEach((node) => {
			mergedNodes[readField('id', node)] = node
		})
		return {
			cursor: incoming.cursor,
			hasNextPage: incoming.hasNextPage,
			count: incoming.count,
			nodes: (args?.cursor ?? '') === '' ? incoming.nodes : Object.values(mergedNodes)
		}
	},

	read(existing) {
		if (existing != null) {
			return {
				cursor: existing.cursor,
				hasNextPage: existing.hasNextPage,
				nodes: Object.values(existing.nodes),
				count: existing.count
			}
		}
	}
}

const cache = new InMemoryCache({
	typePolicies: {
		Question: {
			keyFields: ['compositKey']
		},
		SerializedSkillDetails: {
			keyFields: ['id', 'score', 'questionLabel']
		},
		MotivationQuestion: {
			keyFields: ['id', 'answer']
		},
		UserRef: {
			keyFields: ['userId']
		},
		Query: {
			fields: {
				getUserInterviewList: paginate,
				getEmployeesList: paginate,
				getCommunityMembersList: paginate,
				getStudentsList: paginate,
				getTrackOfferList: paginate,
				getTrackPositionList: paginate,
				getUserOfferList: paginate,
				getDiscussionUserList: paginate,
				getOrganizationUserList: paginate,
				getInterviewTemplateList: paginate,
				getOfferList: paginate,
				getJobSearchList: paginate
			}
		}
	}
})

const client = new ApolloClient({
	link: from([removeTypenameLink, authLink, bugsnagLink, errorLink, httpLink]),
	cache
})

Bugsnag.start({
	apiKey: 'abecf56eda38e52dc9828a4c92e03366',
	plugins: [new BugsnagPluginReact()],
	appVersion: version,
	releaseStage: import.meta.env.VITE_RELEASE_STAGE
})

export const getHeaderHeight = (): number => {
	return 64
}

export const App = (): ReactElement => {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		void initTranslations().then(() => {
			setLoading(false)
		})
	}, [])

	if (loading) {
		return <LocalLoader />
	}
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<MyStoreProvider>
					<MuiSnackbarProvider>
						<ErrorBoundary>
							<ConnectedRouter history={history}>
								<MinimumVersionProvider>
									<Auth>
										<Stack direction="row" flex={1} gap={1}>
											<MainToolbar />
											<>
												<GlobalLoaderFeedback />
												<RouterHandler />
											</>
										</Stack>
									</Auth>
									<EnvIndicator />
								</MinimumVersionProvider>
							</ConnectedRouter>
							<GlobalLoader />
						</ErrorBoundary>
					</MuiSnackbarProvider>
				</MyStoreProvider>
			</ThemeProvider>
		</ApolloProvider>
	)
}

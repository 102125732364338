import { type GetInterviewObjectivesQuery } from '@gqlTypes'
import { Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { InterivewObjectiveRow } from './InterviewObjectiveRow'

interface Props {
	id: string
	label: string
	interviewId: string
	status: GetInterviewObjectivesQuery['getInterviewObjectives'][0]['status']
	quality: GetInterviewObjectivesQuery['getInterviewObjectives'][0]['quality']
	isShortTerme: boolean
	isReadOnly: boolean
}

export const InterviewObjectiveQualityRow = ({
	id,
	label,
	quality,
	status,
	interviewId,
	isShortTerme,
	isReadOnly
}: Props): ReactElement => {
	const { completion, tendency, target, nextTarget, reevaluatedTarget, previousCompletion } = quality
	return (
		<InterivewObjectiveRow
			id={id}
			label={label}
			status={status}
			completion={completion}
			tendency={tendency}
			previousCompletion={previousCompletion}
			interviewId={interviewId}
			isShortTerme={isShortTerme}
			targetComponent={<Typography>{target}</Typography>}
			nextTargetComponent={<Typography>{nextTarget}</Typography>}
			reevaluatedTargetComponent={<Typography>{reevaluatedTarget ?? ''}</Typography>}
			isReadOnly={isReadOnly}
		/>
	)
}

import {
	generateRoute,
	ROUTE_ACCESS_MANAGEMENT,
	ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION,
	ROUTE_CANDIDATE_DETAIL_TAB_INFO,
	ROUTE_CANDIDATE_DETAIL_TAB_PROFILE,
	ROUTE_EMPLOYEES_DETAIL_TAB_INFO,
	ROUTE_EMPLOYEES_DETAIL_TAB_PROFILE,
	ROUTE_EMPLOYEES_DETAIL_TAB_DISCUSSION,
	ROUTE_STUDENTS_DETAIL_TAB_INFO,
	ROUTE_STUDENTS_DETAIL_TAB_PROFILE,
	ROUTE_STUDENTS_DETAIL_TAB_DISCUSSION,
	ROUTE_CANDIDATE_DETAIL_TAB_RETURNS,
	ROUTE_EMPLOYEES_DETAIL_TAB_RETURNS,
	ROUTE_STUDENTS_DETAIL_TAB_RETURNS,
	ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN,
	ROUTE_EMPLOYEES_DETAIL_TAB_ORGA_RETURN,
	ROUTE_STUDENTS_DETAIL_TAB_ORGA_RETURN,
	ROUTE_COMMUNITY_MEMBERS_TAB_INFO,
	ROUTE_COMMUNITY_MEMBERS_TAB_PROFILE,
	ROUTE_COMMUNITY_MEMBERS_TAB_DISCUSSION,
	ROUTE_COMMUNITY_MEMBERS_TAB_RETURNS,
	ROUTE_COMMUNITY_MEMBERS_TAB_ORGA_RETURN,
	ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION,
	ROUTE_EMPLOYEES_DETAIL_TAB_PROGRESSION,
	ROUTE_STUDENTS_DETAIL_TAB_PROGRESSION,
	ROUTE_COMMUNITY_MEMBERS_DETAIL_TAB_PROGRESSION,
	ROUTE_CANDIDATE_DETAIL_TAB_CAREER,
	ROUTE_EMPLOYEES_DETAIL_TAB_CAREER,
	ROUTE_STUDENTS_DETAIL_TAB_CAREER
} from '../../../../router/routes'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import { type ReactElement } from 'react'
import { ScreenId, useMeQuery } from '@gqlTypes'

interface Access {
	id: string
	label: string
	url: string
}

export interface Tab {
	id: string
	path: string
	icon: ReactElement
	translationKey: string
}

export function getAccessManagment(): Access {
	return {
		id: ScreenId.AdminAccess,
		label: 'accessManagment',
		url: ROUTE_ACCESS_MANAGEMENT
	}
}

export function getCandidacyTabs(userOfferId: string): Tab[] {
	const { data } = useMeQuery({ fetchPolicy: 'network-only' })
	const hasCareer: boolean = data?.me?.role?.tabs?.candidate.includes('candidate-careerMatching')
	const tabs = [
		{
			id: 'candidate-info',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_INFO, { userOfferId }),
			icon: <InfoOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-info'
		},
		{
			id: 'candidate-profile',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_PROFILE, { userOfferId }),
			icon: <AccountCircleOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-profile'
		},
		{
			id: 'candidate-discussion',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION, { userOfferId }),
			icon: <ChatOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-discussion'
		},
		{
			id: 'candidate-progression',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION, { userOfferId }),
			icon: <TrendingUpIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-interview'
		},
		{
			id: 'candidate-returns',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_RETURNS, { userOfferId }),
			icon: <RecordVoiceOverOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returns'
		},
		{
			id: 'candidate-returnOrga',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN, { userOfferId }),
			icon: <MDIIcon name="comment-question-outline" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returnOrga'
		}
	]
	if (hasCareer) {
		tabs.push({
			id: 'candidate-careerMatching',
			path: generateRoute(ROUTE_CANDIDATE_DETAIL_TAB_CAREER, { userOfferId }),
			icon: <MDIIcon name="compas" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-careerMatching'
		})
	}
	return tabs
}

export function getEmployeesTabs(userPositionId: string): Tab[] {
	const { data } = useMeQuery({ fetchPolicy: 'network-only' })
	const hasCareer: boolean = data?.me?.role?.tabs?.candidate.includes('employee-careerMatching')
	const tabs = [
		{
			id: 'employee-info',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_INFO, { userPositionId }),
			icon: <InfoOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-info'
		},
		{
			id: 'employee-profile',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_PROFILE, { userPositionId }),
			icon: <AccountCircleOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-profile'
		},
		{
			id: 'employee-discussion',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_DISCUSSION, { userPositionId }),
			icon: <ChatOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-discussion'
		},
		{
			id: 'employee-progression',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_PROGRESSION, { userPositionId }),
			icon: <TrendingUpIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-progression'
		},
		{
			id: 'employee-returns',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_RETURNS, { userPositionId }),
			icon: <RecordVoiceOverOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returns'
		},
		{
			id: 'employee-returnOrga',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_ORGA_RETURN, { userPositionId }),
			icon: <MDIIcon name="comment-question-outline" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returnOrga'
		}
	]
	if (hasCareer) {
		tabs.push({
			id: 'employee-careerMatching',
			path: generateRoute(ROUTE_EMPLOYEES_DETAIL_TAB_CAREER, { userPositionId }),
			icon: <MDIIcon name="compas" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-careerMatching'
		})
	}
	return tabs
}

export function getStudentsTabs(userPositionId: string): Tab[] {
	const { data } = useMeQuery({ fetchPolicy: 'network-only' })
	const hasCareer: boolean = data?.me?.role?.tabs?.candidate.includes('student-careerMatching')
	const tabs = [
		{
			id: 'student-info',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_INFO, { userPositionId }),
			icon: <InfoOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-info'
		},
		{
			id: 'student-profile',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_PROFILE, { userPositionId }),
			icon: <AccountCircleOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-profile'
		},
		{
			id: 'student-discussion',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_DISCUSSION, { userPositionId }),
			icon: <ChatOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-discussion'
		},
		{
			id: 'student-progression',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_PROGRESSION, { userPositionId }),
			icon: <TrendingUpIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-progression'
		},
		{
			id: 'student-returns',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_RETURNS, { userPositionId }),
			icon: <RecordVoiceOverOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returns'
		},
		{
			id: 'student-returnOrga',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_ORGA_RETURN, { userPositionId }),
			icon: <MDIIcon name="comment-question-outline" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returnOrga'
		}
	]
	if (hasCareer) {
		tabs.push({
			id: 'student-careerMatching',
			path: generateRoute(ROUTE_STUDENTS_DETAIL_TAB_CAREER, { userPositionId }),
			icon: <MDIIcon name="compas" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-careerMatching'
		})
	}
	return tabs
}

export function getCommunityMembersTabs(userPositionId: string): Tab[] {
	return [
		{
			id: 'communityMembers-info',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_TAB_INFO, { userPositionId }),
			icon: <InfoOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-info'
		},
		{
			id: 'communityMembers-profile',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_TAB_PROFILE, { userPositionId }),
			icon: <AccountCircleOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-profile'
		},
		{
			id: 'communityMembers-discussion',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_TAB_DISCUSSION, { userPositionId }),
			icon: <ChatOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-discussion'
		},
		{
			id: 'communityMembers-progression',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_DETAIL_TAB_PROGRESSION, { userPositionId }),
			icon: <TrendingUpIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-progression'
		},
		{
			id: 'communityMembers-returns',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_TAB_RETURNS, { userPositionId }),
			icon: <RecordVoiceOverOutlinedIcon />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returns'
		},
		{
			id: 'communityMembers-returnOrga',
			path: generateRoute(ROUTE_COMMUNITY_MEMBERS_TAB_ORGA_RETURN, { userPositionId }),
			icon: <MDIIcon name="comment-question-outline" sx={{ mr: 1 }} />,
			translationKey: 'translation:candidates.tabsLabels.candidate-returnOrga'
		}
	]
}

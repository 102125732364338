import { Chip } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	progression?: number
	isLabelGreen?: boolean
}

export const ProgressionChip = ({ progression, isLabelGreen = false }: Props): ReactElement | null => {
	if (progression == null) return null

	const color = progression === 100 || isLabelGreen ? 'secondary' : 'warning'

	return <Chip variant="twoTone" label={`${Math.round(progression)}%`} size="small" color={color} />
}

import Typography from '@mui/material/Typography'
import { useFormContext } from 'react-hook-form'
import { type QuestionWithAnswer } from '../../../../types/QuestionWithAnswer'
import InputTextField from './inputTextField'
import { type ReactElement } from 'react'
import { Box, Grid } from '@mui/material'
import { TypographyWithLink } from './TypographyWithLink'

interface Props extends QuestionWithAnswer {
	isEditable: boolean
}

export const Question = ({ id, label, answer, isEditable }: Props): ReactElement => {
	const { control } = useFormContext()

	return (
		<Grid container>
			<Grid item xs={4}>
				<TypographyWithLink text={label} sx={{ color: 'lightBlue.main' }} />
			</Grid>

			<Grid item xs={8}>
				<Box ml={5}>
					{isEditable ? (
						<InputTextField control={control} name={`answers[${id}]`} multiline defaultValue={answer} />
					) : (
						<Typography color="primary">{answer}</Typography>
					)}
				</Box>
			</Grid>
		</Grid>
	)
}

import { type ReactElement } from 'react'
import { type GraphRadarSize } from '../GraphRadarSize'
import { useGraphRadar } from '../hook/useGraphRadar'

interface Props {
	cx?: number
	cy?: number
	radius: number
	fillColor?: string
	strokeColor?: string
	isTinyStroke?: boolean
	size: GraphRadarSize
}

export const GraphCircle = ({
	cx,
	cy,
	radius,
	fillColor = 'none',
	strokeColor = 'none',
	isTinyStroke = false,
	size
}: Props): ReactElement => {
	const { centerX, centerY, arcWith, tineStrokeWidth } = useGraphRadar(size)
	const strokeWidth = isTinyStroke ? tineStrokeWidth : arcWith

	return (
		<circle
			cx={cx ?? centerX}
			cy={cy ?? centerY}
			r={radius}
			fill={fillColor}
			stroke={strokeColor}
			strokeWidth={strokeWidth}
		/>
	)
}

import { Stack, Typography, Button, IconButton } from '@mui/material'
import { type ReactElement } from 'react'
import theme from '@ui/theme'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

interface Props {
	title: string
	buttonLabel: string
	hasLegend?: boolean
	toggleDisplayLegend: () => void
	toggleDisplayChildren: () => void
	isChildrenOpen: boolean
}

export const ProgressionHeader = ({
	title,
	buttonLabel,
	hasLegend = false,
	toggleDisplayLegend,
	toggleDisplayChildren,
	isChildrenOpen
}: Props): ReactElement | null => {
	const iconSize = { width: 28, height: 28 }

	return (
		<Stack direction="row" alignItems="center">
			<IconButton onClick={toggleDisplayChildren}>
				{isChildrenOpen ? <KeyboardArrowDownIcon sx={iconSize} /> : <ChevronRightIcon sx={iconSize} />}
			</IconButton>
			<Typography sx={{ fontWeight: 'bold', fontSize: 20, color: theme.palette.primary.main }}>{title}</Typography>
			{hasLegend ? (
				<Button variant="text" sx={{ textDecoration: 'underline' }} onClick={toggleDisplayLegend}>
					{buttonLabel}
				</Button>
			) : null}
		</Stack>
	)
}

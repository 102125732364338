import type { MouseEvent } from 'react'
import { MouseSensor as LibMouseSensor } from '@dnd-kit/core'

export class MouseSensor extends LibMouseSensor {
	static activators = [
		{
			eventName: 'onMouseDown' as const,
			handler: ({ nativeEvent: event }: MouseEvent): boolean => {
				return shouldHandleEvent(event.target as HTMLElement)
			}
		}
	]
}

function shouldHandleEvent(element: HTMLElement | null): boolean {
	while (element != null) {
		const a = element.dataset != null
		const b = element.dataset.noDnd != null
		if (a && b) return false
		element = element.parentElement
	}
	return true
}

import { type ReactNode, type ReactElement } from 'react'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { Link, TableRow } from '@mui/material'

interface Props {
	title: string
	value?: ReactNode
	link?: string
	icon?: ReactElement
}
export default function InfoValue({ title, value, link, icon }: Props): JSX.Element | null {
	if (value == null || value === '') return null
	return (
		<TableRow>
			<InfoLayoutLabel icon={icon}>{title}</InfoLayoutLabel>
			<InfoLayoutValue>
				{link == null && value}
				{link != null && (
					<Link color="lightBlue.main" href={value} target="_blank" rel="noreferrer">
						{link}
					</Link>
				)}
			</InfoLayoutValue>
		</TableRow>
	)
}

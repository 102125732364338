import { SET_ISLOADING, INITIALIZE_ISLOADING } from './isLoading.actions'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = false

const isLoading = function (state = initialState, action) {
	switch (action.type) {
		case SET_ISLOADING: {
			return action.payload
		}
		case INITIALIZE_ISLOADING:
		case LOCATION_CHANGE: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export default isLoading

import { useTranslation } from 'react-i18next'
import { Box, Button, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { LoadingButton } from '@mui/lab'
import { useFormContext, useWatch } from 'react-hook-form'
import { OffersPath } from '../OffersRouter'
import { Link } from 'react-router-dom'
import { type OfferInput, useUpdateOfferMutation } from '@gqlTypes'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import BackButton from '@shared/components/buttons/BackButton'

interface Props {
	isSaving: boolean
	save: (offerInput: OfferInput) => Promise<void>
	onSaveError: () => void
	saveLabel: string
	isActive: boolean
	offerId: string
}

export const OfferFormTopBar = ({ isSaving, save, saveLabel, isActive, offerId, onSaveError }: Props): ReactElement => {
	const { t } = useTranslation()
	const label = useWatch({ name: 'label' })
	const { handleSubmit } = useFormContext()
	const { onError, onCompleted } = useGqlSnackBar('offers:details.snackbar.update')
	const [updateOfferMutation, { loading: isUpdating }] = useUpdateOfferMutation({ onError, onCompleted })

	const toogleIsActive = async (): Promise<void> => {
		await updateOfferMutation({ variables: { offerId, offerInput: { isActive: !isActive } } })
	}

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
			<Box sx={{ ml: -2 }}>
				<BackButton route={OffersPath.list} />
			</Box>
			<Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
				{t('offers:details.topBar.title')}
			</Typography>
			<Typography sx={{ ml: 2, flexGrow: 1, fontWeight: 'bold' }} variant="h6" color="secondary">
				{label}
			</Typography>
			<Stack direction="row" gap={1}>
				{offerId !== '' ? (
					<LoadingButton variant="contained" loading={isUpdating} onClick={toogleIsActive}>
						{t(`offers:details.topBar.button.archive.${!isActive}`)}
					</LoadingButton>
				) : (
					<Button variant="contained" component={Link} to={OffersPath.list}>
						{t('offers:details.topBar.button.goBack')}
					</Button>
				)}
				<LoadingButton
					loading={isSaving}
					onClick={handleSubmit(save, onSaveError)}
					variant="contained"
					color="secondary"
				>
					{saveLabel}
				</LoadingButton>
			</Stack>
		</Box>
	)
}

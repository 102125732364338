import { useEffect } from 'react'

export const useDocumentTitle = (title: string, retainOnUnmount: boolean = false): void => {
	const defaultTitle = document.title

	useEffect(() => {
		document.title = title
		return () => {
			if (!retainOnUnmount) {
				document.title = defaultTitle
			}
		}
	}, [title, defaultTitle, retainOnUnmount])
}

const params = {
	normal: {
		frameWidth: 400,
		frameMargin: 65,
		iconWidth: 33.6,
		iconSize: 1.2
	},
	small: {
		frameWidth: 260,
		frameMargin: 40,
		iconWidth: 25,
		iconSize: 1
	}
}
interface UseGraphRadar {
	polarToCartesian: (radius: number, angleInDegrees: number) => Coordinates
	centerX: number
	centerY: number
	arcWith: number
	tineStrokeWidth: number
	frameWidth: number
	frameMargin: number
	centerBlank: number
	blankColor: string
	iconWidth: number
	backColor: string
	shadowColor: string
	iconSize: number
}

interface Coordinates {
	x: number
	y: number
}

export const useGraphRadar = (size: 'normal' | 'small'): UseGraphRadar => {
	const frameWidth = params[size].frameWidth
	const frameMargin = params[size].frameMargin

	const centerX = frameWidth / 2
	const centerY = frameWidth / 2

	const centerBlank = 16
	const arcWith = 2
	const tineStrokeWidth = 1
	const iconWidth = params[size].iconWidth
	const iconSize = params[size].iconSize
	const backColor = '#EEEEEE'
	const blankColor = '#FFFFFF'
	const shadowColor = '#D9D9D9'

	// Calculate x/y coordinates of a point based on polar (center-radius-angle)
	const polarToCartesian = (radius: number, angleInDegrees: number): Coordinates => {
		const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians)
		}
	}

	return {
		polarToCartesian,
		centerX,
		centerY,
		arcWith,
		tineStrokeWidth,
		frameWidth,
		frameMargin,
		centerBlank,
		blankColor,
		iconWidth,
		backColor,
		shadowColor,
		iconSize
	}
}

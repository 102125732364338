import { getRowIds } from '../../../helper/massActions.helper'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useFilters } from '@shared/hooks/useFilters'
import { useZipInterviewsPdfMutation } from '@gqlTypes'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import { useSnackbar } from 'notistack'
import { Severities } from '@application/snackbar/Severities'
import { dom } from '@shared/dom'
import { type ReactElement } from 'react'
import { IconAction } from '@shared/icon/IconAction'
import { searchQuery } from '@features/lists/components/Search'

interface Props {
	isAllRowsSelected: boolean
	hasPdfExport: boolean
	dataList: { id: string }[]
}

export const ZipInterviewsPdf = ({ isAllRowsSelected = false, dataList = [], hasPdfExport }: Props): ReactElement => {
	const { t } = useTranslation()
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const { filters: filtersState } = useFilters()
	const { enqueueSnackbar } = useSnackbar()
	const [zipInterviewsPdfMutation, { loading: isLoading }] = useZipInterviewsPdfMutation()

	const zipInterviewsPdf = async (): Promise<void> => {
		const interviewIds = isAllRowsSelected ? [] : getRowIds(selectedRows, dataList)
		const filters = [...filtersState].filter((filter) => filter.value !== 'empty')
		const variables = { filters, interviewIds, searchQuery: searchQuery() }
		const result = await zipInterviewsPdfMutation({ variables })
		const path = result.data?.zipInterviewsPdf ?? ''
		dom.downloadFile(path)
		if (path === '') enqueueSnackbar(t('interviews:pdfZip.empty'), { variant: Severities.error })
	}

	const disabled = selectedRows.length < 1 || isLoading
	const tooltip = t('interviews:pdfZip.tooltip')

	return (
		<div>
			{hasPdfExport ? (
				<IconAction {...{ tooltip, isLoading, disabled }} onClick={zipInterviewsPdf}>
					<MDIIcon name="file-pdf-box" />
				</IconAction>
			) : null}
		</div>
	)
}

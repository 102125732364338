import { Stack, Typography } from '@mui/material'
import { InputTextFieldForm } from '@shared/components/forms/fields/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { emailRegex } from '../../constants/emailRegex'

export const OrganizationUserInvitation = (): ReactElement => {
	const { t } = useTranslation()
	const { control } = useFormContext()

	const emails = useWatch({ name: 'usersEmails', defaultValue: '' })
	const count = (emails.match(emailRegex) ?? []).length
	const message = t('organizationCreation:requiredField')
	return (
		<Stack gap={2}>
			<Typography variant="h6" pb={1} color="primary">
				<b>{t('organizationCreation:users.title', { count })}</b>
			</Typography>
			<InputTextFieldForm
				multiline
				rows={7}
				control={control}
				name="usersEmails"
				label={t('organizationCreation:users.field.emails')}
				rules={{ required: { value: true, message, pattern: { value: emailRegex, message } } }}
			/>
		</Stack>
	)
}

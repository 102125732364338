import { Version } from './Version'

class VersionFactory {
	build(version: string): Version {
		const [major, minor, patch] = version.split('.').map((versionPart) => parseInt(versionPart))

		return new Version({ major, minor, patch })
	}
}

export const versionFactory = new VersionFactory()

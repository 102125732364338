import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { type ReactElement } from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

export enum Mode {
	List,
	Dashboard
}

interface Props {
	mode: Mode
	onToggle: () => void
}

export const ModeToggle = ({ mode, onToggle }: Props): ReactElement => {
	const isDashboard = mode === Mode.Dashboard
	const isList = mode === Mode.List

	return (
		<ToggleButtonGroup
			size="small"
			color="primary"
			value={mode}
			exclusive
			onChange={() => {
				onToggle()
			}}
		>
			<ToggleButton disabled={isDashboard} value={Mode.Dashboard}>
				<DashboardIcon />
			</ToggleButton>
			<ToggleButton disabled={isList} value={Mode.List}>
				<FormatListBulletedIcon />
			</ToggleButton>
		</ToggleButtonGroup>
	)
}

import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	value?: boolean
	translationKey: string
	isReversedValue: boolean
	onChange?: (value: string) => void
}

export const IncludeExcludeCheckbox = ({
	value = false,
	translationKey,
	isReversedValue,
	onChange
}: Props): ReactElement => {
	const { t } = useTranslation()
	const onToggleChange = (_e: unknown, value: string): void => {
		if (value == null || onChange == null) return
		onChange(value)
	}

	return (
		<ToggleButtonGroup color="primary" value={value} exclusive onChange={onToggleChange}>
			<ToggleButton value="empty">{t('translation:includeExcludeFilter.noFilter')}</ToggleButton>
			<ToggleButton value>{t(`${translationKey}.${isReversedValue ? 'false' : 'true'}`)}</ToggleButton>
			<ToggleButton value={false}>{t(`${translationKey}.${isReversedValue ? 'true' : 'false'}`)}</ToggleButton>
		</ToggleButtonGroup>
	)
}

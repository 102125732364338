import { FilterForm } from '@ui/filter/FilterForm'
import { useFilters } from '@shared/hooks/useFilters'
import { type ReactNode, type ReactElement } from 'react'
import { type FilterInput } from '@gqlTypes'

interface Props {
	refetch: (filters: { filters: FilterInput[] }) => void
	children: ReactNode
}

export const FilterBarGQL = ({ refetch, children }: Props): ReactElement => {
	const { filters, setFilters, initializeSelectedRows } = useFilters()

	return (
		<FilterForm
			onSubmit={() => {
				initializeSelectedRows()
				refetch({ filters })
			}}
			onChange={(filter: FilterInput[]) => {
				setFilters(filter)
			}}
			defaultValue={filters}
			value={filters}
		>
			{children}
		</FilterForm>
	)
}

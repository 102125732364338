import Bugsnag from '@bugsnag/js'

export function silentErrorHandler(error) {
	Bugsnag.leaveBreadcrumb('Silent error handler')
	Bugsnag.notify(error)
	console.error(error)
}

export function ErrorHandler(message, error) {
	Bugsnag.notify(`[${message}] : ${error}`)
	console.error(`[${message}] :`, error)
}

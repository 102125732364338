import { Button, Container, Paper, Stack, Typography } from '@mui/material'
import Center from '@shared/components/styles/Center'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { ROUTE_HOME } from '../../router/routes'

export const FeedbackRequestThanks = (): ReactElement => {
	const { t } = useTranslation()
	const history = useHistory()
	const { feedbackCount } = useParams<{ feedbackCount: string | undefined }>()
	const count = parseInt(feedbackCount ?? '0')

	return (
		<Container maxWidth="xl">
			<Center sx={{ justifyContent: 'center', alignItems: 'center' }}>
				<Paper>
					<Stack direction="column" sx={{ m: 6 }}>
						<img src="/assets/img/feedback-request-thanks.svg" style={{ maxHeight: 300 }} />
						<Stack marginY={4} spacing={2}>
							<Typography color="secondary.main" fontWeight="bold" fontSize={35} align="center">
								{t('feedbackRequest:thanks.title', { count })}
							</Typography>
							<Typography align="center">{t('feedbackRequest:thanks.subtitle')}</Typography>
							<Typography align="center">{t('feedbackRequest:thanks.consult')}</Typography>
						</Stack>
						<Stack direction="row" justifyContent="center" spacing={3}>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									history.push(ROUTE_HOME)
								}}
							>
								{t('feedbackRequest:thanks.home')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => {
									history.goBack()
								}}
							>
								{t('feedbackRequest:thanks.redo')}
							</Button>
						</Stack>
					</Stack>
				</Paper>
			</Center>
		</Container>
	)
}

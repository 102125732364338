interface UsePasswordValidation {
	hasSpecialChar: boolean
	hasLowercase: boolean
	hasUppercase: boolean
	hasNumber: boolean
	hastheMinLenght: boolean
	isValidPassword: boolean
}

export const usePasswordValidation = (password: string): UsePasswordValidation => {
	const hasSpecialChar = /[#?!@$%^&*-]/.test(password)
	const hasLowercase = /[a-z]/.test(password)
	const hasUppercase = /[A-Z]/.test(password)
	const hasNumber = /[0-9]/.test(password)
	const hastheMinLenght = password.length >= 8
	const isValidPassword = hastheMinLenght && hasSpecialChar && hasLowercase && hasUppercase && hasNumber

	return {
		hasSpecialChar,
		hasLowercase,
		hasUppercase,
		hasNumber,
		hastheMinLenght,
		isValidPassword
	}
}

import { MenuItem, Typography, useTheme } from '@mui/material'
import { Emoji } from '@ui/core/Emoji'
import { type ReactElement } from 'react'

interface Props {
	code: string
	name: string
	dialCode: string
	emoji: string
}

export const CountryCodeItem = ({ code, name, dialCode, emoji }: Props): ReactElement => {
	const theme = useTheme()
	return (
		<MenuItem value={code}>
			<Emoji>
				<Typography>
					{emoji} {name}{' '}
					<span
						style={{
							color: theme.palette.grays.gray2
						}}
					>
						{dialCode}
					</span>
				</Typography>
			</Emoji>
		</MenuItem>
	)
}

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { Box } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {
	tooltip: string
	onClick?: () => void
	children: ReactNode
	isLoading?: boolean
	disabled?: boolean
	to?: string
}

export const IconAction = ({
	tooltip,
	onClick,
	children,
	isLoading = false,
	disabled = false,
	to
}: Props): ReactElement => {
	const component = to != null ? Link : undefined

	return (
		<Tooltip title={tooltip} placement="bottom">
			<Box>
				<LocalLoader size={24} load={isLoading}>
					<IconButton {...{ onClick, disabled }} component={component} to={to}>
						{children}
					</IconButton>
				</LocalLoader>
			</Box>
		</Tooltip>
	)
}

import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { IconButton, Stack, TableRow } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	title?: string
	tooltip?: string
	children?: ReactNode
	translationKey?: string
}

export default function TableLayoutRow({ title = '', children, tooltip, translationKey }: Props): JSX.Element {
	const { t } = useTranslation()

	return (
		<TableRow>
			<InfoLayoutLabel verticalAlign="center">
				{translationKey == null ? title : t(`${translationKey}.title`)}
			</InfoLayoutLabel>
			<InfoLayoutValue>
				<Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
					{children}
					{tooltip != null || translationKey != null ? (
						<Tooltip
							sx={{
								marginLeft: '2px',
								marginTop: '-4px'
							}}
							title={translationKey == null ? tooltip : t(`${translationKey}.tooltip`)}
						>
							<IconButton>
								<InfoOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
				</Stack>
			</InfoLayoutValue>
		</TableRow>
	)
}

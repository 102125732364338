import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Severities } from '@application/snackbar/Severities'

interface Return {
	onError: () => void
	onCompleted: () => void
}

export const useGqlSnackBar = (translationKey: string): Return => {
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation()

	const onError = (): void => {
		enqueueSnackbar(t(`${translationKey}.error`), { variant: Severities.error, autoHideDuration: 3000 })
	}
	const onCompleted = (): void => {
		enqueueSnackbar(t(`${translationKey}.success`), { variant: Severities.success, autoHideDuration: 3000 })
	}

	return {
		onError,
		onCompleted
	}
}

import { type ReactElement } from 'react'
import { type GraphRadarSize } from '../GraphRadarSize'
import { useGraphRadar } from '../hook/useGraphRadar'

interface Props {
	measureIndex: number
	measuresCount: number
	valueBase10: number
	isShadowMeasure?: boolean
	color: string
	size: GraphRadarSize
}

export const MeasureArea = ({
	measureIndex,
	measuresCount,
	valueBase10,
	isShadowMeasure = false,
	color,
	size
}: Props): ReactElement | null => {
	const { centerX, centerY, shadowColor, frameWidth, frameMargin, centerBlank, polarToCartesian } = useGraphRadar(size)

	if (valueBase10 <= 0) return null
	const radius = ((frameWidth / 2 - frameMargin - centerBlank) * valueBase10) / 10 + centerBlank
	const startAngle = (360 / measuresCount) * measureIndex
	const endAngle = startAngle + 360 / measuresCount

	// Define arc start & end coordinates
	const startCoordinates = polarToCartesian(radius, startAngle)
	const endCoordinates = polarToCartesian(radius, endAngle)

	// Create SVG Path 'd' value
	const pathDescription = [
		'M',
		startCoordinates.x,
		startCoordinates.y,
		'A',
		radius,
		radius,
		0,
		0,
		1,
		endCoordinates.x,
		endCoordinates.y,
		'L',
		centerX,
		centerY,
		'L',
		startCoordinates.x,
		startCoordinates.y
	].join(' ')
	const pathColor = isShadowMeasure ? shadowColor : color

	if (measuresCount === 1) return <circle fill={pathColor} cx={centerX} cy={centerY} r={radius} />
	return <path fill={pathColor} d={pathDescription} />
}

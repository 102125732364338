export const SET_ISLOADING = 'SET_ISLOADING'
export const INITIALIZE_ISLOADING = 'INITIALIZE_ISLOADING'

export function setIsLoading(isLoading) {
	return {
		type: SET_ISLOADING,
		payload: isLoading
	}
}

export function initializeIsLoading() {
	return {
		type: INITIALIZE_ISLOADING
	}
}

import app from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { config } from './config'
import 'firebase/compat/performance'

let functionsWrapper: app.functions.Functions

export const initFirebase = (): void => {
	functionsWrapper = app.initializeApp(config.firebase).functions('europe-west1')
	app.performance()
}

export const firebaseAuth = (): app.auth.Auth => {
	return app.auth()
}

export const isAuthenticated = (): boolean => {
	return firebaseAuth().currentUser?.uid != null
}

export const firestore = (): app.firestore.Firestore => {
	return app.firestore()
}

export const storage = (): app.storage.Storage => {
	return app.storage()
}

export const functions = (): app.functions.Functions => {
	return functionsWrapper
}

export interface FirebaseAuthError {
	code: string
	message: string
}

import { type LegacyRef, useState } from 'react'
import { ControlledMultiSelectCard } from './ControlledMultiSelectCard'
import { type SelectableItem } from '../selectableItem'

interface Props {
	title: string
	fieldName: string
	items: SelectableItem[]
	register: () => LegacyRef<HTMLInputElement> | undefined
}

const indexBydId = (array: SelectableItem[]): Record<string, SelectableItem> => {
	return array.reduce((previousValue, currentValue) => {
		return { ...previousValue, [currentValue.id]: currentValue }
	}, {})
}

// Deprecated
// Use component from domain/InterviewTemplate instead as it uses hookForm
export default function MultiSelectCard({ title, fieldName, items, register }: Props): JSX.Element {
	const [itemsState, setItemsState] = useState(indexBydId(items))
	const setIsSelected = (id: string, isSelected: boolean): void => {
		setItemsState({ ...itemsState, [id]: { ...itemsState[id], isSelected, selectedOrder: undefined } })
	}

	return <ControlledMultiSelectCard {...{ title, fieldName, register, itemsState, setIsSelected, setItemsState }} />
}

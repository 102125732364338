import { type FC } from 'react'
import { useGetIntegrationOrgaReturnQuery } from '@gqlTypes'
import { GraphRadarCard } from '@shared/components/GraphRadar/components/cards/GraphRadarCard'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { Grid } from '@mui/material'
import { BrandIndicatorsCard } from './BrandIndicatorsCard'
import { useOrganization } from '@domains/organization/useOrganization'

interface Props {
	filterKey: string
	value: string
	isShowMoreExpandedByDefault?: boolean
}

export const OrgaReturn: FC<Props> = ({ filterKey, value, isShowMoreExpandedByDefault = false }) => {
	const { t } = useTranslation()
	const { hasOrgaSkills, organization } = useOrganization()
	const variables = { filters: [{ key: filterKey, operation: '==', value }] }
	const { data, loading: isLoading } = useGetIntegrationOrgaReturnQuery({ variables, fetchPolicy: 'cache-and-network' })
	const { graphDimensions, indicators } = data?.getOrganizationFeedback ?? {
		average: 0,
		graphDimensions: [],
		indicators: []
	}

	if (organization == null) return null
	if (isLoading) return <LocalLoader />

	return (
		<Grid container spacing={2} mb={1}>
			<Grid item xs={12} md={5}>
				{!isLoading && graphDimensions != null && <GraphRadarCard dimensions={graphDimensions} />}
			</Grid>
			<Grid item xs={12} md={7}>
				<BrandIndicatorsCard
					{...{ isShowMoreExpandedByDefault }}
					isLoading={isLoading}
					indicators={indicators}
					isUnavailable={!hasOrgaSkills}
					title={t('translation:candidates.organizationReturn.organizationSKill.title')}
				/>
			</Grid>
		</Grid>
	)
}

import { type ReactElement, type ReactNode } from 'react'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

interface Props {
	title: string
	tooltip?: string
	children: ReactNode
}

export const ColumnLayout = ({ title, tooltip, children }: Props): ReactElement => {
	return (
		<Box sx={{ padding: 2 }}>
			<Stack sx={{ flexDirection: 'row', alignItems: 'center', mb: 2 }}>
				<Typography color="primary">
					<b>{title}</b>
				</Typography>

				{tooltip != null ? (
					<Tooltip title={tooltip} placement="right">
						<IconButton>
							<InfoOutlinedIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</Stack>

			{children}
		</Box>
	)
}

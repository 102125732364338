import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { LocalDate } from '@ui/core/LocalDate'
import clsx from 'clsx'
import { type ReactElement } from 'react'

const useStyle = makeStyles()((theme) => ({
	root: {
		textAlign: 'center',
		color: theme.palette.grays.gray2
	}
}))

interface Props {
	date: Date
	className: string
}

export const ConversationTimestamp = ({ date, className }: Props): ReactElement => {
	const { classes } = useStyle()

	return (
		<Typography component="div" variant="body2" className={clsx(className, classes.root)}>
			<LocalDate date={date} dateStyle="full" />
			{' • '}
			<LocalDate date={date} dateStyle="none" timeStyle="short" />
		</Typography>
	)
}

import { GraphCircle } from './GraphCircle'
import { useGraphRadar } from '../hook/useGraphRadar'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	dimensionCount: number
	size: GraphRadarSize
}

export const GraphGrid = ({ dimensionCount, size }: Props): ReactElement => {
	const { centerX, centerY, frameWidth, frameMargin, centerBlank, blankColor, arcWith, polarToCartesian } =
		useGraphRadar(size)

	// Create circles for each score
	const scoresSeparators = []
	for (let score = 1; score < 10; score++) {
		const scoreRadius = ((frameWidth / 2 - frameMargin - centerBlank) * score) / 10 + centerBlank
		scoresSeparators.push(
			<GraphCircle key={`score${score}`} radius={scoreRadius} strokeColor={blankColor} size={size} />
		)
	}
	// Create line to split measures area
	const measuresSeparators = []
	for (let measureIndex = 0; measureIndex < dimensionCount; measureIndex++) {
		const angle = (360 / dimensionCount) * measureIndex
		const endPoint = polarToCartesian(frameWidth / 2 + 1, angle)
		measuresSeparators.push(
			<line
				key={`Line${measureIndex}`}
				x1={centerX}
				y1={centerY}
				x2={endPoint.x}
				y2={endPoint.y}
				stroke={blankColor}
				strokeWidth={arcWith}
			/>
		)
	}
	return (
		// Create central circle
		<>{scoresSeparators.concat(measuresSeparators)}</>
	)
}

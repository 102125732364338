import { Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	defaultValue: string[]
}

export const SkillSelectorTitle = ({ defaultValue }: Props): ReactElement => {
	const { t } = useTranslation()

	const skills: string[] | undefined = useWatch({ name: 'softskillIds', defaultValue })

	return (
		<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>
			{t('feedbackRequest:form.feedback.softskill.title', { count: skills?.length })}
		</Typography>
	)
}

import StarRatingInput from '@ui/core/StarRatingInput'
import { type FilterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	operation: FilterOperation
	label: string
	unratedLabel: string
}

export const StarRating = ({ keyName, operation, label, unratedLabel }: Props): ReactElement => {
	return (
		<InputFilter keyName={keyName} operation={operation}>
			<StarRatingInput
				id="min-alignment-filter"
				label={label}
				unratedLabel={unratedLabel}
				forceInteger
				orientation="horizontal"
			/>
		</InputFilter>
	)
}

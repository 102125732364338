import { Box, Dialog } from '@mui/material'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { useState, type ReactElement } from 'react'
import { RateFeedback } from './RateFeedback'
import { RequestDemo } from './RequestDemo'
import { FeedbackComment } from './FeedbackComment'

interface Props {
	isModalOpen: boolean
	handleClose: () => void
}

export enum FeedbackModalRoute {
	RateFeedback = 'RateFeedback',
	RequestDemo = 'RequestDemo',
	FeedbackComment = 'FeedbackComment'
}

export const FeedbackModal = ({ isModalOpen, handleClose }: Props): ReactElement => {
	const [route, setRoute] = useState(FeedbackModalRoute.RateFeedback)
	const routes = {
		RateFeedback: <RateFeedback setRoute={setRoute} />,
		RequestDemo: <RequestDemo handleClose={handleClose} />,
		FeedbackComment: <FeedbackComment handleClose={handleClose} />
	}
	return (
		<Dialog onClose={handleClose} open={isModalOpen}>
			<DialogCloseButton onClick={handleClose} />
			<Box
				sx={{ width: 450, display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 1, paddingX: 5 }}
			>
				{routes[route]}
			</Box>
		</Dialog>
	)
}

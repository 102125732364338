import { Button, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	onDelete: (fileName: string) => void
	setFileName: (fileName: string | null) => void
	fileName: string | null
}

export const InterviewAttachmentDeleteDialog = ({ onDelete, setFileName, fileName }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const isDialogOpen = fileName !== null

	const onClose = (): void => {
		setFileName(null)
	}

	const onConfirm = (): void => {
		if (fileName === null) throw new Error('fileName should not be null')
		onDelete(fileName)
		onClose()
	}

	return (
		<Dialog fullWidth maxWidth="xs" open={isDialogOpen} onClose={onClose}>
			<DialogTitle>
				<Typography variant="h6">{t('translation:attachment.deleteConfirmation.title', { fileName })}</Typography>
			</DialogTitle>
			<DialogActions>
				<Button onClick={onClose}>{t('translation:attachment.deleteConfirmation.cancelLabel')}</Button>
				<Button onClick={onConfirm}>{t('translation:attachment.deleteConfirmation.confirmLabel')}</Button>
			</DialogActions>
		</Dialog>
	)
}

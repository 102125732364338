import { type ReactElement } from 'react'
import { FormControl } from '@mui/material'
import { InputFilter } from '@ui/filter/InputFilter'
import { type FilterOperation } from '@ui/filter/FilterForm'
import { RadioGroupFilter } from './RadioGroupFilter'

interface RadioItem {
	label: string
	value: string
}

interface Props {
	keyName: string
	operation: FilterOperation
	radios: RadioItem[]
}

export const RadioFilter = ({ keyName, operation, radios }: Props): ReactElement => {
	return (
		<FormControl component="fieldset" variant="standard">
			<InputFilter keyName={keyName} operation={operation}>
				<RadioGroupFilter radios={radios} />
			</InputFilter>
		</FormControl>
	)
}

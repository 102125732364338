import { type FC } from 'react'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { CommentsFilters } from './CommentsFilters'
import { Comment } from '@ui/core/Comment'
import { useCommentFilters } from '../hooks/useCommentFilters'
import { Box, Button, CardContent, Stack, Typography } from '@mui/material'
import { type OrganizationFeedbackComment } from '#types/OrganizationFeedbackComment'
import { CommentFilterId } from '#types/CommentFilterId'
import { type CommentBySkill } from '@gqlTypes'
import { GroupedComments } from './GroupedComments'

interface Props {
	comments: OrganizationFeedbackComment[]
	commentsBySkills: CommentBySkill[]
}

export const CommentsCard: FC<Props> = ({ comments, commentsBySkills }) => {
	const { t } = useTranslation()
	const { hasDisplayMore, displayMore, filter, setFilter, filteredComments } = useCommentFilters(comments)

	const content =
		filteredComments.length > 0 ? (
			<>
				<Stack sx={{ mb: 2, alignItems: 'center' }}>
					<CommentsFilters setFilter={setFilter} currentfilter={filter} />
				</Stack>
				{filter !== CommentFilterId.all ? (
					<>
						{filteredComments.map(({ senderDisplayName, message, creationDate, skillLabel, key, businessUnit }) => (
							<Comment
								key={key}
								title={skillLabel}
								senderDisplayName={senderDisplayName}
								message={message}
								creationDate={creationDate}
								businessUnit={businessUnit}
							/>
						))}
					</>
				) : (
					<GroupedComments commentsBySkills={commentsBySkills} />
				)}

				{hasDisplayMore ? (
					<Button fullWidth onClick={displayMore}>
						{t('organizationFeedback:comments.more')}
					</Button>
				) : null}
			</>
		) : (
			<Typography textAlign="center">{t('organizationFeedback:comments.empty')}</Typography>
		)

	return (
		<Box sx={{ pageBreakBefore: 'always' }}>
			<CardLayout title={t('organizationFeedback:comments.title')}>
				<CardContent>{content}</CardContent>
			</CardLayout>
		</Box>
	)
}

type UseUrlValidation = [isUrl: (link: string) => boolean, isYoutubeUrl: (link: string) => boolean]

export const useUrlValidation = (): UseUrlValidation => {
	const isUrl = (link: string): boolean => {
		try {
			return new URL(link) instanceof URL
		} catch (_) {
			return false
		}
	}

	const isYoutubeUrl = (link: string): boolean => {
		if (!isUrl(link)) return false
		const url = new URL(link)
		return (
			(url.hostname.endsWith('youtube.com') && url.searchParams.has('v')) ||
			(url.hostname.endsWith('youtube.com') && url.pathname.startsWith('/embed/')) ||
			url.hostname === 'youtu.be'
		)
	}

	return [isUrl, isYoutubeUrl]
}

import { Typography } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	text: string
}

export const Title = ({ text }: Props): ReactElement => {
	return (
		<Typography color="primary" variant="h5">
			<b>{text}</b>
		</Typography>
	)
}

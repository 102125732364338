/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const SET_DATALIST = 'SET_DATALIST'

export function setDataList(dataList: any): any {
	return {
		type: SET_DATALIST,
		payload: dataList
	}
}

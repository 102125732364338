import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const EmptyJobSearchList = (): ReactElement => {
	const { t } = useTranslation()
	return (
		<Stack gap={3} alignItems="center" mt={5}>
			<Typography>{t('offers:resumeLibrary.empty')}</Typography>
			<img src="/assets/img/offers.svg" />
		</Stack>
	)
}

import { GraphCircle } from './GraphCircle'
import { useGraphRadar } from '../hook/useGraphRadar'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	measureIndex: number
	measuresCount: number
	valueBase10: number
	color: string
	size: GraphRadarSize
}

export const SelfAssessArea = ({ measureIndex, measuresCount, valueBase10, color, size }: Props): ReactElement => {
	const { tineStrokeWidth, frameWidth, frameMargin, centerBlank, blankColor, polarToCartesian } = useGraphRadar(size)

	const radius = (frameWidth / 2 - frameMargin - centerBlank) / 10 / 2 - tineStrokeWidth / 2
	const radiusPosition = ((frameWidth / 2 - frameMargin - centerBlank) * (valueBase10 - 0.5)) / 10 + centerBlank
	const angle = (360 / measuresCount) * (measureIndex + 0.5)

	// Define self assesment point coordinates
	const centerCoordinates = polarToCartesian(radiusPosition, angle)

	return (
		<GraphCircle
			cx={centerCoordinates.x}
			cy={centerCoordinates.y}
			radius={radius}
			fillColor={color}
			strokeColor={blankColor}
			isTinyStroke
			size={size}
		/>
	)
}

import { type ReactElement } from 'react'
import { generateRoute, ROUTE_HOME } from '../../../router/routes'
import { history } from '../../store/MyStoreProvider'
import { type FallbackProps } from 'react-error-boundary'

export const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps): ReactElement => {
	return (
		<div>
			<div>{error.toString()}</div>
			<div
				onClick={() => {
					history.push(generateRoute(ROUTE_HOME))
					resetErrorBoundary()
				}}
			>
				close
			</div>
		</div>
	)
}

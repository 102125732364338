import { JobMatchingStatusId } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { JobMatchingBrowserActionButton } from './JobMatchingBrowserActionButton'

interface Props {
	jobMatchingStatusId: JobMatchingStatusId
	onClick: () => void
}

export const DislikeButton = ({ jobMatchingStatusId, onClick }: Props): ReactElement | null => {
	const { t } = useTranslation()

	const authorizedStatuses = [
		JobMatchingStatusId.Proposed,
		JobMatchingStatusId.LikedByCandidate,
		JobMatchingStatusId.LikedByOrganization,
		JobMatchingStatusId.Matched,
		JobMatchingStatusId.Applied
	]

	if (!authorizedStatuses.includes(jobMatchingStatusId)) return null

	return (
		<JobMatchingBrowserActionButton
			title={t('offers:jobMatching.browser.nextAction.dislikedByOrganization')}
			color="error"
			onClick={onClick}
		>
			<CloseIcon />
		</JobMatchingBrowserActionButton>
	)
}

import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { TextField } from '@ui/core/TextField'
import moment from 'moment'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: Date
}

export default function CreationDateField({ defaultValue }: Props): JSX.Element {
	const { t } = useTranslation()

	return (
		<TableLayoutRow
			title={t('tracks:details.formFields.creationDate.title')}
			tooltip={t('tracks:details.formFields.creationDate.tooltip')}
		>
			<TextField
				variant="outlined"
				size="small"
				value={moment(defaultValue).format('DD/MM/YYYY') ?? ''}
				fullWidth
				disabled
			/>
		</TableLayoutRow>
	)
}

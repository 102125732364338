import { useTranslation } from 'react-i18next'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'

export const InterviewTypeStateFilter: React.FC = () => {
	const { t } = useTranslation()

	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return null
	const interviewTypesData = data.me.organization.interviewTypes
	const interviewTypes = interviewTypesData.map(({ id, label }) => {
		return { value: id, label }
	})

	if (interviewTypesData.length === 0) return null

	return (
		<InputFilter keyName="interviewTypeState" operation={filterOperation.EQUAL} isDynamicKey>
			<AutoCompleteFilter
				options={interviewTypes}
				defaultValue=""
				label={t('lists:filter.interviewTypeState')}
				noneValueLabel={t('lists:filter.noFilter')}
				isOptionsSorted={false}
				width="100%"
			/>
		</InputFilter>
	)
}

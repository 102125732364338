import { type ReactElement } from 'react'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { type MotivationQuestionFragment } from '@gqlTypes'
import { MotivationVideoAnswer } from './MotivationVideoAnswer'

interface Props {
	questions: MotivationQuestionFragment[]
	direction?: 'column' | 'row'
	isDisplayed?: boolean
}

export const MotivationVideoCard = ({
	questions,
	direction = 'column',
	isDisplayed = true
}: Props): ReactElement | null => {
	const { t } = useTranslation(['translation'])
	if (questions.length === 0 || !isDisplayed) return null

	return (
		<CardLayout title={t('translation:interviewDetailPage.motivationVideoTitle')}>
			<InfoCardContent>
				{direction === 'row' ? (
					<Grid container>
						{questions.map(({ id, answer, question }) => (
							<Grid item xs={4} key={id}>
								<MotivationVideoAnswer question={question} answer={answer} sx={{ p: 3 }} />
							</Grid>
						))}
					</Grid>
				) : (
					<>
						{questions.map(({ id, answer, question }) => (
							<MotivationVideoAnswer question={question} answer={answer} key={id} />
						))}
					</>
				)}
			</InfoCardContent>
		</CardLayout>
	)
}

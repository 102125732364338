import { useEffect } from 'react'
import { ROUTE_HOME } from '../router/routes'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { resetFilters } from '../shared/store/actions/filters.actions'
import { clearRoleContext } from '../shared/store/actions/role.action'
import { useHistory } from 'react-router'
import { firebaseAuth } from '../firebase/firebase.helper'
import { ErrorHandler } from '#helpers/error.helper'

export const Logout = (): null => {
	const client = useApolloClient()
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		firebaseAuth()
			.signOut()
			.then(async () => {
				dispatch(resetFilters())
				dispatch(clearRoleContext())
				client.stop()
				await client.resetStore()
				history.push(ROUTE_HOME)
			})
			.catch(ErrorHandler)
	}, [dispatch, client])

	return null
}

import { type ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'

interface Props {
	isModified: boolean
}

export const ExitFormWarning = ({ isModified }: Props): ReactElement => {
	const { t } = useTranslation()
	const message = t('exitMessage:content')

	useEffect(() => {
		window.onbeforeunload = (event) => {
			if (!isModified) return
			event.preventDefault()
			event.returnValue = message
		}
	}, [isModified])

	return <Prompt when={isModified} message={message} />
}

import { Stack, type SxProps } from '@mui/material'
import { StepOverview } from './StepOverview'
import { type ReactElement } from 'react'

interface Step {
	isCompleted: boolean
	icon: string
	label: string
}

interface Props {
	steps: Step[]
	sx?: SxProps
}

export const StepsOverview = ({ steps, sx = {} }: Props): ReactElement => {
	return (
		<Stack sx={{ flexDirection: 'row', ...sx }}>
			{steps.map((stepCompleted, index) => {
				const nextStepCompleted = steps[index + 1] != null ? steps[index + 1].isCompleted : undefined
				return (
					<StepOverview
						key={index}
						stepCompleted={stepCompleted.isCompleted ?? false}
						nextStepCompleted={nextStepCompleted}
						icon={stepCompleted.icon}
						label={stepCompleted.label}
					/>
				)
			})}
		</Stack>
	)
}

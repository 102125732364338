import { type StepInterview, type StepsState } from '@gqlTypes'

interface useStepsProps {
	steps: StepInterview[]
	stepsState: StepsState
}

interface Steps {
	isCompleted: boolean
	icon: string
	label: string
}

export const useSteps = ({ steps = [], stepsState = {} }: useStepsProps): Steps[] => {
	return [...steps]
		.sort((a, b) => a.indexOrder - b.indexOrder)
		.map(({ id, icon, organizationLabel: label }) => {
			const isCompleted = stepsState[id as keyof StepsState] as boolean
			return { isCompleted, icon, label }
		})
}

import { useRequestDemoInterviewMutation } from '@gqlTypes'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface Props {
	handleClose: () => void
}

export const RequestDemo = ({ handleClose }: Props): ReactElement => {
	const { interviewId } = useParams<{ interviewId: string }>()
	const [sendMail] = useRequestDemoInterviewMutation({ variables: { interviewId } })
	const { t } = useTranslation('feedbackRequest')

	return (
		<>
			<DialogTitle color="secondary">{t('interview.mailRequest.title')}</DialogTitle>
			<>
				<img
					src="/assets/img/ThanksFeedback.png"
					style={{ objectFit: 'contain', height: 233, width: 306, paddingTop: 7 }}
				/>
				<DialogContent>
					<Typography color="primary" sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
						{t('interview.mailRequest.subtitle')}
					</Typography>
					<Typography color="primary" sx={{ textAlign: 'center' }}>
						{t('interview.mailRequest.dialog')}
					</Typography>
				</DialogContent>
				<DialogActions sx={{ gap: 3 }}>
					<Button color="primary" variant="contained" onClick={handleClose}>
						{t('interview.mailRequest.close')}
					</Button>
					<LoadingButton
						color="secondary"
						variant="contained"
						onClick={async () => {
							await sendMail()
							handleClose()
						}}
						href="https://web.emage-me.com/meetings/christophe-roure"
						target="_blank"
					>
						{t('interview.mailRequest.send')}
					</LoadingButton>
				</DialogActions>
			</>
		</>
	)
}

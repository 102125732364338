import { ExtractedTextType } from '../constants/ExtractedTextType'

export function reduceString(str: string, max: number, replacer = '...'): string {
	if (str.length <= max) return str
	return `${str.substring(0, max)}${replacer}`
}

export function kebabCaseToCamelCase(str: string): string {
	return str
		.split('-')
		.map((w, i) => (i > 0 ? w[0].toUpperCase() + w.slice(1) : w))
		.join('')
}

export function buildMdiLink(str: string): string {
	let formated = kebabCaseToCamelCase(str)
	formated = formated[0].toUpperCase() + formated.slice(1)
	return 'mdi' + formated
}

export interface ExtractedText {
	value: string
	type: ExtractedTextType
}
export const extractUrl = (str: string): ExtractedText[] => {
	const urlMatch = str.match(/https?:\/\/[\w\-+&@#/%?=~_|!:,.;]+[\w/]/g)
	if (urlMatch == null) return [{ value: str, type: ExtractedTextType.text }]
	const url = urlMatch[0]
	const [startText, endText] = str.split(url)
	return [
		{ value: startText, type: ExtractedTextType.text },
		{ value: url, type: ExtractedTextType.url },
		{ value: endText, type: ExtractedTextType.text }
	]
}

export const joinWithPoint = (strings: string[]): string => strings.filter((str) => str !== '').join(' • ')
export const unescapeNewLine = (text: string): string => text.replace(/\\n/g, '\n')

import MaterialTextField, { type BaseTextFieldProps } from '@mui/material/TextField'
import { type ChangeEvent, type ReactElement, useEffect, useState } from 'react'

export interface OldTextFieldProps extends BaseTextFieldProps {
	merged?: 'left' | 'right' | 'both'
	inputProps?: Record<string, unknown>
	isBlurChange?: boolean
	invisible?: boolean
	onChange?: (e: ChangeEvent<HTMLInputElement> | string) => void
	value?: string
}

export const TextField = ({
	invisible = false,
	merged,
	variant,
	onChange,
	value,
	isBlurChange = false,
	inputProps,
	...materialProps
}: OldTextFieldProps): ReactElement => {
	const [localValue, setLocalValue] = useState(value)

	const onBlur = (): void => {
		if (onChange != null) onChange(localValue ?? '')
	}

	const onChangeBlur = (e: ChangeEvent<HTMLInputElement>): void => {
		setLocalValue(e.target.value)
	}

	useEffect(() => {
		setLocalValue(value)
	}, [value])

	let sx = {}
	if (merged === 'right' || merged === 'both') {
		sx = {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		}
	}
	if (merged === 'left' || merged === 'both') {
		sx = {
			...sx,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		}
	}
	if (invisible) {
		sx = { ...sx, borderWidth: 0 }
	}

	return (
		<MaterialTextField
			{...materialProps}
			value={isBlurChange ? localValue : value}
			onChange={isBlurChange ? onChangeBlur : onChange}
			onBlur={isBlurChange ? onBlur : undefined}
			sx={{ '& fieldset': sx }}
		/>
	)
}

import { type ReactElement } from 'react'
import { MyInterviewsComponent } from './MyInterviews.component'
import { Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const MyInterviews = (): ReactElement | null => {
	const { t } = useTranslation()
	return (
		<Container maxWidth="xl">
			<Stack mt={3} direction="column">
				<Typography color="primary" variant="h5">
					<b>{t('interview:myInterviews.title')}</b>
				</Typography>
				<MyInterviewsComponent />
			</Stack>
		</Container>
	)
}

import { indexById } from '#helpers/array.helper'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useEffect, type ReactElement } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	tracks: { id: string; trackSkills: { id: string; label: string }[] }[]
	defaultTrackId: string
	defaultSofskillIds: string[]
}

export const SkillIdsInput = ({ tracks, defaultTrackId, defaultSofskillIds }: Props): ReactElement | null => {
	const { t } = useTranslation()

	const trackId: string = useWatch({ name: 'trackId', defaultValue: defaultTrackId })
	const { setValue, control } = useFormContext()
	const indexedTracks = indexById(tracks)
	const currentTrack = indexedTracks[trackId]
	const name = 'softskillIds'

	useEffect(() => {
		if (currentTrack == null) return
		if (trackId === defaultTrackId) {
			setValue(name, defaultSofskillIds)
			return
		}
		setValue(
			name,
			currentTrack.trackSkills.map(({ id }) => id)
		)
	}, [trackId, currentTrack])

	if (trackId === '' || currentTrack == null) return null

	return (
		<TableLayoutRow
			title={t('organizationUsers:details.cards.tracks.skills.title')}
			tooltip={t('organizationUsers:details.cards.tracks.skills.tooltip')}
		>
			<Controller
				control={control}
				name={name}
				defaultValue={defaultSofskillIds}
				render={({ onChange, value }: { onChange: (newValue: string[]) => void; value: string[] }) => (
					<Stack>
						{currentTrack.trackSkills.map(({ id, label }) => {
							const isChecked: boolean = value.includes(id)

							return (
								<FormControlLabel
									key={id}
									label={label}
									checked={isChecked}
									control={
										<Checkbox
											onChange={() => {
												onChange(isChecked ? value.filter((selectedId) => selectedId !== id) : [...value, id])
											}}
										/>
									}
								></FormControlLabel>
							)
						})}
					</Stack>
				)}
			></Controller>
		</TableLayoutRow>
	)
}

import { AutocompleteSelect, type AutocompleteSelectProps } from '@domains/form/AutocompleteSelect'
import { useGetObjectiveStatusesQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const ObjectiveStatusSelect = (props: Partial<AutocompleteSelectProps>): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading } = useGetObjectiveStatusesQuery()
	if (loading) return <LocalLoader />
	if (data == null) return null

	const items = (data?.getObjectiveStatuses ?? []).map(({ id, label }) => ({ value: id, label }))

	return (
		<AutocompleteSelect
			name="statusId"
			items={items}
			label={t('interview:objectives.modal.status')}
			orderItems={false}
			fullWidth
			disableClearable
			{...props}
		/>
	)
}

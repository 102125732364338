import { type CircularProgressProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { CircularProgressWithContent } from './CircularProgressWithContent'

interface Props extends CircularProgressProps {
	value?: number
}

export const CircularProgressWithLabel = ({ value = 0, ...props }: Props): ReactElement => {
	return (
		<CircularProgressWithContent value={value} {...props}>
			<Typography variant="caption" component="div" color="text.secondary">
				{`${Math.round(value)}%`}
			</Typography>
		</CircularProgressWithContent>
	)
}

class Dom {
	downloadFile(path: string): void {
		if (path === '') return

		const a = document.createElement('a')
		a.href = path
		a.target = '_Blank'
		a.click()
	}
}

export const dom = new Dom()

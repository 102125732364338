import { useState } from 'react'

export const useToggle = (isDefaultToggle = false): [isToggle: boolean, toggle: () => void] => {
	const [isToggle, setIsToggle] = useState(isDefaultToggle)

	const toggle = (): void => {
		setIsToggle(!isToggle)
	}

	return [isToggle, toggle]
}

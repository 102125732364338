import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import Bar from '@shared/components/Bar'
import { Typography } from '@mui/material'

export default function DetailBar({ isSaving }: { formIsValid: boolean; isSaving: boolean }): JSX.Element {
	const { t } = useTranslation()

	return (
		<Bar>
			<Typography sx={{ flexGrow: 1, marginLeft: 7 }} variant="h6">
				<>{t('tracks:list.bar.title')}</>
			</Typography>
			<Button variant="contained" color="primary" type="submit">
				<LocalLoader load={isSaving}>{t('tracks:details.bar.button.save')}</LocalLoader>
			</Button>
		</Bar>
	)
}

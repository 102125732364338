import { type GetIntegrationsProgressionsQuery } from '@gqlTypes'
import { Card, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { type ReactElement } from 'react'

type filledScores =
	GetIntegrationsProgressionsQuery['getIntegrationsProgressions']['progressions'][0]['filledScores'][0]
interface filledScoresWithDate extends filledScores {
	date: string
}

interface rechartjsPayload {
	// payload attribute format corresponds to rechartjs documentation
	payload?: filledScoresWithDate
}

interface Props {
	active?: boolean
	payload?: rechartjsPayload[]
}

export const ProgressionTooltip = ({ active = false, payload = [] }: Props): ReactElement | null => {
	if (!active) return null
	const date = payload[0]?.payload?.date
	const formatedDate = date != null ? DateTime.fromISO(payload[0]?.payload?.date ?? '').toFormat('dd-MM-yyyy') : ''
	return (
		<Card sx={{ padding: 1 }}>
			<Typography>
				<b>{formatedDate}</b>
			</Typography>
			<Typography>{payload[0]?.payload?.autoEvaluationLabel ?? ''}</Typography>
			<Typography>{payload[0]?.payload?.evaluationLabel ?? ''}</Typography>
			<Typography>{payload[0]?.payload?.commentLabel ?? ''}</Typography>
		</Card>
	)
}

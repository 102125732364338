import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { IconButton, type SxProps, Tooltip, useMediaQuery } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	title?: string
	direction?: 'column' | 'row'
	className?: string
	noDivider?: boolean
	footer?: React.ReactNode
	children: React.ReactNode
	tooltip?: string
	sx?: SxProps
}

export const CardLayout = ({
	title,
	direction = 'column',
	className,
	noDivider = false,
	footer = null,
	children,
	tooltip,
	sx = {}
}: Props): ReactElement => {
	const { classes } = useStyle({ direction })
	const orientation = direction === 'row' ? 'vertical' : 'horizontal'
	const isRow = direction === 'row'
	const matchesPrint = useMediaQuery('print')

	const header = (
		<>
			<div className={clsx(classes.header, direction === 'row' && classes.rowHeader)}>
				<Typography
					color="primary"
					variant="h4"
					sx={{
						paddingX: 2,
						fontSize: 20,
						fontWeight: 700,
						width: isRow ? 250 : 'auto'
					}}
				>
					{title}
				</Typography>
				{tooltip != null ? (
					<Tooltip title={tooltip}>
						<IconButton>
							<InfoOutlinedIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</div>
			{!noDivider && <Divider orientation={orientation} flexItem={direction === 'row'} />}
			{direction === 'row' && !(noDivider ?? false) && <Divider />}
		</>
	)

	const footerComponent =
		footer !== null ? (
			<>
				<div className={clsx(classes.header, direction === 'row' && classes.rowHeader)}>{footer}</div>
			</>
		) : null

	return (
		<Card elevation={matchesPrint ? 0 : 3} className={clsx(classes.root, className)} sx={sx}>
			{title != null && header}
			{children}
			{footerComponent}
		</Card>
	)
}
interface StyleProps {
	direction: 'column' | 'row'
}

const useStyle = makeStyles<StyleProps>()((theme, { direction }) => ({
	root: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'stretch',
		flexDirection: 'column',
		marginBottom: 0,
		[theme.breakpoints.up('md')]: {
			flexDirection: direction
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 75,
		boxSizing: 'border-box',

		'& .MuiTypography-h4': {
			fontSize: 20,
			fontWeight: 700
		}
	},
	rowHeader: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: 38,
			paddingRight: 38,
			width: 250
		}
	}
}))

export default CardLayout

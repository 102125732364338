import { Card, CardContent, Stack, type SxProps } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	children: ReactNode
	sx?: SxProps
}

export const CardWithLogo = ({ children, sx }: Props): ReactElement => {
	return (
		<Stack spacing={2} mt={2} sx={{ ...sx, position: 'relative' }}>
			<img
				style={{ zIndex: 3, marginBottom: -35, alignSelf: 'center' }}
				width={45}
				height={45}
				src="/assets/img/icon-emage-me.png"
			/>
			<Card elevation={3} sx={{ borderRadius: '25px' }}>
				<CardContent sx={{ mt: 2 }}>{children}</CardContent>
			</Card>
		</Stack>
	)
}

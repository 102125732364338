import { type SelectChangeEvent } from '@mui/material'
import { TextField } from '@ui/core/TextField'
import { type ReactElement, type Dispatch, type SetStateAction } from 'react'
interface Props {
	label?: string
	message: string
	setMessage: Dispatch<SetStateAction<string>>
}

export const FeedbackMessageSection = ({ label, message, setMessage }: Props): ReactElement => {
	return (
		<TextField
			label={label}
			variant="outlined"
			fullWidth
			multiline
			onChange={(event: SelectChangeEvent<string>) => {
				setMessage(event.target.value)
			}}
			rows={8}
			value={message}
		/>
	)
}

import { Paper } from '@mui/material'
import { type ReactNode } from 'react'

export default function Bar({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Paper
			elevation={5}
			sx={{
				display: 'flex',
				alignItems: 'center',
				padding: 2
			}}
		>
			{children}
		</Paper>
	)
}

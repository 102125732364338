import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import InfoValue from './InfoValue'
import { type UserIndicatorsFragment } from '@gqlTypes'

export const InfoFeedbacksKPI = ({ indicators }: UserIndicatorsFragment): ReactElement => {
	const { t } = useTranslation()

	return (
		<>
			<InfoValue
				title={t('translation:candidates.commons.feedbacks.requested')}
				value={indicators.feedbackRequestCount}
			/>
			<InfoValue
				title={t('translation:candidates.commons.feedbacks.received')}
				value={indicators.feedbackReceivedCount}
			/>
			<InfoValue title={t('translation:candidates.commons.feedbacks.shared')} value={indicators.feedbackSentCount} />
		</>
	)
}

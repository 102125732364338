import { Box, Container, Stack, type SxProps } from '@mui/material'
import BackButton from '@shared/components/buttons/BackButton'
import { Title } from '@ui/typography/Title'
import { type ReactNode, type ReactElement } from 'react'

export interface DefaultLayoutProps {
	left?: ReactNode
	middle?: ReactNode
	right?: ReactNode
	children: ReactElement
	title?: string | ReactNode
	topStyle?: SxProps
	containerStyle?: SxProps
	backRoute?: string
	isSticky?: boolean
}

export const DefaultLayout = ({
	left,
	middle,
	right,
	title = '',
	children,
	backRoute,
	topStyle = {},
	containerStyle = {},
	isSticky = false
}: DefaultLayoutProps): ReactElement => {
	let stackStyle: SxProps = topStyle
	const stickyStyle: SxProps = {
		zIndex: 1,
		position: 'sticky',
		top: 0,
		borderBottom: '1px solid #0000001F',
		backgroundColor: '#F3F8FF'
	}
	if (isSticky) stackStyle = { ...stackStyle, ...stickyStyle }
	return (
		<Container sx={{ flexDirection: 'column' }}>
			<Stack
				sx={{
					my: 1,
					height: 72,
					...stackStyle
				}}
				direction="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Stack flex={1} direction="row" gap={3} alignItems="center" justifyContent="flex-start">
					{backRoute != null ? <BackButton route={backRoute} /> : null}
					{typeof title === 'string' ? <Title text={title} /> : title}
					{left}
				</Stack>
				<Stack direction="row" gap={3} alignItems="center" justifyContent="center">
					{middle}
				</Stack>
				<Stack flex={1} direction="row" gap={3} alignItems="center" justifyContent="flex-end">
					{right}
				</Stack>
			</Stack>
			<Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', ...containerStyle }}>{children}</Box>
		</Container>
	)
}

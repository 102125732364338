import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, DialogTitle, Rating, Typography } from '@mui/material'
import { type ReactElement } from 'react'

import { useTranslation } from 'react-i18next'
import { FeedbackModalRoute } from './FeedbackModal'
import React from 'react'

interface Props {
	setRoute: React.Dispatch<React.SetStateAction<FeedbackModalRoute>>
}

export const RateFeedback = ({ setRoute }: Props): ReactElement => {
	const [starValue, setStarValue] = React.useState<number>(3)
	const { t } = useTranslation('feedbackRequest')
	return (
		<>
			<img
				src="/assets/img/selected_congratulation.png"
				style={{ objectFit: 'contain', height: 232, width: 258, marginTop: 10 }}
			/>
			<DialogTitle color="secondary">{t('interview.title')}</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Typography color="primary" sx={{ fontSize: '16px', textAlign: 'center', mb: 2 }}>
					{t('interview.subtitle')}
				</Typography>
				<Typography color="primary" sx={{ fontWeight: 'bold', mb: 3 }}>
					{t('interview.dialog')}
				</Typography>
				<Rating
					name="simple-controlled"
					size="large"
					value={starValue}
					onChange={(event, newValue) => {
						setStarValue(newValue ?? 0)
					}}
				/>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					onClick={() => {
						setRoute(starValue >= 3 ? FeedbackModalRoute.RequestDemo : FeedbackModalRoute.FeedbackComment)
					}}
					color="secondary"
					variant="contained"
				>
					{t('interview.validate')}
				</LoadingButton>
			</DialogActions>
		</>
	)
}

import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSubscribeUserToPositionMutation } from '@gqlTypes'
import { handleAsyncSubmit } from '#helpers/form.helper'
import { useSnackbar } from 'notistack'
import { Severities } from '@application/snackbar/Severities'
import { Autocomplete, TextField } from '@mui/material'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import Button from '@mui/material/Button'

interface Props {
	availablePositions: {
		value: string
		label: string
	}[]
	userId: string
}

export const OrganizationUserDetailsTrackCard = ({ availablePositions, userId }: Props): ReactElement => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [trackSelected, setTrackSelected] = useState<{ value: string; label: string } | null>(null)
	const [subscribePosition] = useSubscribeUserToPositionMutation()

	const onConfirm = async (): Promise<void> => {
		if (trackSelected === null) return

		const { errors } = await subscribePosition({ variables: { trackId: trackSelected.value, userId } })
		errors === undefined
			? enqueueSnackbar(t('organizationUsers:details.cards.tracks.success'), { variant: Severities.success })
			: enqueueSnackbar(t('organizationUsers:details.cards.tracks.error'), { variant: Severities.error })
		setTrackSelected(null)
	}

	return (
		<CardLayout title={t('organizationUsers:details.cards.tracks.title')}>
			<TableLayout style={{ display: 'flex' }}>
				<TableLayoutRow
					title={t('organizationUsers:details.cards.tracks.label')}
					tooltip={t('organizationUsers:details.cards.tracks.tooltip')}
				>
					<Autocomplete
						size="small"
						options={availablePositions}
						sx={{ width: 250, margin: 1 }}
						value={trackSelected}
						renderInput={(params) => <TextField {...params} />}
						onChange={(_event, newValue) => {
							setTrackSelected(newValue)
						}}
					/>
					<Button
						style={{ height: 40 }}
						variant="contained"
						onClick={() => {
							handleAsyncSubmit(onConfirm)
						}}
					>
						{t('organizationUsers:details.cards.tracks.confirm')}
					</Button>
				</TableLayoutRow>
			</TableLayout>
		</CardLayout>
	)
}

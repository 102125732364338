import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	onValidate: () => void
	bodyText: string
	buttonText: string
	disabled: boolean
	isSaving: boolean
}

export const TextSection = ({ onValidate, bodyText, buttonText, disabled, isSaving }: Props): ReactElement => {
	return (
		<>
			<DialogContent>{bodyText}</DialogContent>
			<DialogActions>
				<LoadingButton onClick={onValidate} disabled={disabled} loading={isSaving}>
					{buttonText}
				</LoadingButton>
			</DialogActions>
		</>
	)
}

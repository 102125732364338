const Collections = {
	Contacts: {
		name: 'Usr_Contacts'
	},
	Users: {
		name: 'Usr_User',
		BlockedContacts: {
			name: 'BlockedContacts'
		},
		PersonalityTest: {
			name: 'PersonalityTest',
			Answers: {
				name: 'Answers'
			}
		},
		SoftSkillsTest: {
			name: 'SoftSkillsTest',
			Answers: {
				name: 'Answers'
			}
		},
		SoftSkillsEvaluated: {
			name: 'SoftSkillsEvaluated',
			History: {
				name: 'History'
			}
		},
		SoftSkillsToEvaluate: {
			name: 'SoftSkillsToEvaluate'
		},
		MotivationTest: {
			name: 'MotivationTest'
		},
		Indicators: {
			name: 'Indicators'
		}
	},
	Discussions: {
		name: 'Msg_Discussions',
		Messages: {
			name: 'Messages'
		}
	},
	DiscussionUser: {
		name: 'Msg_DiscussionUser'
	},
	OrganizationOffers: {
		name: 'Mdm_OrgaOffer',
		OfferSoftSkill: {
			name: 'OfferSoftSkill'
		},
		OfferStep: {
			name: 'OfferStep'
		}
	},
	ConfigurationRepositories: {
		name: 'Conf_Repositories'
	},
	UserOffer: {
		name: 'Usr_UserOffer',
		OfferStep: {
			name: 'OfferStep'
		},
		MotivationTest: {
			name: 'MotivationTest'
		}
	},
	Organization: {
		name: 'Mdm_Organization',
		InterviewFields: {
			name: 'InterviewFields'
		}
	},
	ImageReturnRequest: {
		name: 'Rtn_ImageReturnRequest',
		Request: {
			name: 'Request'
		},
		Response: {
			name: 'Response'
		}
	},
	ImageReturnResponse: {
		name: 'Rtn_ImageReturnResponse'
	},
	UserInterview: {
		name: 'Pers_UserInterview'
	},
	UserB: {
		name: 'Usr_UserB'
	},
	UserPosition: {
		name: 'Usr_UserPosition',
		MotivationTest: {
			name: 'MotivationTest'
		}
	},
	OrganizationPosition: {
		name: 'Mdm_OrgaPosition'
	},
	ServeurStatus: {
		name: 'Sys_ServeurStatus'
	}
}

export default Collections

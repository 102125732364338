import { type ReactElement } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

interface RadioItem {
	label: string
	value: string
}

interface Props {
	radios: RadioItem[]
	value?: string
	onChange?: (value: string) => void
}

export const RadioGroupFilter = ({ radios, onChange, value }: Props): ReactElement => {
	return (
		<RadioGroup
			row
			onChange={(e, value) => {
				if (onChange != null) onChange(value)
			}}
			value={value}
		>
			{radios.map((radio) => (
				<FormControlLabel key={radio.value} value={radio.value} label={radio.label} control={<Radio />} />
			))}
		</RadioGroup>
	)
}

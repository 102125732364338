import { useCallback } from 'react'
import { deepCopyFunction } from '../../../helper/massActions.helper'
import { setDataList } from '@shared/store/actions/dataList.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { ErrorHandler } from '#helpers/error.helper'

export default function useDataUpdater(query, cacheKey) {
	const client = useApolloClient()
	const dataList = useSelector(({ dataList }) => dataList)
	const dispatch = useDispatch()

	const updateDataGrid = useCallback(
		(itemId, field, value) => {
			const dataListCopy = deepCopyFunction(dataList)
			const toReplace = dataListCopy.find((element) => element.id === itemId) ?? {}
			const index = dataListCopy.indexOf(toReplace)
			toReplace[field] = value
			dataListCopy[index] = toReplace
			dispatch(setDataList(dataListCopy))
		},
		[dataList, dispatch]
	)

	return (itemId, key, value) => {
		;(async () => {
			const payload = {}
			payload[key] = value

			if (cacheKey) {
				if (key === 'juryId') {
					payload[key] = value.juryId
					client.cache.modify({
						id: `${cacheKey}:${itemId}`,
						fields: {
							juryFirstname() {
								return value.juryFirstname
							},
							juryLastname() {
								return value.juryLastname
							}
						}
					})
				}
				client.cache.modify({
					id: `${cacheKey}:${itemId}`,
					fields: {
						[key]() {
							return value
						}
					}
				})
			}
			await query(itemId, payload)
			updateDataGrid(itemId, key, value)
		})().catch(ErrorHandler)
	}
}

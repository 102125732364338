import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'

interface Props {
	status: string
}

export const OfferStatus = ({ status }: Props): ReactElement => {
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<AssignmentIndIcon sx={{ width: 18 }} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>{status}</Typography>
		</Stack>
	)
}

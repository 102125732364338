import { type ReactElement, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import { RiskStatusIds, type UserPositionListNodeFragment } from '@gqlTypes'
import { RiskLabel } from './RiskLabel'
import { DialogContent, Divider, Stack } from '@mui/material'
import { IntegrationAvatar } from '@shared/components/IntegrationAvatar'
import { RiskUpdator } from './RiskUpdator'
import { ModalCloseButton } from '@shared/components/modal/ModalCloseButton'
import { RiskHistory } from './history/RiskHistory'
import { IconAction } from '@shared/icon/IconAction'

interface Props {
	isHovered?: boolean
	position: UserPositionListNodeFragment
	title: string
}

export const RiskModal = ({ isHovered = false, position, title }: Props): ReactElement | null => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()

	const isRisky = [RiskStatusIds.Low, RiskStatusIds.High].includes(position.risk.id)
	const color = !isHovered && !isRisky ? 'transparent' : undefined

	return (
		<>
			<IconAction
				tooltip={t('risk:modal.title.tooltip')}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<RiskLabel risk={position.risk} displayLabel={false} color={color} />
			</IconAction>
			<Dialog
				open={isOpen}
				onClose={() => {
					setIsOpen(false)
				}}
			>
				<ModalCloseButton
					onClick={() => {
						setIsOpen(false)
					}}
				/>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<IntegrationAvatar
							sx={{ mr: 2 }}
							userRef={position.userInfo}
							title={position.orgaPosition?.label ?? ''}
							subtitle={position?.userInfo?.currentCompany ?? ''}
						/>
						<RiskLabel risk={position.risk} />
					</Stack>
				</DialogContent>
				<Divider />
				<DialogContent>
					<RiskUpdator positionRiskId={position.risk.id} positionId={position.id} />
				</DialogContent>
				<RiskHistory positionId={position.id} />
			</Dialog>
		</>
	)
}

import PropTypes from 'prop-types'
import MaterialLinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

const useStyle = makeStyles()((theme) => ({
	root: {
		width: '50%',
		border: 'solid 1px #BDBDBD',
		padding: 2,
		borderRadius: 8
	},

	progress: {
		borderRadius: 6,
		height: 6,

		'&.MuiLinearProgress-root': {
			background: 'none'
		},

		'& .MuiLinearProgress-bar': {
			borderRadius: 6
		}
	},

	labelContainer: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'center',

		'& $root': {
			flex: 1
		}
	},

	label: {
		minWidth: 38,
		textAlign: 'right',

		order: ({ labelPosition }) => (labelPosition === 'left' ? -1 : 1),
		marginLeft: ({ labelPosition }) => (labelPosition === 'left' ? 0 : 10),
		marginRight: ({ labelPosition }) => (labelPosition === 'left' ? 10 : 0)
	},
	primaryColor: {},
	secondaryColor: {},
	progressColor: {
		'& .MuiLinearProgress-bar': {
			background: theme.palette.secondary.main
		}
	},
	compact: {
		display: 'inline-block',
		minWidth: 60
	},
	compactLabelContainer: {
		display: 'inline-flex'
	}
}))

export function LinearProgress(props) {
	const { classes } = useStyle(props)

	const { withLabel, value, color, compact } = props

	const linearProgressProps = { ...props }
	delete linearProgressProps.withLabel
	delete linearProgressProps.labelPosition
	delete linearProgressProps.compact

	if (color === 'progress') linearProgressProps.color = 'primary'

	const rootProgressBar = (
		<div className={clsx(classes.root, classes[color + 'Color'], compact ? classes.compact : null)}>
			<MaterialLinearProgress {...linearProgressProps} className={classes.progress} />
		</div>
	)

	if (withLabel) {
		return (
			<div className={clsx(classes.labelContainer, compact ? classes.compactLabelContainer : null)}>
				{rootProgressBar}
				{typeof value === 'number' && (
					<Typography className={classes.label} variant="body1" color="textSecondary">
						{`${Math.round(value)}%`}
					</Typography>
				)}
			</div>
		)
	} else {
		return rootProgressBar
	}
}

LinearProgress.propTypes = {
	...MaterialLinearProgress.propTypes,
	labelPosition: PropTypes.oneOf(['left', 'right']),
	value: PropTypes.number,
	withLabel: PropTypes.bool,
	color: PropTypes.oneOf(['progress', 'primary', 'secondary']),
	compact: PropTypes.bool
}

LinearProgress.defaultProps = {
	...MaterialLinearProgress.defaultProps,
	labelPosition: 'right',
	withLabel: false,
	color: 'progress',
	compact: false
}

export default LinearProgress

import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { useGetAssessorsQuery } from '@gqlTypes'

interface Props {
	onValidate: (key: string, value: string[]) => void
}

export const AssessorsUpdateSection = ({ onValidate }: Props): ReactElement => {
	const [currentAssessor, setCurrentAssessor] = useState<string[]>([])
	const { t } = useTranslation(['userOfferStatus', 'lists'])
	const { data } = useGetAssessorsQuery()
	const assessorsList = data?.getAssessors ?? []

	const handleChange = (event: SelectChangeEvent<string[]>): void => {
		const selected = event.target.value as string[]
		if (selected.includes('')) {
			setCurrentAssessor([])
			return
		}
		setCurrentAssessor(selected)
	}

	return (
		<DialogSection style={{ display: 'flex', flexDirection: 'row' }}>
			<div>{t('modal:massUpdate.assessor')}</div>
			<Select
				multiple
				value={currentAssessor}
				onChange={handleChange}
				input={<Input />}
				className="main-dialog-element"
			>
				<MenuItem value={''}>{t('modal:massUpdate.noAssessor')}</MenuItem>
				{assessorsList.map((assessor) => (
					<MenuItem key={assessor.id} value={assessor.id}>
						{assessor.reverseFullName}
					</MenuItem>
				))}
			</Select>
			<Button
				onClick={() => {
					onValidate('assessorIds', currentAssessor)
				}}
			>
				{t('modal:massUpdate.update')}
			</Button>
		</DialogSection>
	)
}

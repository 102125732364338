import Tooltip from '@mui/material/Tooltip'
import Checkbox from '@mui/material/Checkbox'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	value: boolean
	id: string
	field: string
	onToggle: (id: string, field: string, value: boolean) => void
	icon: ReactNode
	checkIcon: ReactNode
	tooltip: string
}

export const ToggleIcon = ({ value, id, field, onToggle, icon, checkIcon, tooltip }: Props): ReactElement => {
	return (
		<Tooltip title={tooltip}>
			<Checkbox
				icon={icon}
				checkedIcon={checkIcon}
				checked={value}
				onChange={() => {
					onToggle(id, field, !value)
				}}
			/>
		</Tooltip>
	)
}

import { ChipSelector } from '@shared/components/forms/fields/ChipSelector'
import { useGetFeedbackRequestAvailableSkillsQuery } from '@gqlTypes'
import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { SkillSelectorTitle } from './SkillSelectorTitle'

export const SkillSelector = (): ReactElement | null => {
	const { t } = useTranslation()
	const { fullrequest, interviewId } = useParams<{ fullrequest: string | undefined; interviewId: string | undefined }>()
	const isFullRequest = fullrequest != null

	const { data, loading: isLoading } = useGetFeedbackRequestAvailableSkillsQuery({ variables: { interviewId } })

	if (isLoading || data?.getFeedbackRequestAvailableSkills == null) return null
	const items = data?.getFeedbackRequestAvailableSkills ?? []
	const subtitleType = isFullRequest ? 'fullRequest' : 'returnRequest'
	const defaultValue = items.map(({ id }) => id)

	return (
		<Stack spacing={1}>
			<SkillSelectorTitle defaultValue={defaultValue} />
			<Typography>{t(`feedbackRequest:form.feedback.softskill.subtitle.${subtitleType}`)}</Typography>
			<ChipSelector
				name="softskillIds"
				items={items}
				isLoading={isLoading}
				defaultValue={defaultValue}
				isEditable={!isFullRequest}
				minSelectableItem={1}
			/>
		</Stack>
	)
}

import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useInterviewSkills } from '@features/details/hooks/useInterviewSkills'
import { ProgressionChip } from '@ui/progress/ProgressionChip'
import { type GetInterviewDetailsQuery } from '@gqlTypes'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	userIntegration: GetInterviewDetailsQuery['getInterview']['userIntegration']
}

export const AssessorSkillProgressionChip = ({ interview, userIntegration }: Props): ReactElement | null => {
	const { mandatorySkills } = useInterviewSkills({ userIntegration, interview })
	const { watch } = useFormContext()
	const skillScores = watch('skillScores')

	const mandatorySkillIds = Object.entries(mandatorySkills).map(([skillId]) => skillId)
	const filledMandatorySkill = mandatorySkillIds.filter((skillId) => skillScores[skillId] > 0).length

	const globalManualScore = (interview.validatedScore ?? 0) * 4
	const globalCalculatedScore = (interview.calculatedScore ?? 0) * 4
	const filledGlobalScore = globalManualScore + globalCalculatedScore > 0 ? 1 : 0
	const filledGlobalComment = interview.comment !== '' ? 1 : 0

	const progression =
		((filledMandatorySkill + filledGlobalScore + filledGlobalComment) / (mandatorySkillIds.length + 2)) * 100

	return <ProgressionChip progression={progression} />
}

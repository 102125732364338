import Typography from '@mui/material/Typography'
import { Slider } from '@ui/core/Slider'
import { DetailIndicator } from './DetailIndicator'
import { IndicatorComments } from './IndicatorComments'
import { Divider, Stack } from '@mui/material'
import { ShowMore } from '@ui/core/ShowMore'
import theme from '@ui/theme'
import { ScoreEmoji } from './ScoreEmoji'
import { type ReactElement } from 'react'
import { type SerializedSkill } from '@gqlTypes'

interface Props {
	indicators?: SerializedSkill[]
	isShowMoreExpandedByDefault?: boolean
}

export const BrandIndicators = ({
	indicators = [],
	isShowMoreExpandedByDefault = false
}: Props): ReactElement | null => {
	if (indicators.length === 0) return null

	const treshhold = 4
	const color = 'secondary'
	const displayIndicators = indicators.filter((indicator) => indicator.score > 0)
	const secondaryColor = theme.palette.secondary.main
	const warningColor = theme.palette.warning.main
	return (
		<Stack divider={<Divider orientation="horizontal" flexItem sx={{ borderStyle: 'dashed' }} />}>
			{displayIndicators.map((skill) => {
				const details = skill.details ?? []
				const comments = skill.comments ?? []

				const hasComment = comments.length > 0
				const hasDetails = details.length > 0
				const hasContent = hasComment || hasDetails
				let trackColor = skill.score > skill.delimiter ? secondaryColor : warningColor
				if (treshhold > 0) trackColor = skill.score >= treshhold ? secondaryColor : warningColor
				if (skill.score === 0) trackColor = 'grey'

				return (
					<Stack pt={2} key={skill.id} sx={{ px: 2 }}>
						<Typography sx={{ ml: 2, fontWeight: 'bold', color }}>{skill.label}</Typography>
						<Stack direction="column" spacing={2}>
							<Stack direction="row" spacing={1}>
								<Slider
									sx={{ width: '100%' }}
									min={0}
									max={5}
									readOnly
									color={color}
									value={skill.score}
									trackColor={trackColor}
								/>
								<ScoreEmoji score={skill.score} fontWeight="bold" />
							</Stack>

							{hasContent ? (
								<>
									{hasDetails ? <DetailIndicator details={details} /> : null}
									{hasComment ? (
										<ShowMore
											isExpandedByDefault={isShowMoreExpandedByDefault}
											boxStyle={{ display: 'flex', flexDirection: 'column' }}
											textStyle={{
												justifyContent: 'flex-end',
												display: 'flex',
												whiteSpace: 'nowrap',
												fontSize: 13,
												marginTop: 0,
												color: theme.palette.grays.gray2,
												fontWeight: 400
											}}
											expandText="organizationFeedback:showMore.expand"
											collapseText="organizationFeedback:showMore.collapse"
										>
											<IndicatorComments comments={skill.comments} />
										</ShowMore>
									) : null}
								</>
							) : null}
						</Stack>
					</Stack>
				)
			})}
		</Stack>
	)
}

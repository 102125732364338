import { useMeQuery, type UserOrganizationFragment } from '@gqlTypes'

interface Return {
	organization: UserOrganizationFragment | null
	orgaSkillsData: UserOrganizationFragment['orgaSkillsData']
	hasOrgaSkills: boolean
	organizationId: string
	hasPdfExport: boolean
	hasSkillsQuestionsOnInterview: boolean
}

export const useOrganization = (): Return => {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const organization = data?.me?.organization ?? null
	const orgaSkillsData = organization?.orgaSkillsData ?? []
	const hasOrgaSkills = orgaSkillsData.length > 0
	const organizationId = organization?.id ?? ''
	const hasPdfExport = organization?.hasPdfExport ?? false
	const hasSkillsQuestionsOnInterview = organization?.hasSkillsQuestionsOnInterview ?? false
	return { organization, orgaSkillsData, hasOrgaSkills, organizationId, hasPdfExport, hasSkillsQuestionsOnInterview }
}

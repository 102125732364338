import { type GetInterviewDetailsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { InterviewSection } from './InterviewSection'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@mui/material'
import { useInterviewState } from './useInterviewState'
import { OrgaReturn } from '@domains/brandFeedbacks/tab/OrgaReturn'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	isShowMoreExpandedByDefault?: boolean
}

export const InterviewSurvey = ({ interview, isShowMoreExpandedByDefault = false }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { isInterviewAssessee } = useInterviewState(interview)

	if (interview.stepsState.brandFeedbackResponse == null) return null
	const brandFeedbackResponse: boolean = interview.stepsState.brandFeedbackResponse

	return (
		<InterviewSection title={t('interview:survey.section')}>
			{brandFeedbackResponse ? (
				<OrgaReturn {...{ isShowMoreExpandedByDefault }} filterKey="userInterviewId" value={interview.id ?? ''} />
			) : isInterviewAssessee ? (
				<Button href={`${import.meta.env.VITE_WEB_PUBLIC_URL}interview-survey/${interview.id}`} variant="contained">
					{t('interview:survey.respond')}
				</Button>
			) : (
				<Typography>{t('interview:survey.noResponse')}</Typography>
			)}
		</InterviewSection>
	)
}

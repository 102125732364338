import { useState } from 'react'
import { ControlledMultiSelectCard } from '@domains/tracks/details/cards/ControlledMultiSelectCard'
import { type SelectableItem } from '@domains/tracks/details/selectableItem'
import { useFormContext } from 'react-hook-form'

interface Props {
	title: string
	fieldName: string
	items: SelectableItem[]
}

const indexBydId = (array: SelectableItem[]): Record<string, SelectableItem> => {
	return array.reduce((previousValue, currentValue) => {
		return { ...previousValue, [currentValue.id]: currentValue }
	}, {})
}

export default function MultiSelectCard({ title, fieldName, items }: Props): JSX.Element {
	const { register } = useFormContext()

	const [itemsState, setItemsState] = useState(indexBydId(items))
	const setIsSelected = (id: string, isSelected: boolean): void => {
		setItemsState({ ...itemsState, [id]: { ...itemsState[id], isSelected, selectedOrder: undefined } })
	}

	return <ControlledMultiSelectCard {...{ title, fieldName, register, itemsState, setIsSelected, setItemsState }} />
}

import Button, { type ButtonProps } from '@mui/material/Button'
import { history } from '../../store/MyStoreProvider'
import { generateRoute } from '../../../router/routes'
import { type ReactElement } from 'react'

interface Props extends ButtonProps {
	to: string
}

export const ButtonRouter = ({ to, ...props }: Props): ReactElement => {
	return (
		<Button
			variant="contained"
			color="primary"
			{...props}
			onClick={(e) => {
				e.preventDefault()
				history.push(generateRoute(to))
			}}
		/>
	)
}

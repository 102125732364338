import { type ReactElement } from 'react'

interface Props {
	children: string
}

export const ParagraphWithBreakLine = ({ children }: Props): ReactElement | null => {
	if (children === '') return null

	return <p style={{ whiteSpace: 'pre-wrap' }}>{children.replaceAll('\\n', '\n')}</p>
}

import { IconButton } from '@mui/material'
import { useEffect, type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { generateRoute } from '../../../../router/routes'
import { OffersPath } from '../../OffersRouter'
import { type ApolloQueryResult } from '@apollo/client'

interface Props {
	jobSearchIds: string[]
	currentJobSearchId: string
	hasNextPage: boolean
	fetchMore: () => Promise<ApolloQueryResult<unknown>>
}

export const ResumeLibraryBrowserButtons = ({
	jobSearchIds,
	currentJobSearchId,
	hasNextPage,
	fetchMore
}: Props): ReactElement => {
	const currentProfileIndex = jobSearchIds?.findIndex((id) => id === currentJobSearchId) ?? 0
	const previousProfileId = jobSearchIds[currentProfileIndex - 1] ?? ''
	const nextProfileId = jobSearchIds[currentProfileIndex + 1] ?? ''
	const isBeforeLastPage = jobSearchIds.length === currentProfileIndex + 2

	const backRouteProfile: string = generateRoute(OffersPath.resumeLibraryBrowser, { jobSearchId: previousProfileId })
	const nextRouteProfile: string = generateRoute(OffersPath.resumeLibraryBrowser, { jobSearchId: nextProfileId })
	const state = { isFromApp: true }

	useEffect(() => {
		if (isBeforeLastPage && hasNextPage) void fetchMore()
	}, [hasNextPage, isBeforeLastPage])

	return (
		<>
			<IconButton
				sx={{ backgroundColor: 'white' }}
				to={{ pathname: backRouteProfile, state }}
				component={Link}
				disabled={previousProfileId === ''}
			>
				<ChevronLeftIcon />
			</IconButton>
			<IconButton
				sx={{ backgroundColor: 'white' }}
				to={{ pathname: nextRouteProfile, state }}
				component={Link}
				disabled={nextProfileId === '' && !hasNextPage}
			>
				<ChevronRightIcon />
			</IconButton>
		</>
	)
}

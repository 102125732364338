export function buildCSV(rowList, t) {
	const discussionHeaderList = [
		t('translation:candidates.commons.lastname'),
		t('translation:candidates.commons.firstname'),
		t('translation:discussionListPage.lastMessage'),
		t('translation:discussionListPage.interlocutor'),
		t('translation:discussionListPage.unread'),
		t('translation:discussionListPage.flagged'),
		t('translation:discussionListPage.archived')
	]

	const discussionMapping = (rowList) => {
		return rowList.map((row) => {
			return [
				row.recipientRef?.lastName || '',
				row.recipientRef?.firstName || '',
				row.lastMessage || '',
				row.userRef?.displayName || '',
				row.read ? t('translation:csv.no') : t('translation:csv.yes'),
				row.flagged ? t('translation:csv.yes') : t('translation:csv.no'),
				row.archived ? t('translation:csv.yes') : t('translation:csv.no')
			]
		})
	}

	let csvData = []

	csvData.push(discussionHeaderList.join(';'))
	discussionMapping(rowList).map((row) => csvData.push(row.join(';')))

	csvData = csvData.join('\n')

	const a = document.createElement('a')

	const universalBOM = '\uFEFF'
	a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + csvData)
	a.target = '_Blank'
	a.download = 'export.csv'

	a.click()
}

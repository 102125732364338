import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { type ReactElement, type ReactNode, useCallback } from 'react'
import { useTheme } from '@mui/material'

interface Props {
	checkedIcon?: ReactNode
	falseLabel?: string
	icon?: ReactNode
	label?: string
	onChange?: () => void
	readOnly?: boolean
	trueLabel?: string
	noLabel?: boolean
	value?: boolean
}

export const BooleanInput = ({
	trueLabel,
	falseLabel,
	label,
	noLabel = false,
	readOnly,
	onChange,
	icon,
	checkedIcon,
	value = false
}: Props): ReactElement => {
	const { t } = useTranslation(['translation'])
	const theme = useTheme()
	const finalLabel = value
		? (trueLabel ?? label ?? t('translation:booleanIndicator.trueLabel'))
		: (falseLabel ?? label ?? t('translation:booleanIndicator.falseLabel'))

	const handleChange = useCallback(() => {
		if (onChange != null) onChange()
	}, [onChange])
	const color = value ? theme.palette.success.main : theme.palette.grays.gray2
	return (
		<div
			style={{
				display: 'inline-flex',
				justifyContent: 'center',
				alignItems: 'center',
				textTransform: 'none'
			}}
		>
			<Checkbox
				style={{
					marginRight: 9,
					height: '1em',
					fontSize: '1.5em',
					lineHeight: 1,
					color
				}}
				icon={icon ?? <CloseIcon fontSize="inherit" />}
				checkedIcon={checkedIcon ?? <CheckIcon fontSize="inherit" />}
				checked={value}
				disabled={readOnly}
				onChange={handleChange}
			/>
			<Typography component="div" style={{ color }}>
				{!noLabel && finalLabel}
			</Typography>
		</div>
	)
}

import UserOfferService from './UserOfferService'
import UserService from './UserService'
import DiscussionUserService from './DiscussionUserService'
import StorageService from './StorageService'
import DiscussionService from './DiscussionService'

// FireStore
export const userOfferService = new UserOfferService()
export const userService = new UserService()
export const discussionUserService = new DiscussionUserService()
export const discussionService = new DiscussionService()

// Storage
export const storageService = new StorageService()

export const SET_ROLE_CONTEXT = '[EmageMeRoleContext] SET ROLE CONTEXT'
export const CLEAR_ROLE_CONTEXT = '[EmageMeRoleContext] CLEAR ROLE CONTEXT'

export function setRoleContext(roleData) {
	return {
		type: SET_ROLE_CONTEXT,
		payload: roleData
	}
}

export function clearRoleContext() {
	return {
		type: CLEAR_ROLE_CONTEXT
	}
}

import { useTranslation } from 'react-i18next'
import { useMeQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { AutocompleteSelect } from '@domains/form/AutocompleteSelect'

export const InterviewTypeField = (): ReactElement | null => {
	const { t } = useTranslation()

	const { loading, data } = useMeQuery()
	const interviewTypes = data?.me?.organization.interviewTypes ?? []
	const rules = { required: t('drawer:interview.required') }

	const items = interviewTypes.map((interviewType) => ({
		value: interviewType.id,
		label: interviewType.label
	}))

	if (interviewTypes.length === 0) return null
	return (
		<AutocompleteSelect
			rules={rules}
			name="interviewTypeId"
			items={items}
			label={t('drawer:interview.interviewType')}
			loading={loading}
			inputProps={{ size: 'small' }}
		/>
	)
}

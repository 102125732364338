import { ProfileResume } from '@domains/resume/ProfileResume'
import { DefaultLayout } from '@ui/layout/DefaultLayout'
import i18next from 'i18next'
import { type ReactElement } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { RoutePath } from '../../../../router/RoutePath'
import { useGetJobSearchListQuery } from '@gqlTypes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { ResumeLibraryBrowserButtons } from './ResumeLibraryBrowserButtons'
import { useFilters } from '@shared/hooks/useFilters'

export const ResumeLibraryBrowser = (): ReactElement => {
	const { filters } = useFilters('resumeLibrary')
	const { jobSearchId } = useParams<{ jobSearchId: string }>()
	const { state } = useLocation<{ isFromApp: boolean }>()
	const isFromApp = state?.isFromApp

	const variables = { cursor: '', limit: 12, filters }
	const { data, loading, fetchMore } = useGetJobSearchListQuery({ variables })

	if (loading || data?.getJobSearchList == null) return <LocalLoader />

	const cursor = data?.getJobSearchList?.cursor ?? ''
	const hasNextPage = data?.getJobSearchList?.hasNextPage ?? false
	const nodes = data?.getJobSearchList?.nodes ?? []

	const profileIds = nodes.map(({ id }) => id)
	const currentUserId = nodes.find((profile) => profile.id === jobSearchId)?.userOffer?.userInfo?.userId ?? ''

	return (
		<DefaultLayout
			isSticky
			backRoute={RoutePath.resumeLibrary}
			middle={
				isFromApp ? (
					<ResumeLibraryBrowserButtons
						jobSearchIds={profileIds}
						currentJobSearchId={jobSearchId}
						hasNextPage={hasNextPage}
						fetchMore={async () => await fetchMore({ variables: { cursor } })}
					/>
				) : null
			}
		>
			<ProfileResume
				page={`${import.meta.env.VITE_WEB_PUBLIC_URL}resume/user/${currentUserId}/iframe/${i18next.language}`}
			/>
		</DefaultLayout>
	)
}

import { useState, type ReactElement, type CSSProperties, type ReactNode } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'

interface Props {
	isExpandedByDefault: boolean
	boxStyle: CSSProperties
	textStyle: CSSProperties
	expandText: string
	collapseText: string
	children: ReactNode
}

export const ShowMore = ({
	expandText,
	collapseText,
	boxStyle,
	textStyle,
	isExpandedByDefault,
	children
}: Props): ReactElement => {
	const { t } = useTranslation(['translation'])
	const [isExpanded, setExpanded] = useState(isExpandedByDefault ?? false)

	return (
		<div style={boxStyle}>
			<Collapse in={isExpanded}>
				<div
					style={{
						position: 'relative',
						opacity: isExpanded ? 1 : 0,
						transition: 'opacity 0.3s linear'
					}}
				>
					{children}
				</div>
			</Collapse>
			<Typography
				sx={{
					background: 'transparent',
					border: 'none',
					cursor: 'pointer',
					padding: 0,
					margin: 0,
					marginTop: 10
				}}
				className="no-print"
				variant="button"
				style={{ ...textStyle }}
				component="button"
				onClick={() => {
					setExpanded(!isExpanded)
				}}
			>
				{isExpanded
					? t(collapseText ?? 'translation:showMore.collapseLabel')
					: t(expandText ?? 'translation:showMore.expandLabel')}
			</Typography>
		</div>
	)
}

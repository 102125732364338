import InfoValue from '@features/details/components/informationsTab/InfoValue'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import EventIcon from '@mui/icons-material/Event'
import WorkIcon from '@mui/icons-material/Work'
import { IntegrationCategory, useMeQuery } from '@gqlTypes'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { Button, TableRow } from '@mui/material'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import InfoIcon from '@mui/icons-material/Info'
import { BooleanInput } from '@ui/core/BooleanInput'

interface Props {
	category: string
	registerDateLabel: string
	publicTitle: string
	visioLink: string
	information: string
	isEnglish: boolean
	comment: string
}

export const KeyInformationsCard = ({
	category,
	registerDateLabel,
	publicTitle,
	visioLink,
	information,
	isEnglish,
	comment
}: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data } = useMeQuery()
	const hasVideoLink = visioLink !== ''
	const isCategoryCompany = category === IntegrationCategory.Company
	const isExternal = data?.me?.role?.isExternal ?? true
	const valuesToCheck = [visioLink, information]
	if (!isExternal) valuesToCheck.push(comment)
	if (isCategoryCompany) valuesToCheck.push(publicTitle, registerDateLabel)
	const isAllEmptyValues = valuesToCheck.every((value) => value === '') && !isEnglish

	if (isAllEmptyValues) return null

	return (
		<CardLayout title={t('translation:candidates.informations.cardTitle.generalInfos')}>
			<TableLayout>
				{hasVideoLink ? (
					<>
						<InfoLayoutLabel icon={<VideoCameraFrontIcon />} verticalAlign={'middle'}>
							{t('translation:candidates.informations.visioLink')}
						</InfoLayoutLabel>
						<InfoLayoutValue>
							<Button variant="contained" color="secondary" href={visioLink} target="_blank">
								{t('translation:candidates.informations.visioLinkLabel')}
							</Button>
						</InfoLayoutValue>
					</>
				) : null}
				<InfoValue
					icon={<InfoIcon />}
					title={t('translation:candidates.informations.Information')}
					value={information}
				/>
				{isCategoryCompany ? (
					<>
						<InfoValue
							icon={<EventIcon />}
							title={t('interview:keyInformations.registerDate')}
							value={registerDateLabel}
						/>
						<InfoValue icon={<WorkIcon />} title={t('interview:keyInformations.publicTitle')} value={publicTitle} />
					</>
				) : null}
				{!isExternal ? (
					<InfoValue icon={<InfoIcon />} title={t('translation:candidates.informations.note')} value={comment} />
				) : null}
				{isEnglish ? (
					<TableRow>
						<InfoLayoutLabel>{t('translation:candidates.informations.parcour')}</InfoLayoutLabel>
						<InfoLayoutValue>
							<BooleanInput value={isEnglish} label={t('translation:candidates.informations.english')} readOnly />
						</InfoLayoutValue>
					</TableRow>
				) : null}
			</TableLayout>
		</CardLayout>
	)
}

import { Stack, Typography } from '@mui/material'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { type ReactElement } from 'react'

interface Props {
	businessUnit: string
	location: string
}

export const CurrentLocation = ({ businessUnit, location }: Props): ReactElement | null => {
	const schoolLabel = [businessUnit, location].filter((str) => str !== '').join(' • ')

	if (schoolLabel === '') return null
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<LocationCityIcon sx={{ width: 18 }} />
			<Typography
				sx={{ fontSize: '14px', color: 'grays.gray3' }}
				whiteSpace="nowrap"
				overflow="hidden"
				textOverflow="ellipsis"
			>
				{schoolLabel}
			</Typography>
		</Stack>
	)
}

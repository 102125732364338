import { type GetOfferTrackDetailsQuery, type GetPositionTrackDetailsQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import ActiveField from '../fields/ActiveField'
import CreationDateField from '../fields/CreationDateField'
import PublicationDateField from '../fields/PublicationDateField'
import PublicField from '../fields/PublicField'
import { type Control, type FieldErrors } from 'react-hook-form'

interface Props {
	control: Control
	errors: FieldErrors
	details?: GetOfferTrackDetailsQuery['getTrackDetails'] | GetPositionTrackDetailsQuery['getTrackDetails']
	isOffer: boolean
}

export default function TrackPublicationCard({ control, details, isOffer }: Props): JSX.Element {
	const { t } = useTranslation()

	return (
		<CardLayout title={t('tracks:details.cards.publication')}>
			<TableLayout>
				<ActiveField control={control} defaultValue={details?.isActive ?? undefined} />
				{isOffer && <PublicField control={control} defaultValue={details?.isPublic ?? undefined} />}
				<CreationDateField control={control} defaultValue={details?.creationDate ?? undefined} />
				{isOffer && <PublicationDateField control={control} defaultValue={details?.publicationDate} />}
			</TableLayout>
		</CardLayout>
	)
}

import { useCallback, useEffect, useState } from 'react'
import { discussionService, discussionUserService, userOfferService } from '../../services/Services'
import { useErrorHandler } from 'react-error-boundary'
import { readQuerySnapshot } from '../../firebase/firestore.helper'
import { generateRoute, ROUTE_CANDIDATE_DETAIL } from '../../router/routes'
import { useHistory } from 'react-router-dom'
import { buildDiscussion } from './discussionBuilder.helper'
import { useTranslation } from 'react-i18next'
import { useDecryptMessagesLazyQuery, usePostMessageFromOrganizationMutation } from '@gqlTypes'
import { useOrganization } from '@domains/organization/useOrganization'

export default function useDiscussionContent(discussionUser, setDiscussionUser, userId, displayName) {
	const errorHandler = useErrorHandler()
	const { organizationId } = useOrganization()
	const history = useHistory()
	const { t } = useTranslation()

	const [isRead, setIsRead] = useState(false)
	const [isFlagged, setIsFlagged] = useState(false)
	const [isArchived, setIsArchived] = useState(false)

	const [userOfferList, setUserOfferList] = useState([])
	const [isLoadingMessages, setIsLoadingMessages] = useState(false)
	const [messages, setMessages] = useState([])
	const [decryptedMessages, setDecryptedMessages] = useState([])
	const [decryptMessages] = useDecryptMessagesLazyQuery()
	const [postMessage, { loading: isSendingMessage }] = usePostMessageFromOrganizationMutation()

	useEffect(() => {
		if (discussionUser) {
			try {
				const update = async () => {
					await discussionUserService.updateDiscussionUser(discussionUser.id, { read: true })
				}
				setIsRead(true)
				setIsFlagged(discussionUser.flagged)
				setIsArchived(discussionUser.archived)
				update()
			} catch (error) {
				errorHandler(error)
			}
		}
	}, [discussionUser, errorHandler])

	useEffect(() => {
		if (discussionUser) {
			userOfferService
				.getUserOffersOfUser(discussionUser.recipientId, organizationId)
				.then((userOfferSnapShot) => {
					setUserOfferList(readQuerySnapshot(userOfferSnapShot))
				})
				.catch(errorHandler)
		}
	}, [discussionUser, errorHandler, organizationId])

	// get messages socket
	useEffect(() => {
		let unsubscribe
		if (discussionUser) {
			setIsLoadingMessages(true)
			let crypted
			unsubscribe = discussionService
				.getDiscussionMessages(discussionUser.discussionId)
				.onSnapshot(async (messagesSnapshot) => {
					crypted = readQuerySnapshot(messagesSnapshot)
					const cryptedMessages = crypted.map((message) => message.text)
					const variables = {
						discussionId: discussionUser.discussionId,
						messages: cryptedMessages
					}
					const { data } = await decryptMessages({ variables })
					const decrypted = crypted
						.map((message, index) => {
							return { ...message, decrypted: data.decryptMessages[index] }
						})
						.sort((msgA, msgB) => msgA.creationDate.seconds - msgB.creationDate.seconds)

					const builded = buildDiscussion(discussionUser, decrypted, t, displayName)

					setMessages(decrypted)
					setDecryptedMessages(builded)
					setIsLoadingMessages(false)
				})
		}
		return () => {
			if (unsubscribe) unsubscribe()
		}
	}, [discussionUser, errorHandler])

	const handleNewMessage = useCallback(
		async (msg) => {
			const message = {}
			message.text = msg
			const result = await postMessage({ variables: { recipientId: userId, messageText: msg } })
			if (!discussionUser) setDiscussionUser(result.data.postMessageFromOrganization.senderDiscussionUser)
		},
		[discussionUser, errorHandler, organizationId, setDiscussionUser, userId]
	)

	const handleMarkChange = useCallback(
		(field, newValue, onSuccess) => {
			const payload = {}
			payload[field] = newValue
			discussionUserService
				.updateDiscussionUser(discussionUser.id, payload)
				.then(() => onSuccess(newValue))
				.catch(errorHandler)
		},
		[discussionUser, errorHandler]
	)

	const onCandidacySelect = useCallback(
		(userOfferId) => {
			history.push(generateRoute(ROUTE_CANDIDATE_DETAIL, { userOfferId }))
		},
		[history]
	)

	return {
		isRead,
		setIsRead,
		isFlagged,
		setIsFlagged,
		isArchived,
		setIsArchived,
		userOfferList,
		handleMarkChange,
		onCandidacySelect,
		displayName,
		messages,
		isSendingMessage,
		isLoadingMessages,
		handleNewMessage,
		decryptedMessages
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { SET_FILTERS, RESET_FILTERS } from '../actions/filters.actions'
import { type FilterInput } from '@gqlTypes'
import { deepCopyFunction } from '#helpers/massActions.helper'
import { filterOperation } from '@ui/filter/FilterForm'

export type FilterStore = Record<string, FilterInput[]>

const studentsFilters = [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }]
const interviewFilters = [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }]

export const initialState: FilterStore = {
	candidates: [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }],
	employees: [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }],
	studentslist: studentsFilters,
	studentsdashboard: studentsFilters,
	'community-members': [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }],
	interviewlist: interviewFilters,
	interviewdashboard: interviewFilters,
	discussions: [],
	'organization-users': [],
	orgareturns: [],
	'interview-template': [],
	offers: [{ key: 'isArchived', operation: filterOperation.EQUAL, value: false }],
	resumeLibrary: []
}

interface Action {
	type: string
	payload: any
}

const filters = function (state = initialState, action: Action): any {
	switch (action.type) {
		case SET_FILTERS: {
			const currentState = deepCopyFunction(state)
			currentState[action.payload.location] = action.payload.filters
			return currentState
		}
		case RESET_FILTERS: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export default filters

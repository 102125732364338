import {
	type GetFeedbackRequestTranslatedTemplatesQuery,
	useGetFeedbackRequestTranslatedTemplatesQuery
} from '@gqlTypes'
import { FormHelperText, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useTranslation } from 'react-i18next'
import { useArrayItem } from '@shared/hooks/useArrayItem'

interface Render {
	onChange: (...event: unknown[]) => void
	value: GetFeedbackRequestTranslatedTemplatesQuery['getFeedbackRequestTranslatedTemplates'][0]
}

export const TemplateSelector = (): ReactElement | null => {
	const { t } = useTranslation()
	const formContext = useFormContext()

	const name = 'message'
	const errors = formContext.formState?.errors ?? {}
	const error = errors[name]

	const { interviewId } = useParams<{ interviewId: string }>()
	const { data, loading } = useGetFeedbackRequestTranslatedTemplatesQuery({
		variables: { interviewId }
	})
	const templates = data?.getFeedbackRequestTranslatedTemplates ?? []
	const [currentTemplate, nextTemplate, previousTemplate] = useArrayItem(0, templates)

	if (loading || data == null) return null

	return (
		<Controller
			name={name}
			rules={{
				validate: (message: string) => (message === '' ? t('form:errors.required') : true)
			}}
			defaultValue={templates[0].content}
			render={({ onChange, value }: Render) => {
				return (
					<Stack>
						<Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
							<Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>{currentTemplate.title}</Typography>
							{templates.length > 0 ? (
								<Stack direction="row" sx={{ alignItems: 'center' }}>
									<IconButton
										onClick={() => {
											onChange(previousTemplate().content)
										}}
									>
										<NavigateBeforeIcon />
									</IconButton>
									<Typography>{t('feedbackRequest:form.feedback.template.selector.label')}</Typography>
									<IconButton
										onClick={() => {
											onChange(nextTemplate().content)
										}}
									>
										<NavigateNextIcon />
									</IconButton>
								</Stack>
							) : null}
						</Stack>

						<Paper
							elevation={0}
							sx={{
								boxShadow: 8,
								borderRadius: 4,
								borderBottomRightRadius: 2
							}}
						>
							<TextField
								InputProps={{
									sx: {
										'& fieldset': { border: 'none' }
									}
								}}
								fullWidth
								multiline
								value={value}
								onChange={(e) => {
									onChange(e.target.value)
								}}
							/>
						</Paper>
						<FormHelperText error>{error?.message ?? ''}</FormHelperText>
					</Stack>
				)
			}}
		/>
	)
}

interface Props {
	organizationId: string
	connectedUserId: string
	interviewId: string
}

export const useInterviewAttachmentFilePath = ({
	organizationId,
	connectedUserId,
	interviewId
}: Props): { filePath: string } => {
	const filePath = `organizations/${organizationId}/users/${connectedUserId}/interviews/${interviewId}`

	return { filePath }
}

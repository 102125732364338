/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, TextField } from '@mui/material'
import { type Control, Controller, type FieldValues } from 'react-hook-form'

interface Item {
	value: any
	label: string
}

interface Props {
	control?: Control
	rules?: FieldValues
	name: string
	defaultValue?: any
	items: Item[]
	label?: string
	loading?: boolean
	orderItems?: boolean
}

export default function AutocompleteField({
	control,
	rules = {},
	name,
	defaultValue,
	items,
	label = '',
	loading = false,
	orderItems = true
}: Props): JSX.Element {
	const orderedItems = orderItems ? [...items].sort((a, b) => a.label.localeCompare(b.label)) : items

	return (
		<Controller
			control={control}
			rules={rules}
			name={name}
			defaultValue={defaultValue}
			render={({ onChange, value }) => {
				return (
					<Autocomplete
						defaultValue={defaultValue}
						options={orderedItems}
						getOptionLabel={(option: Item) => option.label}
						onChange={(_: any, newValue: any) => {
							onChange(newValue)
						}}
						value={value}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.value}>
									{option.label}
								</li>
							)
						}}
						renderInput={(params) => <TextField {...params} label={label} />}
						loading={loading}
					/>
				)
			}}
		/>
	)
}

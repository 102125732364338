import { type ReactElement } from 'react'
import { Box } from '@mui/material'
import { GraphRadarLegendItem } from './GraphRadarLegendItem'

interface Props {
	dimensions: { id: string; label: string; icon: string; color: string }[]
	columnsNumber?: number
	noSpace?: boolean
}

export const GraphRadarLegend = ({ dimensions, columnsNumber = 2, noSpace = false }: Props): ReactElement => {
	return (
		<Box sx={{ columns: columnsNumber }}>
			{dimensions.map((dimension) => (
				<GraphRadarLegendItem
					key={dimension.id}
					label={dimension.label}
					icon={dimension.icon}
					color={dimension.color}
					noSpace={noSpace}
				/>
			))}
		</Box>
	)
}

import i18next from 'i18next'
import { type ReactElement } from 'react'

interface Props {
	date: Date
	dateStyle?: 'full' | 'long' | 'medium' | 'short' | 'none'
	timeStyle?: 'full' | 'long' | 'medium' | 'short' | 'none'
	relative?: boolean
}

export const LocalDate = ({ date, dateStyle = 'short', timeStyle = 'none' }: Props): ReactElement => {
	const lang = i18next.language

	const localeOptions: Intl.DateTimeFormatOptions = {}

	if (dateStyle.length > 0 && dateStyle !== 'none') {
		localeOptions.dateStyle = dateStyle
	}

	if (timeStyle.length > 0 && timeStyle !== 'none') {
		localeOptions.timeStyle = timeStyle
	}

	const local = new Intl.DateTimeFormat(lang, localeOptions)

	return <>{local.format(date)}</>
}

import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { DateTime } from 'luxon'
import { useMeQuery } from '@gqlTypes'

export const StartMonthFilter = (): ReactElement => {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const { t } = useTranslation()

	const startMonthValues = Array.from(Array(12).keys()).map((_, index) => {
		const month = DateTime.now().set({ month: DateTime.now().month }).plus({ month: index })
		const value = month.toFormat('yyyy-MM')
		const label = month.setLocale(data?.me?.language ?? '').toFormat('MMMM yyyy')
		return { value, label }
	})

	return (
		<InputFilter keyName="startMonthIds" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				isOptionsSorted={false}
				options={startMonthValues}
				label={t('lists:filter.startMonth.label')}
			/>
		</InputFilter>
	)
}

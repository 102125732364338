import { ErrorHandler } from '#helpers/error.helper'
import { useUndoInterviewSignatureMutation } from '@gqlTypes'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useRole } from '@shared/hooks/useRole'
import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	interviewId: string
	isLocked: boolean
}

export const UndoSignatureButton = ({ interviewId, isLocked }: Props): ReactElement | null => {
	const [isOpen, setIsOpen] = useState(false)
	const close = (): void => {
		setIsOpen(false)
	}
	const open = (): void => {
		setIsOpen(true)
	}
	const { t } = useTranslation()
	const { isAdmin } = useRole()
	const [undoInterviewSignature] = useUndoInterviewSignatureMutation()
	const onPressConfirm = (): void => {
		;(async () => {
			await undoInterviewSignature({ variables: { interviewId } })
			close()
		})().catch(ErrorHandler)
	}

	const title = t('interview:undoSignature.popup.title')
	const message = t('interview:undoSignature.popup.message')
	const confirmLabel = t('interview:undoSignature.popup.confirmLabel')
	const cancelLabel = t('interview:undoSignature.popup.cancelLabel')

	if (!isAdmin || isLocked) return null

	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={isOpen} onClose={close}>
				<DialogTitle>
					<Typography variant="h6">{title}</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography>{message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={close}>
						{cancelLabel}
					</Button>
					<Button variant="outlined" color="success" onClick={onPressConfirm}>
						{confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>
			<Button className="no-print" variant="outlined" color="error" onClick={open}>
				{t('interview:undoSignature.button')}
			</Button>
		</>
	)
}

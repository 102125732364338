import { Checkbox } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	value?: boolean | 'empty'
	onChange?: (value: string | boolean) => void
}

export const CheckboxFilter = ({ value, onChange = () => null }: Props): ReactElement => {
	const isChecked = value === 'empty' ? false : value

	const onChangeValue = (_e: unknown, checked: boolean): void => {
		onChange(checked ? true : 'empty')
	}
	return <Checkbox checked={isChecked} value={value} onChange={onChangeValue} />
}

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enAppKey from './en/en-appkey.json'
import enUserOfferStatus from './en/en-userOfferStatus.json'
import enProfileProfiling from './en/en-profileProfiling.json'
import enLists from '../features/lists/translations/lists/en-lists.json'
import enCandidates from '../features/lists/translations/candidates/en-candidates.json'
import enCommunityMembers from '../features/lists/translations/communityMembers/en-communityMembers.json'
import enDiscussions from '../features/lists/translations/discussions/en-discussions.json'
import enInterviews from '../features/lists/translations/interviews/en-interviews.json'
import enEmployees from '../features/lists/translations/employees/en-employees.json'
import enCsv from '../features/lists/translations/csv/en-csv.json'
import enStudents from '../features/lists/translations/students/en-students.json'
import enOrgaSkills from './en/en-orgaSkill.json'
import enModals from '../features/modals/translations/en-modals.json'
import enSharedReturnTab from './en/en-sharedReturnTab.json'
import enDetails from './en/en-details.json'
import enDrawer from './en/en-drawer.json'
import enKeyIndicators from './en/en-keyIndicators.json'
import enSlider from './en/en-slider.json'
import enTracks from './en/en-tracks.json'
import enMembers from './en/en-members.json'
import enOrgaReturns from '../features/lists/translations/orgaReturns/en-orgaSkills.json'
import enOrganizationUsers from '../features/lists/translations/organizationUsers/en-organizationUsers.json'
import enDiscussion from './en/en-discussion.json'
import enOrganizationFeedback from './en/organizationFeedback.json'
import enAuth from './en/auth.json'
import enInterview from './en/interview.json'
import frForm from './fr/form.json'
import frOnboardingHelp from './fr/onboardingHelp.json'
import enOrganizationCreation from './en/organizationCreation.json'
import enInterviewTemplate from './en/en-interviewTemplate.json'
import enOffers from './en/offers.json'
import enFeedbackRequest from './en/en-feedbackRequest.json'
import enRisk from './en/en-risk.json'
import enMinimumVersion from './en/minimumVersion.json'
import enCareerMatching from './en/careerMatching.json'
import enCustomTranslation from './en/customTranslation.json'
import enTutorial from './en/en-tutorial.json'

import frAppKey from './fr/fr-appkey.json'
import frUserOfferStatus from './fr/fr-userOfferStatus.json'
import frProfileProfiling from './fr/fr-profileProfiling.json'
import frLists from '../features/lists/translations/lists/frlists.json'
import frCandidates from '../features/lists/translations/candidates/fr-candidates.json'
import frCommunityMembers from '../features/lists/translations/communityMembers/fr-communityMembers.json'
import frDiscussions from '../features/lists/translations/discussions/fr-discussions.json'
import frInterviews from '../features/lists/translations/interviews/fr-interviews.json'
import frEmployees from '../features/lists/translations/employees/fr-employees.json'
import frCsv from '../features/lists/translations/csv/fr-csv.json'
import frStudents from '../features/lists/translations/students/fr-students.json'
import frOrgaSkills from './fr/fr-orgaSkill.json'
import frModals from '../features/modals/translations/fr-modals.json'
import frSharedReturnTab from './fr/fr-sharedReturnTab.json'
import frDetails from './fr/fr-details.json'
import frDrawer from './fr/fr-drawer.json'
import frKeyIndicators from './fr/fr-keyIndicators.json'
import frSlider from './fr/fr-slider.json'
import frTracks from './fr/fr-tracks.json'
import frMembers from './fr/fr-members.json'
import frTutorial from './fr/fr-tutorial.json'
import frOrgaReturns from '../features/lists/translations/orgaReturns/fr-orgaSkills.json'
import frOrganizationUsers from '../features/lists/translations/organizationUsers/fr-organizationUsers.json'
import frText from './fr/fr-text.json'
import enText from './en/en-text.json'
import frDiscussion from './fr/fr-discussion.json'
import frOrganizationFeedback from './fr/organizationFeedback.json'
import frAuth from './fr/auth.json'
import frInterview from './fr/interview.json'
import frExitMessage from './fr/exitMessage.json'
import enExitMessage from './en/exitMessage.json'
import enForm from './en/form.json'
import enOnboardingHelp from './en/onboardingHelp.json'
import frOrganizationCreation from './fr/organizationCreation.json'
import frInterviewTemplate from './fr/fr-interviewTemplate.json'
import frOffers from './fr/offers.json'
import frFeedbackRequest from './fr/fr-feedbackRequest.json'
import frRisk from './fr/fr-risk.json'
import frMinimumVersion from './fr/minimumVersion.json'
import frCareerMatching from './fr/careerMatching.json'
import frCustomTranslation from './fr/customTranslation.json'

import itAppKey from './it/it-appkey.json'
import itFeedbackRequest from './it/it-feedbackRequest.json'
import itRisk from './it/it-risk.json'
import itSharedReturnTab from './it/it-sharedReturnTab.json'
import itMinimumVersion from './it/minimumVersion.json'
import itCareerMatching from './it/careerMatching.json'
import itOffers from './it/offers.json'
import itCustomTranslation from './it/customTranslation.json'
import itLists from '../features/lists/translations/lists/it-lists.json'
import itTutorial from './it/it-tutorial.json'
import itModals from '../features/modals/translations/it-modals.json'

export default function initTranslations() {
	const resources = {
		en: {
			exitMessage: enExitMessage,
			translation: enAppKey,
			userOfferStatus: enUserOfferStatus,
			profileProfiling: enProfileProfiling,
			lists: enLists,
			candidates: enCandidates,
			discussions: enDiscussions,
			interviews: enInterviews,
			employees: enEmployees,
			csv: enCsv,
			students: enStudents,
			orgaSkill: enOrgaSkills,
			modal: enModals,
			sharedReturnTab: enSharedReturnTab,
			details: enDetails,
			drawer: enDrawer,
			keyIndicators: enKeyIndicators,
			sliders: enSlider,
			tracks: enTracks,
			members: enMembers,
			orgaReturns: enOrgaReturns,
			organizationUsers: enOrganizationUsers,
			text: enText,
			discussion: enDiscussion,
			organizationFeedback: enOrganizationFeedback,
			auth: enAuth,
			interview: enInterview,
			form: enForm,
			communityMembers: enCommunityMembers,
			onboardingHelp: enOnboardingHelp,
			organizationCreation: enOrganizationCreation,
			interviewTemplate: enInterviewTemplate,
			offers: enOffers,
			feedbackRequest: enFeedbackRequest,
			risk: enRisk,
			minimumVersion: enMinimumVersion,
			careerMatching: enCareerMatching,
			customTranslation: enCustomTranslation,
			tutorial: enTutorial
		},
		fr: {
			exitMessage: frExitMessage,
			translation: frAppKey,
			userOfferStatus: frUserOfferStatus,
			profileProfiling: frProfileProfiling,
			lists: frLists,
			candidates: frCandidates,
			discussions: frDiscussions,
			interviews: frInterviews,
			employees: frEmployees,
			csv: frCsv,
			students: frStudents,
			orgaSkill: frOrgaSkills,
			modal: frModals,
			sharedReturnTab: frSharedReturnTab,
			details: frDetails,
			drawer: frDrawer,
			keyIndicators: frKeyIndicators,
			sliders: frSlider,
			tracks: frTracks,
			members: frMembers,
			orgaReturns: frOrgaReturns,
			organizationUsers: frOrganizationUsers,
			text: frText,
			discussion: frDiscussion,
			organizationFeedback: frOrganizationFeedback,
			auth: frAuth,
			interview: frInterview,
			form: frForm,
			communityMembers: frCommunityMembers,
			onboardingHelp: frOnboardingHelp,
			organizationCreation: frOrganizationCreation,
			interviewTemplate: frInterviewTemplate,
			offers: frOffers,
			feedbackRequest: frFeedbackRequest,
			risk: frRisk,
			minimumVersion: frMinimumVersion,
			careerMatching: frCareerMatching,
			customTranslation: frCustomTranslation,
			tutorial: frTutorial
		},
		it: {
			translation: itAppKey,
			feedbackRequest: itFeedbackRequest,
			risk: itRisk,
			sharedReturnTab: itSharedReturnTab,
			minimumVersion: itMinimumVersion,
			careerMatching: itCareerMatching,
			offers: itOffers,
			customTranslation: itCustomTranslation,
			lists: itLists,
			tutorial: itTutorial,
			modal: itModals
		}
	}

	return i18n
		.use(LanguageDetector)
		.use(initReactI18next)
		.init({
			fallbackLng: 'en',
			compatibilityJSON: 'v3',
			supportedLngs: ['en', 'fr', 'it'],
			debug: false,
			resources,
			keySeparator: '.',
			interpolation: {
				skipOnVariables: false,
				escapeValue: false, // react already safes from xss
				prefix: '%{',
				suffix: '}'
			}
		})
}

import { ScreenId } from '@gqlTypes'
import { StudentsList } from './list/StudentsList.page'
import { StudentsDashboard } from './dashboard/StudentsDashboard.page'
import { Redirect } from 'react-router-dom'

enum Path {
	default = '/students',
	list = '/students/list',
	dashboard = '/students/dashboard'
}

const routes = [
	{
		path: Path.list,
		children: <StudentsList />
	},
	{
		path: Path.default,
		children: <Redirect to={Path.list} />
	},
	{
		path: Path.dashboard,
		children: <StudentsDashboard />
	}
]

export const StudentsRouter = {
	roleId: ScreenId.Students,
	paths: Path,
	routes
}

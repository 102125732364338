import { Typography, type TypographyProps } from '@mui/material'
import { type ReactElement } from 'react'

interface Props extends TypographyProps {
	score?: number
}

export const ScoreEmoji = ({ score, ...props }: Props): ReactElement | null => {
	if (score == null) return null

	const emojis: Record<number, string> = { 1: '😤', 2: '🙁', 3: '😐', 4: '🙂', 5: '😍' }
	const emoji = score > 0 ? emojis[Math.floor(Math.min(score + 1, 5))] : ''

	const scoreLabel = Number((score * 2).toFixed(1))
	const label = `${scoreLabel} ${emoji}`

	return <Typography sx={{ flexShrink: 0, width: '48px', ...props }}>{label}</Typography>
}

import { CircularProgress } from '@mui/material'
import { type CSSProperties, type ReactElement } from 'react'

interface Props {
	children?: ReactElement | null
	load?: boolean
	flex?: boolean
	withPadding?: boolean
	size?: number
	white?: boolean
	style?: CSSProperties
}

export const LocalLoader = ({
	children = null,
	load = true,
	flex = false,
	withPadding = false,
	size,
	style,
	white = false
}: Props): ReactElement | null => {
	if (!load) return children

	return (
		<div
			style={{
				flex: flex ? 1 : undefined,
				height: flex ? undefined : '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				width: '100%',
				padding: withPadding ? 50 : 0,
				...style
			}}
		>
			<CircularProgress size={size} sx={{ color: white ? '#fff' : 'inherit' }} />
		</div>
	)
}

import { versionFactory } from './Version.factory'

export class Version {
	major: number
	minor: number
	patch: number

	constructor({ major = 0, minor = 0, patch = 0 }: Partial<Version>) {
		this.major = major
		this.minor = minor
		this.patch = patch
	}

	get value(): number {
		return this.major * 1000000 + this.minor * 1000 + this.patch
	}

	isNewer(minVersionValue: string): boolean {
		const minVersion = versionFactory.build(minVersionValue)
		return this.value >= minVersion.value
	}
}

import { SET_DATALIST } from '../actions/dataList.actions'

const initialState = []

const dataList = function (state = initialState, action) {
	switch (action.type) {
		case SET_DATALIST: {
			return action.payload
		}
		default: {
			return state
		}
	}
}

export default dataList

import { DefaultLayout, type DefaultLayoutProps } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationTabs } from '@shared/tabs/components/NavigationTabs'
import { type TabContent } from '@shared/tabs/types/TabContent'
import { TabsRouter } from './TabsRouter'

interface Props extends Omit<DefaultLayoutProps, 'children'> {
	tabs: TabContent[]
}

export const DefaultTabsLayout = ({ tabs, ...layoutProps }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<DefaultLayout {...layoutProps} title={t('menu.my-profile')} middle={<NavigationTabs tabs={tabs} />}>
			<TabsRouter routes={tabs} />
		</DefaultLayout>
	)
}

import { type ReactElement } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import { setSelectedRows } from '@shared/store/actions/selectedRows.actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface Props {
	setIsAllRowsSelected: (value: boolean) => void
	isAllRowsSelected: boolean
}

export const SelectAllRowButton = ({ setIsAllRowsSelected, isAllRowsSelected }: Props): ReactElement => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const toogleSelectAllRows = (): void => {
		setIsAllRowsSelected(!isAllRowsSelected)
		dispatch(setSelectedRows(isAllRowsSelected ? [] : [0]))
	}

	return (
		<Tooltip title={isAllRowsSelected ? t('unselectAll') : t('selectAll')} placement="right">
			<IconButton onClick={toogleSelectAllRows}>
				{isAllRowsSelected ? <LibraryAddCheckIcon color="primary" /> : <FilterNoneIcon color="primary" />}
			</IconButton>
		</Tooltip>
	)
}

import { Stack } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	children: ReactNode
}

export const LeftBar = ({ children }: Props): ReactElement => {
	return (
		<Stack direction="row" sx={{ alignItems: 'center' }} className="no-print">
			{children}
		</Stack>
	)
}

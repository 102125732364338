import { type ReactElement, useEffect, useState, type MutableRefObject } from 'react'
import { InterviewEvaluationCard } from '@shared/components/cards/InterviewEvaluationCard'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { InterviewQuestions } from '@domains/interviews/details/InterviewQuestions'
import { Card, Portal, Stack } from '@mui/material'
import { useUpdateInterview } from '@features/details/hooks/useUpdateInterview'
import { useInterviewSkills } from '@features/details/hooks/useInterviewSkills'
import { useSelfEvaluateSkillsMutation, type GetInterviewDetailsQuery } from '@gqlTypes'
import { useInterviewState } from '@domains/interviews/details/useInterviewState'
import { ExitFormWarning } from '@shared/components/ExitFormWarning'
import { InterviewGlobalEvaluationCard } from '@shared/components/cards/InterviewGlobalEvaluationCard'
import { ScoreCalculation } from '#types/ScoreCalculation'
import { InterviewTabBar } from '@domains/interviews/details/InterviewTabBar'
import { InterviewSection } from '@domains/interviews/details/InterviewSection'
import { type InterviewFormInput } from '@features/details/types/interviewsForm.type'
import { useScrollToError } from '@shared/hooks/form/useScrollToError'
import { ImageReturnDialog } from './ImageReturnDialog'
import { InterviewObjectives } from '@domains/interviews/objectives/InterviewObjectives'
import { LoadingButton } from '@mui/lab'
import { computeAverage } from '#helpers/array.helper'
import { AssessedSkillProgressionChip } from './AssessedSkillProgressionChip'
import { AssessorSkillProgressionChip } from './AssessorSkillProgressionChip'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	track: GetInterviewDetailsQuery['getInterview']['track']
	userIntegration: GetInterviewDetailsQuery['getInterview']['userIntegration']
	tabBarContainer: MutableRefObject<null>
}

export const InterviewForm = ({ interview, track, userIntegration, tabBarContainer }: Props): ReactElement | null => {
	const hasObjectives = interview.template?.hasObjectives ?? false
	const { normalSkillList, orgaOnlySkillList, skillScores, skillComments, mandatorySkills, subSkillScores } =
		useInterviewSkills({
			userIntegration,
			interview
		})
	const { isReadOnly, isAssesseePreparation, isEditable, isInterviewAssessee, hasSkillEvaluation } =
		useInterviewState(interview)
	const hasSkill = normalSkillList.length > 0 || orgaOnlySkillList.length > 0
	const isInterviewSendingFeedback = (track.isInterviewSendingFeedback ?? false) && hasSkill
	const interviewId = interview.id ?? ''
	const globalManualScore = (interview.validatedScore ?? 0) * 4
	const globalCalculatedScore = (interview.calculatedScore ?? 0) * 4
	const scoreCalculation = globalManualScore === 0 ? ScoreCalculation.Average : ScoreCalculation.Manual
	const defaultValues: InterviewFormInput = {
		comment: interview?.comment ?? '',
		globalManualScore,
		globalCalculatedScore,
		skillScores,
		subSkillScores,
		skillComments,
		scoreCalculation,
		answers: {},
		isDraft: false
	}

	const [selfEvaluateSkills, { loading: isSavingSelfEvaluation }] = useSelfEvaluateSkillsMutation()
	useEffect(() => {
		reset(defaultValues)
	}, [interview])
	const formMethods = useForm({ defaultValues })
	const {
		getValues,
		reset,
		trigger,
		errors,
		formState: { isDirty }
	} = formMethods
	useScrollToError({ errors })
	const { saveDraft, isSaving, isSavingDraft, handleSave } = useUpdateInterview({
		interviewId,
		isInterviewSendingFeedback,
		getValues,
		trigger
	})
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [skillId, setSkillId] = useState('')
	const onClick = (skillId: string): void => {
		setSkillId(skillId)
		setOpen(true)
	}
	const saveSelfEvaluation = async (): Promise<void> => {
		const { subSkillScores, skillComments, skillScores } = getValues()
		const skillsEvaluation = Object.entries(skillScores ?? {}).map(([softSkillId, skillScore]) => {
			const values = subSkillScores?.[softSkillId] ?? []
			const subskillsScore: Record<string, number> = {}
			values.map((v, index) => (subskillsScore[index] = v))
			const average = computeAverage(values)
			return {
				softSkillId,
				subskillsScore,
				comment: skillComments[softSkillId],
				manualScore: average === 0 ? skillScore : 0
			}
		})
		const variables = { skillsEvaluation, interviewId }
		await selfEvaluateSkills({ variables })
		reset({
			skillScores,
			subSkillScores,
			skillComments
		})
	}

	const tabBar = (
		<Portal container={tabBarContainer?.current}>
			<InterviewTabBar
				interview={interview}
				isDirty={isDirty}
				isReadOnly={isInterviewAssessee || isReadOnly}
				saveDraft={saveDraft}
				isSaving={isSaving}
				isSavingDraft={isSavingDraft}
				handleSave={handleSave}
			/>
		</Portal>
	)

	const interviewQuestionCategories = interview.questionCategories
	return (
		<FormProvider {...formMethods}>
			<ImageReturnDialog interviewId={interview.id ?? ''} skillId={skillId} open={open} setOpen={setOpen} />
			{tabBar}
			<ExitFormWarning isModified={isDirty} />
			<Stack spacing={3}>
				{hasSkill && hasSkillEvaluation ? (
					<InterviewSection
						title={t('interview:skillEvaluation.section')}
						progressionChip={
							isAssesseePreparation ? (
								<AssessedSkillProgressionChip skillList={normalSkillList} />
							) : (
								<AssessorSkillProgressionChip interview={interview} userIntegration={userIntegration} />
							)
						}
					>
						<Stack spacing={3}>
							<InterviewEvaluationCard
								title={t('translation:interviewDetailPage.normalSkillName')}
								skillList={normalSkillList}
								isReadOnly={isReadOnly}
								onClick={onClick}
								isAssesseePreparation={isAssesseePreparation}
								hasDetails
							/>
							{isAssesseePreparation ? (
								<LoadingButton
									loading={isSavingSelfEvaluation}
									variant="contained"
									sx={{ alignSelf: 'center' }}
									onClick={saveSelfEvaluation}
								>
									{t('interview:skillEvaluation.save')}
								</LoadingButton>
							) : (
								<>
									<InterviewEvaluationCard
										title={t('translation:interviewDetailPage.orgaOnlyName')}
										skillList={orgaOnlySkillList}
										isReadOnly={isReadOnly}
										onClick={onClick}
										isAssesseePreparation={isAssesseePreparation}
									/>
									<Card elevation={3}>
										<InterviewGlobalEvaluationCard
											isReadOnly={isReadOnly}
											track={track}
											mandatorySkills={mandatorySkills}
										/>
									</Card>
								</>
							)}
						</Stack>
					</InterviewSection>
				) : null}

				{hasObjectives && !isAssesseePreparation ? (
					<InterviewObjectives interviewId={interview.id ?? ''} isReadOnly={isReadOnly} />
				) : null}
				{!isAssesseePreparation ? (
					<InterviewQuestions
						interviewQuestionCategories={interviewQuestionCategories}
						interviewAnswers={interview.answersArray}
						isEditable={isEditable}
					/>
				) : null}
			</Stack>
		</FormProvider>
	)
}

import { type ReactElement, type JSXElementConstructor } from 'react'

type Children = ReactElement<unknown, string | JSXElementConstructor<unknown>>

interface Props {
	condition: boolean
	wrapper: (children: Children) => ReactElement
	children: Children
}

export const ConditionalWrapper = ({ condition, wrapper, children }: Props): ReactElement => {
	return condition ? wrapper(children) : <>{children}</>
}

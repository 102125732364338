import { type ReactElement, useState } from 'react'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import { CardMenuButton } from '@ui/core/CardMenuButton'
import { BooleanInput } from '@ui/core/BooleanInput'
import ChatIcon from '@mui/icons-material/Chat'
import FlagIcon from '@mui/icons-material/Flag'
import ArchiveIcon from '@mui/icons-material/Archive'
import ViewListIcon from '@mui/icons-material/ViewList'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

interface Props {
	photoURL: string
	isRead: boolean
	setIsRead: () => void
	isFlagged: boolean
	setIsFlagged: () => void
	isArchived: boolean
	setIsArchived: () => void
	userOfferList: { id: string; status: string; orgaOffer: { label: string } }[]
	handleMarkChange: (key: string, value: boolean, setValue: () => void) => void
	onCandidacySelect: (userOfferId: string) => void
	disabled: boolean
	displayName: string
}

export const DiscussionInfoCard = ({
	photoURL,
	isRead,
	setIsRead,
	isFlagged,
	setIsFlagged,
	isArchived,
	setIsArchived,
	userOfferList,
	handleMarkChange,
	onCandidacySelect,
	disabled,
	displayName
}: Props): ReactElement => {
	const { t } = useTranslation(['translation', 'userOfferStatus'])
	const theme = useTheme()
	const [isExpanded, setIsExpanded] = useState(false)

	return (
		<CardLayout>
			<InfoCardContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center'
				}}
			>
				{photoURL !== '' && <Avatar sx={{ fontSize: 50, width: 150, height: 150 }} src={photoURL} />}
				<Typography>{displayName}</Typography>
			</InfoCardContent>
			<Divider />
			{disabled ? (
				<>
					<CardMenuButton simple>
						<BooleanInput icon={<ChatIcon />} label={t('translation:discussionDetailPage.read.false')} readOnly />
					</CardMenuButton>
					<Divider />
					<CardMenuButton simple>
						<BooleanInput icon={<FlagIcon />} label={t('translation:discussionDetailPage.flagged.false')} readOnly />
					</CardMenuButton>
					<Divider />
					<CardMenuButton simple>
						<BooleanInput
							icon={<ArchiveIcon />}
							label={t('translation:discussionDetailPage.archived.false')}
							readOnly
						/>
					</CardMenuButton>
					<CardMenuButton>
						<BooleanInput
							label={t('translation:discussionDetailPage.candidacyList')}
							icon={<ViewListIcon />}
							readOnly
						/>
					</CardMenuButton>
				</>
			) : (
				<>
					<CardMenuButton
						onClick={() => {
							handleMarkChange('read', !isRead, setIsRead)
						}}
						simple
					>
						<BooleanInput
							icon={<ChatIcon />}
							checkedIcon={<ChatIcon style={{ color: theme.palette.darkBlue.light }} />}
							trueLabel={t('translation:discussionDetailPage.read.true')}
							falseLabel={t('translation:discussionDetailPage.read.false')}
							value={!isRead}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={() => {
							handleMarkChange('flagged', !isFlagged, setIsFlagged)
						}}
						simple
					>
						<BooleanInput
							icon={<FlagIcon />}
							checkedIcon={
								<FlagIcon
									style={{
										color: theme.palette.error.main
									}}
								/>
							}
							trueLabel={t('translation:discussionDetailPage.flagged.true')}
							falseLabel={t('translation:discussionDetailPage.flagged.false')}
							value={isFlagged}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={() => {
							handleMarkChange('archived', !isArchived, setIsArchived)
						}}
						simple
					>
						<BooleanInput
							icon={<ArchiveIcon />}
							checkedIcon={
								<ArchiveIcon
									style={{
										color: theme.palette.warning.main
									}}
								/>
							}
							trueLabel={t('translation:discussionDetailPage.archived.true')}
							falseLabel={t('translation:discussionDetailPage.archived.false')}
							value={isArchived}
							readOnly
						/>
					</CardMenuButton>
					<Divider />
					<CardMenuButton
						onClick={() => {
							setIsExpanded(!isExpanded)
						}}
					>
						<BooleanInput
							label={t('translation:discussionDetailPage.candidacyList')}
							icon={<ViewListIcon />}
							readOnly
						/>
					</CardMenuButton>
					<Collapse in={isExpanded}>
						{userOfferList.map((userOffer) => (
							<div key={userOffer.id}>
								<CardMenuButton
									onClick={() => {
										onCandidacySelect(userOffer.id)
									}}
								>
									<Typography>
										<Box fontWeight="fontWeightBold">{userOffer.orgaOffer.label}</Box>
										<Box>{t(`userOfferStatus:${userOffer.status}.labelOrga`)}</Box>
									</Typography>
								</CardMenuButton>
							</div>
						))}
					</Collapse>
				</>
			)}
		</CardLayout>
	)
}

import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Divider from '@mui/material/Divider'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	children: ReactNode
}

const useStyle = makeStyles()(() => ({
	root: {
		paddingLeft: 19,
		paddingRight: 19,
		paddingTop: 19,
		paddingBottom: 19,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',

		'& > *': {
			boxSizing: 'border-box',
			marginLeft: 5
		}
	}
}))

export const InfoCardToolbar = ({ children }: Props): ReactElement => {
	const { classes } = useStyle()
	return (
		<>
			<Divider />
			<div className={clsx(classes.root, 'emageme-infocardtoolbar')}>{children}</div>
		</>
	)
}

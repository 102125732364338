const { VITE_PROJECT_ID, VITE_API_KEY, VITE_MESSAGING_SENDER_ID, VITE_APP_ID, VITE_MEASUREMENT_ID } = import.meta.env

export const config = {
	firebase: {
		apiKey: VITE_API_KEY,
		authDomain: `${VITE_PROJECT_ID}.firebaseapp.com`,
		databaseURL: `https://${VITE_PROJECT_ID}.firebaseio.com`,
		projectId: `${VITE_PROJECT_ID}`,
		storageBucket: `${VITE_PROJECT_ID}.appspot.com`,
		messagingSenderId: `${VITE_MESSAGING_SENDER_ID}`,
		appId: `${VITE_APP_ID}`,
		measurementId: `${VITE_MEASUREMENT_ID}`
	},
	baseRef: `gs://${VITE_PROJECT_ID}.appspot.com/`
}

import { type GetOfferTrackDetailsQuery, type GetPositionTrackDetailsQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import MemberProfileField from '../fields/MemberProfileField'
import MemberSoftskillReturnField from '..//fields/MemberSoftskillReturnField'
import MemberSoftskillTestField from '../fields/MemberSoftskillTestField'
import SharedMemberContactInfoField from '../fields/SharedMemberContactInfoField'
import SharingMemberVisibleField from '../fields/SharingMemberVisibleField'
import { TrackCheckBox } from '../fields/TrackCheckBox'
import { type Control, type FieldErrors } from 'react-hook-form'

interface Props {
	control: Control
	errors: FieldErrors
	details?: GetOfferTrackDetailsQuery['getTrackDetails'] | GetPositionTrackDetailsQuery['getTrackDetails']
}

export default function TrackMemberCard({ control, details }: Props): JSX.Element {
	const { t } = useTranslation()

	return (
		<CardLayout title={t('tracks:details.cards.members')}>
			<TableLayout>
				<SharingMemberVisibleField control={control} defaultValue={details?.isSharingMemberVisible ?? undefined} />
				<MemberProfileField control={control} defaultValue={details?.sharingSettings?.sharedMemberProfile} />
				<MemberSoftskillTestField
					control={control}
					defaultValue={details?.sharingSettings?.sharedMemberSoftSkillsTest}
				/>
				<MemberSoftskillReturnField
					control={control}
					defaultValue={details?.sharingSettings?.sharedMemberSoftSkillsReturn}
				/>
				<SharedMemberContactInfoField
					control={control}
					defaultValue={details?.sharingSettings?.sharedMemberContactInfo ?? undefined}
				/>
				<TrackCheckBox control={control} fieldName="hasNotMemberButton" details={details} isReversed />
			</TableLayout>
		</CardLayout>
	)
}

import { InputTextFieldForm } from '@shared/components/forms/fields/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	organizationType: string
}

export const OrganizationName = ({ organizationType }: Props): ReactElement => {
	const { t } = useTranslation()
	const message = t('organizationCreation:requiredField')

	return (
		<InputTextFieldForm
			name="organizationName"
			label={t(`organizationCreation:organization.${organizationType}.field.name`)}
			rules={{ required: { value: true, message } }}
		/>
	)
}

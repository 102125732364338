import { SET_INTERVIEWFIELDS } from '../actions/interviewFields.action'

const initialState = null

const interviewFields = function (state = initialState, action) {
	switch (action.type) {
		case SET_INTERVIEWFIELDS: {
			return action.payload
		}
		default: {
			return state
		}
	}
}

export default interviewFields

import NavButtonIcon from './NavButtonIcon'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

interface Props {
	route: string
	tooltipTitle?: string
}

export default function BackButton({ route, tooltipTitle = '' }: Props): JSX.Element {
	return <NavButtonIcon route={route} icon={<ChevronLeftIcon />} tooltipTitle={tooltipTitle} />
}

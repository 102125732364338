import { useMeQuery } from '@gqlTypes'
import { Typography, useTheme } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	comments: { id: string; text: string; lastname: string; firstname: string; creationDate: string }[]
}

export const IndicatorComments = ({ comments }: Props): ReactElement | null => {
	const { data } = useMeQuery()
	const theme = useTheme()
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false

	if (comments == null || comments.length === 0) return null

	return (
		<>
			{comments.map((comment, index) => {
				const subtitle = isBrandFeedbackAnonymous
					? `${comment.creationDate ?? ''}`
					: `${comment.lastname} ${comment.firstname} - ${comment.creationDate ?? ''}`
				return (
					<div key={index + comment.text}>
						<Typography style={{ wordBreak: 'break-word' }}>&quot;{comment.text}&quot;</Typography>
						<Typography
							style={{
								color: theme.palette.grays.gray2,
								marginBottom: 7
							}}
						>
							{subtitle}
						</Typography>
					</div>
				)
			})}
		</>
	)
}

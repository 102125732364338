import { type CSSProperties, type ReactElement } from 'react'
import theme from '@ui/theme'

interface Props {
	title: string
	picture: string
	style?: CSSProperties
}

export const OrganizationTitle = ({ title, picture, style }: Props): ReactElement => {
	return (
		<div style={{ display: 'flex', alignItems: 'center', ...style }}>
			<img style={{ width: 80, height: 80 }} src={picture} />
			<h3 style={{ marginLeft: 20, color: theme.palette.primary.dark, fontSize: 40, marginTop: 0, marginBottom: 0 }}>
				{title}
			</h3>
		</div>
	)
}

import { useGetCoMembersQuery } from '@gqlTypes'
import { Autocomplete, Paper, Popper, Typography, createFilterOptions, FormHelperText } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { indexById } from '#helpers/array.helper'
import { MemberSelectorRow } from './MemberSelectorRow'
import { MemberSelectorInput } from './MemberSelectorInput'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

export const MemberSelector = (): ReactElement | null => {
	const { data, loading } = useGetCoMembersQuery()
	const { t } = useTranslation()
	const formContext = useFormContext()

	const name = 'userIds'
	const errors = formContext.formState?.errors ?? {}
	const error = errors[name]

	const members = data?.getCoMembers ?? []
	const filterOptions = createFilterOptions<string>({
		limit: 100
	})

	if (loading) return <LocalLoader />
	const indexedMembers = indexById(members)
	const memberIds = members.map(({ id }) => id)
	return (
		<>
			<Typography sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: 20, mb: 2 }}>
				{t('feedbackRequest:form.member.title')}
			</Typography>
			<FormHelperText error>{error?.message ?? ''}</FormHelperText>
			<Controller
				name={name}
				rules={{
					validate: (array) => (array.length > 0 ? true : t('form:errors.minSelectableItem', { count: 1 }))
				}}
				render={({ onChange, value }) => (
					<Autocomplete
						onChange={(_, value) => {
							onChange(value)
						}}
						value={value}
						multiple
						open
						options={memberIds}
						filterOptions={filterOptions}
						getOptionLabel={(memberId) => indexedMembers[memberId].fullName}
						ChipProps={{ color: 'primary' }}
						ListboxProps={{ style: { maxHeight: 450 } }}
						PaperComponent={(props) => <Paper {...props} sx={{ marginTop: 2, backgroundColor: '#FFFFFF90' }} />}
						PopperComponent={({ style, ...props }) => <Popper {...props} sx={{ ...style, height: 450 }} />}
						renderInput={(params) => <MemberSelectorInput params={params} />}
						renderOption={(props, memberId, state) => (
							<MemberSelectorRow
								key={memberId}
								member={indexedMembers[memberId]}
								props={props}
								isSelected={state.selected}
							/>
						)}
					/>
				)}
			/>
		</>
	)
}

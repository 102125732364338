export const SET_SELECTEDROWS = 'SET_SELECTEDROWS'
export const INITIALIZE_SELECTEDROWS = 'INITIALIZE_SELECTEDROWS'

export function setSelectedRows(selectedRows) {
	return {
		type: SET_SELECTEDROWS,
		payload: selectedRows
	}
}

export function initializeSelectedRows() {
	return {
		type: INITIALIZE_SELECTEDROWS
	}
}

import { type AutocompleteRenderInputParams, TextField } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	params: AutocompleteRenderInputParams
}

export const MemberSelectorInput = ({ params }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<TextField
			{...params}
			variant="outlined"
			sx={{ border: '4px solid #205186', borderRadius: 2, backgroundColor: 'white' }}
			InputProps={{
				...params.InputProps,
				placeholder: t('feedbackRequest:form.member.placeholder'),
				endAdornment: <>{params.InputProps.endAdornment}</>
			}}
		/>
	)
}

import { useParams } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useGetDiscussionUserFromOrganizationQuery } from '@gqlTypes'
import { ROUTE_DISCUSSIONS } from '../../../router/routes'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'
import { TopBar } from '@ui/core/TopBar'
import { DiscussionContent } from '@domains/discussions/DiscussionContent'
import { type ReactElement } from 'react'

export const DiscussionDetailsPage = (): ReactElement | null => {
	const { discussionUserId } = useParams<{ discussionUserId: string }>()
	const { loading, data } = useGetDiscussionUserFromOrganizationQuery({ variables: { id: discussionUserId } })
	const { t } = useTranslation()

	if (loading) return <LocalLoader />
	if (data == null) return null

	const discussionUser = data.getDiscussionUserFromOrganization
	const displayName = discussionUser?.recipientRef?.displayName ?? ''

	return (
		<Stack>
			<TopBar title={displayName} backRoute={ROUTE_DISCUSSIONS} backToolTip={t('menu.chat')} />
			<DiscussionContent
				discussionUser={discussionUser}
				userId={discussionUser?.recipientId ?? ''}
				displayName={displayName}
			/>
		</Stack>
	)
}

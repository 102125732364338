import { MassMessages } from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { MassFeedback } from '../../../features/modals/pages/MassFeedback'
import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import { IntegrationCategory, StudentExportDocument, TrackType, useUpdateManyUserIntegrationsMutation } from '@gqlTypes'
import { MassInterviewsCreation } from '@domains/interviews/createMany/MassInterviewsCreation'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { LeftBar } from '@features/lists/components/LeftBar'
import { useOrganization } from '@domains/organization/useOrganization'
import { useRole } from '@shared/hooks/useRole'
import { type ApolloQueryResult } from '@apollo/client'
import { type ReactElement } from 'react'
import { StudentsFilters } from '../StudentsFilters'

interface Props {
	isAllRowsSelected: boolean
	refetch: () => Promise<ApolloQueryResult<unknown>>
	dataList: { id: string }[]
	count: number
}

export const StudentsFilterBar = ({ isAllRowsSelected, refetch, dataList, count }: Props): ReactElement => {
	const { hasOrgaSkills } = useOrganization()
	const [updateManyUserIntegrations, { loading }] = useUpdateManyUserIntegrationsMutation()
	const { screens } = useRole()

	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.School,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<MassInterviewsCreation {...massAction} />
			{screens.students.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={loading}
					isAllRowsSelected={isAllRowsSelected}
					count={count}
				/>
			) : null}

			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={StudentExportDocument} />
			<StudentsFilters refetch={refetch} />
		</LeftBar>
	)
}

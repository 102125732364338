import { Stack, type StackProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props extends StackProps {
	count: number
	translationKey: string
}

export const OfferCount = ({ count, translationKey, ...stackProps }: Props): ReactElement => {
	const { t } = useTranslation()
	const label = t(translationKey, { count })
	return (
		<Stack gap={0} {...stackProps}>
			<Typography sx={{ fontSize: '34px', fontWeight: 900, lineHeight: 0.8, mt: 2, color: '#009FF8' }}>
				{count}
			</Typography>
			<Typography sx={{ fontSize: '12px', color: 'grays.gray3' }}>{label}</Typography>
		</Stack>
	)
}

import { useMeQuery } from '@gqlTypes'
import { Typography } from '@mui/material'
import moment from 'moment'
import { type ReactElement } from 'react'

interface Props {
	title: string
	senderDisplayName: string
	message: string
	creationDate: Date

	businessUnit: string
}

export const Comment = ({ title, senderDisplayName, message, creationDate, businessUnit }: Props): ReactElement => {
	const { data } = useMeQuery()
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false
	const dateStr = `${moment(creationDate).format('DD/MM/YYYY')}`
	const cutlines = isBrandFeedbackAnonymous ? dateStr : `${senderDisplayName} - ${dateStr}`

	return (
		<div>
			<Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
			<Typography sx={{ fontSize: 16 }}>&ldquo;{message.replace(/"/g, '&quot;')}&rdquo;</Typography>
			<Typography gutterBottom sx={{ fontSize: 14, color: 'grey.500', textAlign: 'right' }}>
				{cutlines}
			</Typography>
			<Typography gutterBottom sx={{ fontSize: 14, color: 'grey.500', textAlign: 'right' }}>
				{businessUnit}
			</Typography>
		</div>
	)
}

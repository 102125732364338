import { type ReactElement } from 'react'
import { type PutUsersReport } from '@gqlTypes'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { UserImportResultCount } from './UserImportResultCount'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Alert, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
	report?: PutUsersReport
}

export const UserImportResult = ({ report }: Props): ReactElement | null => {
	const { t } = useTranslation()
	if (report === undefined) return null

	const { isSuccess, userCounts, integrationCounts } = report

	return (
		<>
			<DialogSection>
				{isSuccess ? (
					<CheckCircleOutlineIcon sx={{ fontSize: 160 }} color="success" />
				) : (
					<ErrorOutlineIcon sx={{ fontSize: 160 }} color="error" />
				)}
				<Stack>
					<UserImportResultCount translationKey="userCounts.createdCount" count={userCounts.createdCount} />
					<UserImportResultCount translationKey="userCounts.updatedCount" count={userCounts.updatedCount} />
					<UserImportResultCount translationKey="userCounts.upToDateCount" count={userCounts.upToDateCount} />
					<UserImportResultCount
						translationKey="integrationCounts.createdCount"
						count={integrationCounts.createdCount}
					/>
					<UserImportResultCount
						translationKey="integrationCounts.updatedCount"
						count={integrationCounts.updatedCount}
					/>
					<UserImportResultCount
						translationKey="integrationCounts.upToDateCount"
						count={integrationCounts.upToDateCount}
					/>
				</Stack>
			</DialogSection>
			{!isSuccess ? (
				<DialogSection>
					<Alert severity="info">{t('modal:userImport.reportDownloaded')}</Alert>
				</DialogSection>
			) : null}
		</>
	)
}

import { joinWithPoint } from '#helpers/string.helper'
import { type GetCoMembersQuery } from '@gqlTypes'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { type HTMLAttributes, type ReactElement } from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

interface Props {
	member: GetCoMembersQuery['getCoMembers'][0]
	isSelected: boolean
	props: HTMLAttributes<HTMLLIElement>
}

export const MemberSelectorRow = ({ member, props, isSelected }: Props): ReactElement => {
	const { photoURL, publicTitle, businessUnit, fullName, initials } = member
	const subtitle = [publicTitle, businessUnit]

	return (
		<Box {...props} style={{ backgroundColor: isSelected ? '#1FFB9115' : '', display: 'block' }} component="li">
			<Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
				<Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
					<Avatar src={photoURL ?? ''} sx={{ backgroundColor: 'primary.main', width: 30, height: 30, mr: 2 }}>
						<Typography>{initials}</Typography>
					</Avatar>
					<Stack>
						<Typography sx={{ fontWeight: 'bold', color: 'primary.main' }}>{fullName}</Typography>
						<Typography>{joinWithPoint(subtitle)}</Typography>
					</Stack>
				</Stack>
				{isSelected ? (
					<CheckCircleRoundedIcon fontSize="small" sx={{ color: 'secondary.main' }} />
				) : (
					<RadioButtonUncheckedIcon fontSize="small" />
				)}
			</Stack>
		</Box>
	)
}

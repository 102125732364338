export function readQuerySnapshot(querySnapshot) {
	if (!querySnapshot.empty) {
		return querySnapshot.docs.map((doc) => readDocumentSnapshot(doc))
	}
	return []
}

export function readDocumentSnapshot(documentSnapshot) {
	if (documentSnapshot.exists) {
		return { id: documentSnapshot.id, ...documentSnapshot.data() }
	} else {
		return null
	}
}

export function readFirestoreDate(date) {
	if (date && date.seconds !== undefined && date.nanoseconds !== undefined) {
		return new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
	}
	return date
}

import { MarkerArrow } from './MarkerArrow'
import Tooltip from '@mui/material/Tooltip'
import { ConditionalWrapper } from '../ConditionalWrapper'
import theme from '@ui/theme'
import { type ReactNode, type ReactElement } from 'react'
import { valueToPercent } from '#helpers/number.helper'
import { markerSizes } from './markerSizes'

interface Props {
	icon: ReactNode
	isFilled?: boolean
	value: number
	label: string
	arrowColor?: string
	size?: keyof typeof markerSizes
}

export const Marker = ({
	icon,
	isFilled = false,
	value,
	label,
	arrowColor,
	size = 'medium'
}: Props): ReactElement | null => {
	const min = 0
	const max = 5
	const markerLeftMargin = valueToPercent(value, min, max)
	const { marker, markerIcon } = markerSizes[size]
	const color = isFilled ? 'white' : theme.palette.darkBlue.main

	const validValue = value !== 0 && value > 0
	if (!validValue) return null

	return (
		<ConditionalWrapper
			condition={label !== ''}
			wrapper={(children) => (
				<Tooltip placement="top" title={<span style={{ whiteSpace: 'pre-line' }}>{label}</span>} arrow>
					{children}
				</Tooltip>
			)}
		>
			<span
				style={{
					position: 'absolute',
					display: 'inline-block',
					bottom: 23,
					...marker,
					left: markerLeftMargin + '%',
					marginLeft: -(marker.width / 2)
				}}
			>
				<MarkerArrow color={arrowColor} isFilled={isFilled} />
				<span
					style={{
						position: 'absolute',
						display: 'inline-block',
						...markerIcon,
						borderRadius: '100%',
						overflow: 'hidden',
						color
					}}
				>
					{icon}
				</span>
			</span>
		</ConditionalWrapper>
	)
}

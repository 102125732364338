import { type CSSProperties, type ReactElement } from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

interface Props {
	name: string
	link: string
	style?: CSSProperties
}

export const File = ({ name, link }: Props): ReactElement => {
	return (
		<a target="_blank" rel="noreferrer" href={link} style={{ textDecoration: 'none' }}>
			<Paper elevation={3} sx={{ padding: 2 }}>
				<Stack direction="row" gap={2}>
					<DescriptionOutlinedIcon />
					<Typography>{name}</Typography>
				</Stack>
			</Paper>
		</a>
	)
}

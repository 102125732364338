import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { Typography } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	isSigned: boolean
	fullName: string
	date: string
	type: string
}

const handTextStyle: React.CSSProperties = {
	fontFamily: 'Caveat',
	textAlign: 'center',
	fontSize: 30
}

export const InterviewSignature = ({ isSigned, fullName, date, type }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<CardLayout title={t(`interview:signatures.${type}.title`)}>
			<InfoCardContent>
				{isSigned ? (
					<>
						<Typography color="primary" style={handTextStyle}>
							{fullName}
						</Typography>
						<Typography color="primary" style={{ ...handTextStyle, fontSize: 22 }}>
							{moment(date).format('[le] DD/MM/YYYY [à] HH[h]mm')}
						</Typography>
					</>
				) : (
					<Typography style={{ textAlign: 'center' }}>{t('interview:signatures.empty')}</Typography>
				)}
			</InfoCardContent>
		</CardLayout>
	)
}

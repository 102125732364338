import { type GraphDimension } from '@gqlTypes'
import { SelfAssessArea } from './SelfAssessArea'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	dimensions: GraphDimension[]
	size: GraphRadarSize
}

export const AllSelfAssessAreas = ({ dimensions, size }: Props): ReactElement => {
	return (
		<>
			{dimensions.map((dimension, dimensionIndex) => (
				<SelfAssessArea
					key={`self-${dimension.id}`}
					measureIndex={dimensionIndex}
					measuresCount={dimensions.length}
					valueBase10={dimension.selfScore ?? 0}
					color={dimension.color}
					size={size}
				/>
			))}
		</>
	)
}

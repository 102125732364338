import { useGetUserFunctionsQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'

export const UserFunctionFilter = (): ReactElement | null => {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useGetUserFunctionsQuery()

	if (isLoading || data?.getUserFunctions == null) return null
	const userFunctions = data.getUserFunctions.map((userFunction) => ({
		value: userFunction?.id ?? '',
		label: userFunction?.name ?? ''
	}))

	return (
		<InputFilter keyName="offerFunction" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter options={userFunctions} label={t('lists:filter.offerFunction.label')} />
		</InputFilter>
	)
}

import { type ReactElement, useState } from 'react'
import { BooleanUpdater } from '../BooleanUpdater'
import { useTranslation } from 'react-i18next'

interface Props {
	onValidate: (field: string, newValue: string | boolean) => void
}

export const ArchivedUpdateSection = ({ onValidate }: Props): ReactElement => {
	const { t } = useTranslation(['lists'])
	const [isArchived, setIsArchived] = useState('')

	return (
		<BooleanUpdater
			onValidate={onValidate}
			value={isArchived}
			setvalue={setIsArchived}
			title={t('modal:massUpdate.archived')}
			field="isArchived"
		/>
	)
}

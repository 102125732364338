import { GraphCircle } from './GraphCircle'
import { useGraphRadar } from '../hook/useGraphRadar'
import { type GraphRadarSize } from '../GraphRadarSize'
import { type ReactElement } from 'react'

interface Props {
	size: GraphRadarSize
}

export const BackgroundCircle = ({ size }: Props): ReactElement => {
	const { centerX, frameMargin, backColor } = useGraphRadar(size)
	return <GraphCircle radius={centerX - frameMargin} fillColor={backColor} size={size} />
}

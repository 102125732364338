import { type LegacyRef, type ReactNode } from 'react'
import { Divider } from '@mui/material'
import MultiSelectItem from './MultiSelectItem'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { makeStyles } from 'tss-react/mui'
import { type SelectableItem } from '@domains/tracks/details/selectableItem'
import { DndContext, useSensors, useSensor } from '@dnd-kit/core'
import { type DragEndEvent } from '@dnd-kit/core/dist/types'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { MouseSensor } from '@shared/sensors/NoDraggMouseSensor'

interface Props<T> {
	title: string
	fieldName: string
	itemsState: Record<string, T>
	register: () => LegacyRef<HTMLInputElement> | undefined
	setIsSelected: (id: string, isSelected: boolean) => void
	children?: ReactNode
	renderItem?: (selectedItem: T) => ReactNode
	getValue?: (selectedItem: T) => string
	setItemsState: React.Dispatch<React.SetStateAction<Record<string, T>>>
}

export const ControlledMultiSelectCard = <T extends SelectableItem>({
	title,
	fieldName,
	itemsState,
	setIsSelected,
	register,
	children,
	renderItem,
	getValue,
	setItemsState
}: Props<T>): JSX.Element => {
	const { classes } = useStyles()
	const sensors = useSensors(useSensor(MouseSensor))
	const items = Object.values(itemsState)
	const selectedItems = items
		.filter((item) => item.isSelected)
		.sort((a, b) => {
			if (a.selectedOrder == null) return 1
			if (b.selectedOrder == null) return -1
			return a.selectedOrder - b.selectedOrder
		})
	const unSelectedItems = items.filter((item) => !item.isSelected).sort((a, b) => a.order - b.order)
	const hasDivider = selectedItems.length > 0 && unSelectedItems.length > 0

	const onAdd = (id: string): void => {
		setIsSelected(id, true)
	}
	const onRemove = (id: string): void => {
		setIsSelected(id, false)
	}

	const handleDragEnd = (event: DragEndEvent): void => {
		const { active, over } = event
		if (over == null || active.id === over.id) return

		const ids = selectedItems.map((item) => item.id)
		const oldIndex = ids.indexOf(active.id.toString())
		const newIndex = ids.indexOf(over.id.toString())
		const newArray = arrayMove(selectedItems, oldIndex, newIndex)
		setSelectedOrders(newArray.map((item, index) => ({ id: item.id, selectedOrder: index })))
	}

	const setSelectedOrders = (selectedOrders: { id: string; selectedOrder: number }[]): void => {
		const items = { ...itemsState }
		selectedOrders.forEach(({ id, selectedOrder }) => {
			items[id] = { ...items[id], selectedOrder }
		})
		setItemsState(items)
	}

	return (
		<CardLayout title={title}>
			<InfoCardContent>
				<DndContext onDragEnd={handleDragEnd} sensors={sensors}>
					<SortableContext strategy={verticalListSortingStrategy} items={selectedItems}>
						{selectedItems.map((selectedItem, index) => (
							<div key={`${selectedItem.id}-${index}`}>
								<MultiSelectItem
									selected
									label={selectedItem.label}
									description={selectedItem.description}
									onRemove={() => {
										onRemove(selectedItem.id)
									}}
									id={selectedItem.id}
									isDraggable={selectedItems.length > 1}
								>
									{renderItem != null ? renderItem(selectedItem) : null}
								</MultiSelectItem>
								<input
									type="hidden"
									name={`${fieldName}.${index}`}
									value={getValue != null ? getValue(selectedItem) : selectedItem.id}
									ref={register}
								/>
							</div>
						))}
					</SortableContext>
				</DndContext>
				{hasDivider ? <Divider className={classes.divider} /> : null}
				{unSelectedItems.map((unSelectedItem) => (
					<MultiSelectItem
						key={`${unSelectedItem.id}-unselected`}
						selected={false}
						label={unSelectedItem.label}
						description={unSelectedItem.description}
						onAdd={() => {
							onAdd(unSelectedItem.id)
						}}
						id={unSelectedItem.id}
						isDraggable={false}
					/>
				))}
				{children}
			</InfoCardContent>
		</CardLayout>
	)
}

const useStyles = makeStyles()(() => ({
	divider: {
		margin: 10
	}
}))

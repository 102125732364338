import { Container, Grid, Stack, Typography } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'

interface Props {
	children: ReactNode
	headerLeftComponent?: ReactNode
	title: string
	toolbar?: ReactElement
}

export const Layout = ({ children, headerLeftComponent, title, toolbar }: Props): ReactElement => {
	return (
		<Container maxWidth="xl">
			<Stack
				gap={3}
				sx={{ width: { xs: 400, sm: 500, md: 574, lg: 872, xl: 1160, margin: 'auto', marginLeft: 15 }, mb: 2 }}
			>
				<Stack direction="row" gap={1} alignItems="center" justifyContent="space-between" mt={2}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={10}>
							<Stack direction="row" gap={1} spacing={2}>
								<Typography variant="h5" color="primary">
									<b>{title}</b>
								</Typography>
								{headerLeftComponent}
							</Stack>
						</Grid>

						<Grid item container xs={12} sm={2} justifyContent="end">
							{toolbar}
						</Grid>
					</Grid>
				</Stack>
			</Stack>
			{children}
		</Container>
	)
}

import { type ReactElement, useState } from 'react'
import Joyride, { type CallBackProps, STATUS } from 'react-joyride'
import { makeVar } from '@apollo/client'
import { IconButton, Tooltip } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useCustomTranslation } from '#translations/useCustomTranslation'

export const bellRef = makeVar(null)
export const checkBoxRef = makeVar(null)

export const InterviewDetailTutorial = (): ReactElement => {
	const { t } = useCustomTranslation()
	const [runTutorial, setRunTutorial] = useState(false)

	const steps = [
		{
			target: '.slider',
			content: t('tutorial:steps.interviewDetails.1'),
			disableBeacon: true
		},
		{
			target: '.box1',
			content: t('tutorial:steps.interviewDetails.2'),
			disableBeacon: true
		},
		{
			target: '.boxGlobal',
			content: t('tutorial:steps.interviewDetails.3'),
			disableBeacon: true,
			styles: { options: { width: '700px' } }
		},
		{
			target: '.question',
			content: t('tutorial:steps.interviewDetails.4'),
			disableBeacon: true
		},
		{
			target: '.draft',
			content: t('tutorial:steps.interviewDetails.5'),
			disableBeacon: true,
			placement: 'bottom-end'
		},
		{
			target: '.finalize',
			content: t('tutorial:steps.interviewDetails.6'),
			disableBeacon: true,
			placement: 'bottom-end'
		}
	]

	const handleJoyrideCallback = (data: CallBackProps): void => {
		const { status } = data

		if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
			setRunTutorial(false)
			document.body.style.overflow = 'auto'
		}

		if (status === STATUS.RUNNING) {
			document.body.style.overflow = 'hidden'
		}
	}

	return (
		<div>
			<Tooltip title={t('tutorial:tooltip')}>
				<IconButton
					onClick={() => {
						setRunTutorial(true)
					}}
					color="secondary"
				>
					<HelpOutlineIcon />
				</IconButton>
			</Tooltip>

			<Joyride
				steps={steps}
				run={runTutorial}
				continuous
				showSkipButton
				disableOverlayClose
				showProgress
				hideCloseButton
				scrollOffset={200}
				spotlightClicks={false}
				callback={handleJoyrideCallback}
				styles={{
					options: {
						primaryColor: '#0A2E56'
					}
				}}
				locale={{
					back: t('tutorial:back'),
					close: t('tutorial:close'),
					last: t('tutorial:last'),
					next: t('tutorial:next'),
					skip: t('tutorial:skip')
				}}
			/>
		</div>
	)
}

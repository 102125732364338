import {
	type GetInterviewDetailsQuery,
	type InterviewSkillsEvaluationFragment,
	type TrackSoftskill,
	useMeQuery
} from '@gqlTypes'
import { indexById, indexByProperty, indexPropertyById } from '../../../helper/array.helper'
import { useInterviewState } from '@domains/interviews/details/useInterviewState'

interface Props {
	userIntegration: GetInterviewDetailsQuery['getInterview']['userIntegration']
	interview: GetInterviewDetailsQuery['getInterview']
}

export interface InterviewSkill extends TrackSoftskill {
	id: string
	mandatory: boolean
	selfEvaluationScore: number
	selfEvaluationComment: string
	feedbackAverage: number
	imageResponse: number
	comment: string
	imageResponseDetailedLabel: string
	selfEvaluatedDetailedLabel: string
	label: string
	descriptionOrga: string
	questions: InterviewSkillsEvaluationFragment['skills'][0]['skill']['questions']
}

interface UseInterviewSkills {
	normalSkillList: InterviewSkill[]
	orgaOnlySkillList: InterviewSkill[]
	mandatorySkills: Record<string, boolean>
	skillScores: Record<string, number>
	skillComments: Record<string, string>
	subSkillScores: Record<string, number[]>
}

export const useInterviewSkills = ({ userIntegration, interview }: Props): UseInterviewSkills => {
	const { data } = useMeQuery()
	const isFeedbackAverageVisible = data?.me?.organization.isFeedbackAverageVisible ?? false
	const { isAssesseePreparation } = useInterviewState(interview)

	const imageReturnResponses = interview.assessorFeedbacks ?? []
	const indexedSelfEvaluatedDetailedLabel = interview.answersDetailedLabelsBySkill ?? {}
	const indexedSkillsRestitutions = isFeedbackAverageVisible
		? indexPropertyById(userIntegration.sharedSoftskillsRestitution, 'score')
		: {}
	const indexedImageResponses = indexByProperty(imageReturnResponses, 'softSkillId')
	const indexedAssessorSkillEvaluations = indexById(interview.skillsEvaluation.assessorSkillEvaluations)
	const selfEvaluatedSoftSkill = indexByProperty(interview.skillsEvaluation.assessedSkillEvaluations, 'softSkillId')
	const indexedAssessorOnlySkillEvaluations = indexById(interview.skillsEvaluation.assessorOnlySkillEvaluations)
	const mandatorySkills: Record<string, boolean> = {}

	const normalSkillList = interview.skillsEvaluation.skills.map(({ mandatory, skill }) => {
		mandatorySkills[skill.id] = mandatory
		const interviewSoftskill = indexedAssessorSkillEvaluations[skill.id] ?? {}
		const imageResponseObject = indexedImageResponses[skill.id] ?? {}
		const selfEvaluationScore = selfEvaluatedSoftSkill[skill.id]?.score ?? 0
		const selfEvaluationComment = selfEvaluatedSoftSkill[skill.id]?.comment ?? ''
		const feedbackAverage = indexedSkillsRestitutions[skill.id] ?? null
		const interviewScore = interviewSoftskill.score ?? 0
		const imageResponse = imageResponseObject.score
		const comment = interviewSoftskill.comment ?? imageResponseObject.message ?? ''
		const imageResponseDetailedLabel = imageResponseObject.answersDetailedLabel
		const selfEvaluatedDetailedLabel = indexedSelfEvaluatedDetailedLabel[skill.id] ?? ''
		return {
			...skill,
			selfEvaluationScore,
			feedbackAverage,
			interviewScore,
			imageResponse,
			comment,
			imageResponseDetailedLabel,
			selfEvaluatedDetailedLabel,
			selfEvaluationComment
		}
	})

	const orgaOnlySkillList = interview.skillsEvaluation.assessorOnlySkills.map((skill) => {
		mandatorySkills[skill.id] = skill.mandatory
		const interviewSoftskill = indexedAssessorOnlySkillEvaluations[skill.id] ?? {}
		const comment = interviewSoftskill.comment ?? ''
		const interviewScore = interviewSoftskill.score ?? 0
		return {
			...skill,
			selfEvaluationScore: 0,
			feedbackAverage: 0,
			imageResponse: 0,
			interviewScore,
			comment,
			selfEvaluationComment: ''
		}
	})

	const skillScores = indexPropertyById(
		normalSkillList.concat(orgaOnlySkillList),
		isAssesseePreparation ? 'selfEvaluationScore' : 'interviewScore'
	)
	const skillComments = indexPropertyById(
		normalSkillList.concat(orgaOnlySkillList),
		isAssesseePreparation ? 'selfEvaluationComment' : 'comment'
	)

	const subSkillScores: Record<string, number[]> = {}
	interview.skillsEvaluation.skills.map(({ skill }) => {
		return skill.questions.firstPersonQuestions.map((question) => {
			subSkillScores[skill.id] = subSkillScores[skill.id] ?? []
			subSkillScores[skill.id][question.id] = 0
			return question
		})
	})

	interview.skillsEvaluation.assessedSkillEvaluations.forEach((selfEvaluation) => {
		Object.entries(selfEvaluation.subskillsScore as Record<number, number>).forEach(([questionId, value]) => {
			subSkillScores[selfEvaluation.softSkillId][parseInt(questionId)] = value
		})
	})

	return {
		skillScores,
		mandatorySkills,
		normalSkillList,
		orgaOnlySkillList,
		skillComments,
		subSkillScores
	}
}

import { ProfileResume } from '@domains/resume/ProfileResume'
import i18next from 'i18next'
import { type ReactElement } from 'react'

interface Props {
	userId: string
	jobMatchingId: string
}

export const MatchingProfileResume = ({ userId, jobMatchingId }: Props): ReactElement | null => {
	return (
		<ProfileResume
			page={`${import.meta.env.VITE_WEB_PUBLIC_URL}resume/${userId}/matching/${jobMatchingId}/iframe/${
				i18next.language
			}?`}
		/>
	)
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Option {
	value: any
	label: string
}

interface Props {
	value?: string | string[]
	options: Option[]
	onChange?: (value: unknown) => void
	onChangeValue?: (value: unknown) => void
	defaultValue?: any
	label?: string
	multiple?: boolean
	limitTags?: number
	allValueLabel?: string
	width?: number | string
	isOptionsSorted?: boolean
	noneValueLabel?: string
	disabled?: boolean
}

export default function AutoCompleteFilter({
	value,
	options,
	onChange = () => {}, // used by filters and forms
	onChangeValue = () => {}, // onChange override
	defaultValue,
	label,
	multiple = false,
	limitTags = 1,
	allValueLabel = '',
	width = 200,
	isOptionsSorted = true,
	noneValueLabel = '',
	disabled = false
}: Props): JSX.Element {
	const { t } = useTranslation()
	const allValue = { label: allValueLabel ?? '', value: 'empty' }
	const noneValue = { label: noneValueLabel === '' ? t('lists:filter.none') : noneValueLabel, value: '' }

	const computeArraySelection = (values: any): Option[] => {
		const valueMap = values ?? []
		if (!Array.isArray(valueMap)) return []

		return valueMap.reduce((acc: Option[], nextValue: string) => {
			const result = options.find((option: Option) => option.value === nextValue)
			return result != null ? [...acc, result] : acc
		}, [])
	}

	const orderedOptions = isOptionsSorted ? [...options].sort((a, b) => a.label.localeCompare(b.label)) : [...options]
	if (!multiple) orderedOptions.push(noneValue)
	if (allValueLabel !== '') {
		orderedOptions.push(allValue)
		if (defaultValue === '') defaultValue = allValue
	}

	const selected = multiple
		? computeArraySelection(value)
		: (orderedOptions.find((option: Option) => option.value === value) ?? allValue)

	const onChangeSingleValue = (e: unknown, item: { value: unknown }): void => {
		onChangeValue(item.value)
		onChange(item.value)
	}

	const onChangeMultipleValue = (e: unknown, items: { value: unknown }[]): void => {
		const value = items.slice(-10).map((e) => e.value)
		onChange(value)
		onChangeValue(value)
	}

	return (
		<Autocomplete
			options={orderedOptions}
			getOptionLabel={(option) => option.label}
			style={{ width }}
			renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
			value={selected}
			onChange={multiple ? onChangeMultipleValue : onChangeSingleValue}
			defaultValue={defaultValue}
			disableClearable
			multiple={multiple}
			limitTags={limitTags}
			autoSelect
			disabled={disabled}
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.value}>
						{option.label}
					</li>
				)
			}}
		/>
	)
}

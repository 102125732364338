import Collections from '../dao/Collections'
import { firestore } from '../firebase/firebase.helper'

export default class UserOfferService {
	getUserOffersOfUser(userId, orgaId) {
		return firestore()
			.collection(Collections.UserOffer.name)
			.where('userId', '==', userId)
			.where('organizationId', '==', orgaId)
			.get()
	}
}

import { type ReactElement } from 'react'
import { type BaseTextFieldProps, TextField } from '@mui/material'
import { type Control, Controller, type FieldValues, useFormContext } from 'react-hook-form'
import { getValue } from '#helpers/object.helper'

interface Props extends BaseTextFieldProps {
	control?: Control
	name: string
	rules?: FieldValues
	label?: string
}

export const InputTextFieldForm = ({
	control,
	name,
	label = '',
	rules = {},
	...textFieldProps
}: Props): ReactElement => {
	const { errors } = useFormContext()
	const error = getValue(errors, name)
	const errorMessage = error?.message ?? ''
	const isRequiredLabel = rules?.required?.value === true && label !== ''
	const computedLabel = isRequiredLabel ? `${label} *` : label

	return (
		<Controller
			{...{ control, rules, name }}
			render={({ onChange, value }) => (
				<TextField
					name={name} // name is needed for refscroll
					error={errorMessage !== ''}
					helperText={errorMessage}
					value={value}
					onChange={(e) => {
						onChange(textFieldProps.type === 'number' ? Number(e.target.value) : e.target.value)
					}}
					{...textFieldProps}
					label={computedLabel}
				/>
			)}
		/>
	)
}

import { DefaultLayout } from '@ui/layout/DefaultLayout'
import { type ReactElement } from 'react'
import { MatchingProfileResume } from './MatchingProfileResume'
import { useParams } from 'react-router-dom'
import { type JobMatchingStatusId, useGetPublicResumeQuery } from '@gqlTypes'
import { JobMatchingStatus } from '../JobMatchingStatus'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { JobMatchingBrowserButtons } from './actions/JobMatchingBrowserButtons'

export const JobMatchingBrowser = (): ReactElement | null => {
	const { userId, jobMatchingId, trackOfferId, statusId } = useParams<{
		userId: string
		jobMatchingId: string
		trackOfferId: string
		statusId: string
	}>()
	const {
		data,
		error,
		loading: isLoading
	} = useGetPublicResumeQuery({
		variables: { trackOfferId, statusId: statusId === 'all' ? '' : statusId }
	})
	const profiles = data?.getUserOfferJobMatching ?? []
	const profile = profiles?.find(({ jobMatching }) => jobMatching?.id === jobMatchingId)

	if (isLoading) return <LocalLoader />
	if (error != null || profile?.jobMatching === undefined) return null

	const jobMatchingStatus = profile?.jobMatching?.status
	const offername = profile?.userOffer?.orgaOffer?.label ?? ''
	const firstname = profile?.userOffer?.userInfo?.firstName ?? ''
	const statusHistoryIds = profile?.jobMatching?.statusHistoryIds ?? []

	return (
		<DefaultLayout
			isSticky
			backRoute={`/offers/${trackOfferId}/matchings`}
			left={<JobMatchingStatus {...{ jobMatchingId, statusHistoryIds, trackOfferId, ...jobMatchingStatus }} />}
			middle={
				<JobMatchingBrowserButtons
					{...{
						jobMatchingId,
						profiles,
						trackOfferId,
						browserStatusId: statusId,
						jobMatchingStatusId: jobMatchingStatus.id as JobMatchingStatusId,
						offername,
						firstname
					}}
				/>
			}
		>
			<MatchingProfileResume userId={userId} jobMatchingId={jobMatchingId} />
		</DefaultLayout>
	)
}

import { Box, type SxProps } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'
import { useToggle } from '@shared/hooks/useToggle'
import { ProgressionHeader } from './ProgressionHeader'

interface Props {
	title: string
	buttonLabel: string
	legend?: ReactNode
	children?: ReactNode
	sx?: SxProps
	isDisplay?: boolean
}

export const ProgressionSection = ({
	title,
	buttonLabel,
	legend,
	sx,
	children,
	isDisplay
}: Props): ReactElement | null => {
	const [isLegendOpen, toggleDisplayLegend] = useToggle(false)
	const [isChildrenOpen, toggleDisplayChildren] = useToggle(true)

	const hasLegend = legend != null
	const isLegendDisplay = isLegendOpen && hasLegend
	const isChildrenDisplay = isChildrenOpen && children != null

	if (children == null || isDisplay === false) return null

	return (
		<Box sx={{ ...sx, mb: 2 }}>
			<ProgressionHeader
				title={title}
				buttonLabel={buttonLabel}
				hasLegend={legend != null}
				toggleDisplayLegend={toggleDisplayLegend}
				toggleDisplayChildren={toggleDisplayChildren}
				isChildrenOpen={isChildrenOpen}
			/>
			{isLegendDisplay ? (
				<Box sx={{ backgroundColor: '#E7F1FF', px: 2, justifyContent: 'center' }}>{legend}</Box>
			) : null}
			{isChildrenDisplay ? <Box mt={1}>{children}</Box> : null}
		</Box>
	)
}

import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'
import { type ReactElement } from 'react'

export const GlobalLoaderFeedback = (): ReactElement | null => {
	const isLoading = useSelector(({ loader }: { loader: { load: boolean } }) => loader.load)
	if (!isLoading) return null

	return <LinearProgress variant="query" color="secondary" />
}

import { useEffect } from 'react'
import { type DeepMap, type FieldError } from 'react-hook-form'

interface ObjectRef {
	focus: string
	value: string
	name: string
	scrollIntoView: (options?: boolean | ScrollIntoViewOptions) => void
}

export const useScrollToError = <T>({ errors }: { errors: DeepMap<T, FieldError> }): void => {
	const hasProperty = (nestedErrors: object, prop: string): boolean => {
		return Object.prototype.hasOwnProperty.call(nestedErrors, prop)
	}

	function findObjectRef(nestedErrors?: DeepMap<T, FieldError> | ObjectRef): ObjectRef | null {
		if (
			nestedErrors != null &&
			typeof nestedErrors === 'object' &&
			'focus' in nestedErrors &&
			'value' in nestedErrors &&
			'name' in nestedErrors
		) {
			return nestedErrors
		}

		for (const key in nestedErrors) {
			if (hasProperty(nestedErrors, key)) {
				const nestedError = nestedErrors[key]

				if (nestedError != null && typeof nestedError === 'object') {
					const result = findObjectRef(nestedError)
					if (result != null) {
						return result
					}
				}
			}
		}

		return null
	}

	const scrollToError = (): void => {
		const hasError = Object.keys(errors).length > 0
		if (!hasError) return

		const foundValue = findObjectRef(errors)
		if (foundValue === null) throw new Error('No error found')

		const scrollElement = document.getElementsByName(foundValue.name)[0]
		scrollElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

	useEffect(() => {
		scrollToError()
	}, [errors])
}

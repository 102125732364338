import { type ApolloError } from '@apollo/client'
import { ErrorHandler } from './error.helper'

export type GqlFormErrors = Record<string, string>

export const handleAsyncSubmit = <N, T>(asyncToRun: (params?: T) => Promise<N>, params?: T): void => {
	;(async (params?: T) => {
		await asyncToRun(params)
	})(params).catch(ErrorHandler)
}

export const handleGQLErrors = (error: ApolloError): GqlFormErrors => {
	const gqlFormErrors: GqlFormErrors = {}
	error.graphQLErrors.forEach((err) => {
		const field = typeof err.extensions.field === 'string' ? err.extensions.field : ''
		gqlFormErrors[field] = err.message
	})
	return gqlFormErrors
}

import { useTranslation } from 'react-i18next'
import { SharedDetails } from './SharedDetails'
import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'
import { Stack } from '@mui/material'

interface Props {
	details: {
		id?: string
		label: string
		score: number
	}[]
}

export const SharedDetailsList = ({ details }: Props): ReactElement | null => {
	const { t } = useTranslation()
	if (details.length === 0) return null
	return (
		<Stack spacing={1}>
			<Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 1 }}>
				{t('sharedReturnTab:sharedDetails.title')}
			</Typography>
			{details.map((behaviorsAverage) => (
				<SharedDetails key={behaviorsAverage.id} text={behaviorsAverage.label} score={behaviorsAverage.score} />
			))}
		</Stack>
	)
}

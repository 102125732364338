import { Fragment, type ReactElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { FormSlider } from '@ui/core/FormSlider'
import { type InterviewSkillsEvaluationFragment } from '@gqlTypes'

interface Props {
	questions: InterviewSkillsEvaluationFragment['skills'][0]['skill']['questions']
	skillId: string
	isReadOnly: boolean
	isAssesseePreparation: boolean
}

export const SkillEvaluationQuestion = ({
	questions,
	skillId,
	isReadOnly,
	isAssesseePreparation
}: Props): ReactElement | null => {
	if (questions == null) return null

	const questionsViewPoint = isAssesseePreparation ? questions.firstPersonQuestions : questions.thirdPersonQuestions

	return (
		<Grid
			container
			rowSpacing={2}
			bgcolor="#F3F8FF"
			borderRadius={2}
			alignItems={'center'}
			paddingBottom={1}
			marginTop={0}
		>
			{questionsViewPoint.map((question) => (
				<Fragment key={`subSkillScores.${questions.id}.${question.id}`}>
					<Grid item md={6} xs={6} padding={2} paddingY={1}>
						<Typography sx={{ paddingX: 0, fontWeight: 'bold' }}>{question.value}</Typography>
					</Grid>
					<Grid key={`subSkillScores.${skillId}.${question.id}`} item md={6} xs={6} padding={2} paddingY={1}>
						<Box>
							<FormSlider
								isReadOnly={isReadOnly}
								subLabels={questions.behaviorLabels}
								name={`subSkillScores.${skillId}.${question.id}`}
								step={1}
								min={0}
								max={5}
								sx={{ paddingTop: 0 }}
								color="primary"
							/>
						</Box>
					</Grid>
				</Fragment>
			))}
		</Grid>
	)
}

import { deepCopyFunction } from '../../../helper/massActions.helper'
import updateQueryContext from '../../../helper/updateQueryContext.helper'

export const SET_QUERYCONTEXT = 'SET_QUERYCONTEXT'
export const INITIALIZE_QUERYCONTEXT = 'INITIALIZE_QUERYCONTEXT'
export const SET_QUERY = 'SET_QUERY'

export function setQueryContext(context, response) {
	const contextCopy = deepCopyFunction(context)
	const updatedContext = updateQueryContext(contextCopy, response)
	return {
		type: SET_QUERYCONTEXT,
		payload: updatedContext
	}
}

export function initializeQueryContext() {
	return {
		type: INITIALIZE_QUERYCONTEXT
	}
}

export function setQuery(query) {
	return {
		type: SET_QUERY,
		payload: query
	}
}

import { type CSSProperties, type ReactElement } from 'react'
import { ExternalLink } from './ExternalLink'
import { InternalLink } from './InternalLink'

interface Props {
	children?: JSX.Element
	url: string
	isExternal?: boolean
	style?: CSSProperties
}

export const Link = ({ children, url, style, isExternal = false }: Props): ReactElement => {
	return isExternal ? (
		<ExternalLink url={url} style={style}>
			{children}
		</ExternalLink>
	) : (
		<InternalLink url={url} style={style}>
			{children}
		</InternalLink>
	)
}

import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import { GraphRadar } from '../..'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { GraphRadarLegend } from '../legend/GraphRadarLegend'
import { type ReactElement } from 'react'
import { type GraphDimension } from '@gqlTypes'

interface Props {
	dimensions: GraphDimension[]
}

export const GraphRadarCard = ({ dimensions }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { classes } = useStyles()

	const isEmpty =
		dimensions.reduce((sum, { selfScore, feedbackScore }) => sum + (selfScore ?? 0) + (feedbackScore ?? 0), 0) === 0

	if (dimensions == null || isEmpty) return null

	return (
		<CardLayout title={t('members:members.graph.title')}>
			<InfoCardContent className={classes.container}>
				<GraphRadar dimensions={dimensions} />
				<GraphRadarLegend dimensions={dimensions} />
			</InfoCardContent>
		</CardLayout>
	)
}

const useStyles = makeStyles()(() => ({
	card: {
		margin: 15,
		paddingBottom: 10
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	graph: {
		borderWidth: 5,
		borderColor: 'black',
		backgroundColor: 'red'
	}
}))

import { useGetCareerMatchingStatusesQuery, useUpdateCarrerMatchingStatusMutation } from '@gqlTypes'
import { IconButton, MenuItem, Popover, Stack, Typography } from '@mui/material'
import { useState, type ReactElement } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { LocalLoader } from '@shared/components/layout/LocalLoader'

interface Props {
	careerMatchingId: string
	statusId: string
}

export const CareerMatchingStatusUpdator = ({ careerMatchingId, statusId }: Props): ReactElement => {
	const { data, loading } = useGetCareerMatchingStatusesQuery()
	const [updateCareerMatching] = useUpdateCarrerMatchingStatusMutation()
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(anchorEl != null ? null : event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const statuses = data?.getCareerMatchingStatuses ?? []
	return (
		<>
			<IconButton aria-label="close" onClick={handleClick}>
				<MoreVertIcon />
			</IconButton>
			<Popover
				id={open ? 'simple-popover' : undefined}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<Stack sx={{ p: 2 }} gap={2}>
					<LocalLoader load={loading}>
						<>
							{statuses.map((status) => (
								<MenuItem
									key={status.id}
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										void updateCareerMatching({ variables: { careerMatchingId, statusId: status.id } })
									}}
								>
									<Typography sx={{ fontWeight: status.id === statusId ? 'bold' : null }}>{status.label}</Typography>
								</MenuItem>
							))}
						</>
					</LocalLoader>
				</Stack>
			</Popover>
		</>
	)
}

import Button from '@mui/material/Button'
import { LocalLoader } from '../layout/LocalLoader'

interface Props {
	disabled: boolean
	isSaving: boolean
	title: string
	onClick: () => void
}

export default function SaveButton({ disabled, isSaving, title, onClick }: Props): JSX.Element {
	return (
		<Button variant="contained" color="primary" onClick={onClick} disabled={disabled}>
			<LocalLoader load={isSaving} white size={24.5}>
				{title}
			</LocalLoader>
		</Button>
	)
}

import { firestore } from '../firebase/firebase.helper'
import Collections from '../dao/Collections'

export default class DiscussionUserService {
	/* TODO switch to functions for more field control ? */
	updateDiscussionUser(discussionUserId, payload) {
		return firestore().collection(Collections.DiscussionUser.name).doc(discussionUserId).update(payload)
	}

	getDiscussionUser(discussionUserId) {
		return firestore().collection(Collections.DiscussionUser.name).doc(discussionUserId).get()
	}

	hasDiscussionUser(recipientId, userId) {
		return firestore()
			.collection(Collections.DiscussionUser.name)
			.where('recipientId', '==', recipientId)
			.where('userId', '==', userId)
			.limit(1)
			.get()
	}
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import InputSelectTextField from '@domains/interviews/create/InputSelectTextField'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { type Control, type FieldErrors, type FieldValues } from 'react-hook-form'

interface Props {
	control: Control
	errors?: FieldErrors
	items: any[]
	rules?: FieldValues
	name: string
	label?: string
	isSelfKey?: boolean
	defaultValue?: any
	displayKey?: string
	isLoading?: boolean
}

export default function InputSelectField({
	control,
	errors = {},
	items,
	rules = {},
	name,
	label,
	isSelfKey,
	defaultValue,
	displayKey,
	isLoading = false
}: Props): ReactElement {
	if (isLoading) return <LocalLoader />

	return (
		<InputSelectTextField
			name={name}
			items={items}
			label={label}
			control={control}
			rules={rules}
			errors={errors}
			isSelfKey={isSelfKey}
			defaultValue={defaultValue}
			displayKey={displayKey}
			required={undefined}
		/>
	)
}

import { firestore } from '../firebase/firebase.helper'
import Collections from '../dao/Collections'

export default class DiscussionService {
	getDiscussionMessages(discussionId) {
		return firestore()
			.collection(Collections.Discussions.name)
			.doc(discussionId)
			.collection(Collections.Discussions.Messages.name)
			.orderBy('creationDate')
	}
}

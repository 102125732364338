import { SET_USERB_CONTEXT } from '../actions/userB.action'

const initialState = null

const userB = function (state = initialState, action) {
	switch (action.type) {
		case SET_USERB_CONTEXT: {
			return action.payload
		}
		default: {
			return state
		}
	}
}

export default userB

import { generateRoute, ROUTE_DISCUSSION_DETAIL } from '../../../router/routes'
import Typography from '@mui/material/Typography'
import { CandidateInfoCell } from '@shared/components/dataset/CandidateInfoCell'
import { useTranslation } from 'react-i18next'
import ForumIcon from '@mui/icons-material/Forum'
import ChatIcon from '@mui/icons-material/Chat'
import theme from '@ui/theme'
import { ToggleIcon } from '@shared/components/toggle/ToggleIcon'
import FlagIcon from '@mui/icons-material/Flag'
import ArchiveIcon from '@mui/icons-material/Archive'
import useDataUpdater from '../../../features/lists/hooks/useDataUpdater'
import NavButtonIcon from '@shared/components/buttons/NavButtonIcon'
import { type UserRef } from '@gqlTypes'

interface Cell {
	label: string
	width?: number | string
	align?: string
	renderCell: (param: Conversation) => JSX.Element
	isDisplay?: boolean
}

interface Conversation {
	id: string
	recipientRef: UserRef
	userRef: UserRef
	lastMessage: string
	read: boolean
	flagged: boolean
	archived: boolean
}

export function useDiscussionsColumns(query: (discussionUserId: string, payload: unknown) => void): () => Cell[] {
	const { t } = useTranslation(['translation', 'userOfferStatus', 'discussions'])
	const updateData = useDataUpdater(query, 'DiscussionUser')

	return (): Cell[] => {
		return [
			{
				label: '',
				width: 78,
				align: 'center',
				renderCell: (conversation) => (
					<NavButtonIcon
						route={generateRoute(ROUTE_DISCUSSION_DETAIL, { discussionUserId: conversation.id })}
						icon={<ForumIcon />}
						tooltipTitle={t('discussions:columns.discussionLink')}
					/>
				)
			},
			{
				label: t('discussions:columns.recipient'),
				width: 300,
				align: 'center',
				renderCell: (conversation) => (
					<CandidateInfoCell
						firstname={conversation.recipientRef.firstName ?? ''}
						lastname={conversation.recipientRef.lastName ?? ''}
						phoneNumber={conversation.recipientRef.mobile ?? ''}
					/>
				)
			},
			{
				label: t('discussions:columns.lastMessage'),
				width: '100%',
				renderCell: (conversation) => <Typography>{conversation.lastMessage}</Typography>
			},
			{
				label: t('discussions:columns.interlocutor'),
				width: 200,
				align: 'center',
				renderCell: (conversation) => <Typography>{conversation.userRef.displayName}</Typography>
			},
			{
				label: t('discussions:columns.unread'),
				width: 78,
				renderCell: (conversation) => {
					return (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column'
							}}
						>
							<ChatIcon
								style={{
									color: !conversation.read ? theme.palette.darkBlue.light : theme.palette.grays.gray2
								}}
							/>
						</div>
					)
				}
			},
			{
				label: t('discussions:columns.flagged'),
				width: 78,
				align: 'center',
				renderCell: (conversation) => {
					return (
						<ToggleIcon
							field="flagged"
							id={conversation.id}
							value={conversation.flagged}
							onToggle={updateData}
							checkIcon={<FlagIcon style={{ color: theme.palette.error.main }} />}
							icon={<FlagIcon style={{ color: theme.palette.grays.gray2 }} />}
							tooltip={t('discussions:columns.tooltip.flagged')}
						/>
					)
				}
			},
			{
				label: t('discussions:columns.archived'),
				width: 78,
				align: 'center',
				renderCell: (conversation) => {
					return (
						<ToggleIcon
							field="archived"
							id={conversation.id}
							value={conversation.archived}
							onToggle={updateData}
							checkIcon={<ArchiveIcon style={{ color: theme.palette.warning.main }} />}
							icon={<ArchiveIcon style={{ color: theme.palette.grays.gray2 }} />}
							tooltip={t('discussions:columns.tooltip.archived')}
						/>
					)
				}
			}
		]
	}
}

import { TextField } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	sendCode: (code: string) => void
}

export const ValidationCodeInput = ({ sendCode }: Props): ReactElement => {
	return (
		<TextField
			autoFocus
			variant="outlined"
			size="medium"
			placeholder="______"
			style={{ letterSpacing: 10 }}
			onChange={(e) => {
				sendCode(e.target.value)
			}}
			InputProps={{
				style: {
					fontSize: 40,
					width: 222
				}
			}}
			inputProps={{
				maxLength: 6
			}}
		/>
	)
}

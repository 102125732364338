import { Tab, Tabs } from '@mui/material'
import theme from '@ui/theme'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import { type TabContent } from '../types/TabContent'

interface Props {
	tabs: TabContent[]
}

export const NavigationTabs = ({ tabs }: Props): ReactElement => {
	const { t } = useTranslation()
	const location = useLocation()
	const currentTabIndex = tabs.findIndex((tab) => tab.route.path === location.pathname)

	return (
		<Tabs value={currentTabIndex} centered indicatorColor="secondary">
			{tabs.map((tab) => (
				<Tab
					iconPosition="start"
					key={tab.route.path}
					component={Link}
					to={tab.route.path}
					icon={tab.icon}
					label={String(t(tab.translationKey))}
					sx={{ color: theme.palette.primary.main }}
				/>
			))}
		</Tabs>
	)
}

import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { type InterviewSkill } from '@features/details/hooks/useInterviewSkills'
import { ProgressionChip } from '@ui/progress/ProgressionChip'

interface Props {
	skillList: InterviewSkill[]
}

export const AssessedSkillProgressionChip = ({ skillList }: Props): ReactElement | null => {
	const { watch } = useFormContext()
	const skillScores = watch('skillScores')

	const filledSkill = skillList.filter((skill) => skillScores[skill.id] > 0)
	const progression = (filledSkill.length / skillList.length) * 100
	return <ProgressionChip progression={progression} />
}

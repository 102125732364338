import './polyfills'
import './index.css'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { initFirebase } from './firebase/firebase.helper'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
if (container === null) throw new Error('Root container missing in index.html')
const root = createRoot(container)

initFirebase()
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import { type ReactElement } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useToggle } from '@shared/hooks/useToggle'
import { useTranslation } from 'react-i18next'

interface Props {
	isRequired?: boolean
	isWhite?: boolean
	label: string
	children: React.ReactNode
	actions?: React.ReactNode
	description?: string
	isDefaultOpen?: boolean
	hasDetails?: boolean
}

export const SliderTitle = ({
	isRequired = false,
	isWhite = false,
	label,
	children,
	actions,
	description = '',
	isDefaultOpen = false,
	hasDetails = false
}: Props): ReactElement => {
	const [isDescriptionOpen, toogleIsDescriptionOpen] = useToggle()
	const [isOpen, toogleIsOpen] = useToggle(isDefaultOpen)
	const { t } = useTranslation()
	const color = isWhite ? '#FFF' : 'grays.gray4'
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row" spacing={2}>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: '20px',
							color
						}}
					>
						{label} {isRequired ? <span style={{ color: '#FB881F' }}>*</span> : null}
					</Typography>
					{actions}
				</Stack>
				<Stack className="no-print" direction="row" spacing={1}>
					{hasDetails ? (
						<Button endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} onClick={toogleIsOpen}>
							{t(`sliders:open.${isOpen}`)}
						</Button>
					) : null}
					<Button endIcon={<HelpOutlineIcon />} onClick={toogleIsDescriptionOpen}>
						{t('sliders:expectations')}
					</Button>
				</Stack>
			</Stack>
			{isDescriptionOpen ? (
				<Typography
					sx={{
						p: 2,
						color: 'grays.gray4',
						backgroundColor: 'backgroundBlue.main',
						borderRadius: '4px',
						whiteSpace: 'pre-line'
					}}
				>
					{description}
				</Typography>
			) : null}
			{hasDetails ? <Box sx={{ display: isOpen ? 'block' : 'none' }}>{children}</Box> : null}
		</>
	)
}

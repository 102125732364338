import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

interface Props {
	customFields: string[]
}

export const InterviewCustomFieldList = ({ customFields }: Props): ReactElement => {
	return (
		<>
			{customFields.map((customField, index) => (
				<Stack direction="row" gap={1} key={index} alignItems="center">
					<InfoIcon sx={{ width: 18 }} />
					<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>{customField}</Typography>
				</Stack>
			))}
		</>
	)
}

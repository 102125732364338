import { type ReactElement } from 'react'
import { filterOperation } from '@ui/filter/FilterForm'
import { useTranslation } from 'react-i18next'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { type ApolloQueryResult } from '@apollo/client'
import { TrackOfferFilter } from '@features/lists/components/Filters/TrackOfferFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { RadioFilter } from '@features/lists/components/Filters/RadioFilter'
import { useRole } from '@shared/hooks/useRole'
import {
	type FilterInput,
	type GetOrganizationFeedbackQuery,
	TrackType,
	type UserOrganizationFragment,
	OrganizationType,
	useMeQuery
} from '@gqlTypes'
import { ORGANIZATION_RETURN_EXPORT } from '../queries/OrganizationReturnExport'
import BusinessUnitFilter from '@features/lists/components/Filters/BusinessUnitFilter'
import ManagersFilter from '@features/lists/components/Filters/ManagersFilter'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { useFilters } from '@shared/hooks/useFilters'
import { FiltersContent } from '@features/lists/components/FiltersContent'

interface Props {
	refetch: (variables: { filters: FilterInput[] }) => Promise<ApolloQueryResult<GetOrganizationFeedbackQuery>>
	requestCount: number
	organization: UserOrganizationFragment
	defaultFilters?: FilterInput[]
}

export const OrganizationReturnsFilterBar = ({ organization, refetch, defaultFilters }: Props): ReactElement => {
	const { t } = useTranslation()
	const { hasOfferAccess, hasPositionAccess } = useRole()
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const isManager = data?.me?.isManager ?? false
	const isRestrictedAccess = data?.me?.isRestrictedAccess ?? false
	const hasRestrictedManager = isManager && isRestrictedAccess

	const isSchool = organization.type === OrganizationType.School
	const tracksRadio = []
	if (hasOfferAccess) tracksRadio.push({ value: TrackType.Offer, label: t('organizationFeedback:offers') })
	if (hasPositionAccess) tracksRadio.push({ value: TrackType.Position, label: t('organizationFeedback:positions') })
	const { filters } = useFilters()
	const trackTypeFilterValue = filters.find((filter) => filter.key === 'trackType')?.value

	return (
		<LeftBar>
			<Filters refetch={refetch} defaultFilters={defaultFilters}>
				<FiltersContent>
					<RadioFilter keyName="trackType" operation={filterOperation.EQUAL} radios={tracksRadio} />
					{trackTypeFilterValue === TrackType.Offer ? (
						<TrackOfferFilter keyName="trackId" isMultiple />
					) : (
						<TrackPositionFilter keyName="trackId" isMultiple />
					)}
					{!hasRestrictedManager ? <ManagersFilter keyName="managerId" /> : null}
					<ManagersFilter keyName="externalAssessorId" keyLabel="tutors" />
					<BusinessUnitFilter isSchool={isSchool} />
					<TextFilter
						type="date"
						keyName="creationDate"
						operation={filterOperation.GREATER_THAN_EQUAL}
						label={t('organizationFeedback:startDate')}
						InputLabelProps={{ shrink: true }}
					/>
					<TextFilter
						type="date"
						keyName="creationDate"
						operation={filterOperation.LOWER_THAN_EQUAL}
						label={t('organizationFeedback:endDate')}
						InputLabelProps={{ shrink: true }}
					/>
				</FiltersContent>
			</Filters>
			<ExportGQL isNotList QUERY={ORGANIZATION_RETURN_EXPORT} itemCount={1} />
		</LeftBar>
	)
}

import { Link, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { blue, orange, red } from '@mui/material/colors'
import clsx from 'clsx'
import { type ReactElement } from 'react'

const useStyles = makeStyles()(() => ({
	container: {
		margin: 10,
		padding: 10,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	error: {
		color: red[900],
		backgroundColor: red[100]
	},
	info: {
		color: blue[900],
		backgroundColor: blue[100]
	},
	warning: {
		color: orange[900],
		backgroundColor: orange[100]
	}
}))

interface Props {
	message: string
	level?: 'error' | 'info' | 'warning'
	hidden?: boolean
	link?: { href: string; label: string }
	dataTestId?: string
}

export const Alert = ({ message, level = 'info', hidden = false, link, dataTestId }: Props): ReactElement | null => {
	const { classes } = useStyles()

	if (hidden) return null

	return (
		<Paper className={clsx(classes.container, classes[level])} elevation={2} data-testid={dataTestId}>
			<div>{message}</div>
			{link != null && (
				<div>
					<Link href={link.href} target="_blank">
						{link.label}
					</Link>
				</div>
			)}
		</Paper>
	)
}

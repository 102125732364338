import { type ReactElement } from 'react'
import { Box, type BoxProps } from '@mui/material'

interface Props extends BoxProps {
	width?: number
}

export const Logo = ({ width, ...boxProps }: Props): ReactElement => {
	return (
		<Box {...boxProps}>
			<img
				width={width}
				style={{
					height: '100%',
					display: 'inline-block'
				}}
				alt="emage-me logo"
				src="/assets/img/logo/logo.svg"
			/>
		</Box>
	)
}

import { useGetOfferDetailsQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { ChipSelector } from '@shared/components/forms/fields/ChipSelector'
import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

export const StartMonthsSelect = (): ReactElement | null => {
	const { offerId = 'new' } = useParams<{ offerId: string }>()
	const { data, loading } = useGetOfferDetailsQuery({ variables: { offerId } })

	if (data == null) return null
	const items = data.getOfferDetails.availableMonths
	return (
		<TableLayoutRow translationKey="offers:details.main.startMonths">
			<ChipSelector name="startMonthIds" items={items} minSelectableItem={1} isLoading={loading} />
		</TableLayoutRow>
	)
}

import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadImageField } from './UploadImageField'
import { OrganizationName } from './OrganizationName'
import { OrganizationWebsite } from './OrganizationWebsite'
import { OrganizationSiret } from './OrganizationSiret'

interface Props {
	organizationType: string
}

export const OrganizationInvitation = ({ organizationType }: Props): ReactElement => {
	const { t } = useTranslation()
	return (
		<Stack gap={2}>
			<Typography variant="h6" pb={1} color="primary">
				<b>{t(`organizationCreation:organization.${organizationType}.title`)}</b>
			</Typography>
			<OrganizationName {...{ organizationType }} />
			<OrganizationWebsite {...{ organizationType }} />
			<OrganizationSiret {...{ organizationType }} />
			<UploadImageField />
		</Stack>
	)
}

import { type ReactElement, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import { teal } from '@mui/material/colors'
import { Badge, Box, Divider, IconButton, Popover, Button } from '@mui/material'
import { firebaseAuth } from '../../firebase/firebase.helper'
import { useTranslation } from 'react-i18next'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { OrganizationSwitcher } from './OrganizationSwitcher'
import { type OrganizationListItem } from '@gqlTypes'
import { ROUTE_LOGOUT } from '../../router/routes'
import { Link } from 'react-router-dom'

interface Props {
	organizationList: OrganizationListItem[]
	currentOrganizationId: string
}

const UserToolbar = ({ organizationList, currentOrganizationId }: Props): ReactElement => {
	const { t } = useTranslation()
	const currentUser = firebaseAuth().currentUser
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = (): void => {
		setAnchorEl(null)
	}
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	return (
		<Box sx={{ ml: 1, mb: 2 }}>
			<IconButton aria-describedby={id} onClick={handleClick}>
				<Badge
					sx={{ '& .MuiBadge-badge': { height: '14px', minWidth: '10px', padding: '2px' } }}
					color="primary"
					badgeContent={<MoreHoriz sx={{ width: 10, height: 10 }} />}
					overlap="circular"
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				>
					<Avatar sx={{ backgroundColor: teal[200] }}>
						{currentUser?.displayName != null ? currentUser.displayName.substring(0, 1).toUpperCase() : 'U'}
					</Avatar>
				</Badge>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{ borderRadius: 2, ml: 1 }}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				{organizationList.length > 1 ? (
					<OrganizationSwitcher
						organizationList={organizationList}
						currentOrganizationId={currentOrganizationId}
						handleClose={handleClose}
					/>
				) : (
					<Box sx={{ p: 2 }}>
						<b>Organization: {organizationList[0]?.organizationName}</b>
					</Box>
				)}
				<Divider />
				<Button component={Link} color="error" to={ROUTE_LOGOUT} sx={{ m: 1 }}>
					{t('login.disconnect')}
				</Button>
			</Popover>
		</Box>
	)
}

export default UserToolbar

import { type ReactElement, type ReactNode, useEffect } from 'react'
import { firebaseAuth } from '../../firebase/firebase.helper'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { isPublicPath } from '../../helper/navigation.helper'
import { useErrorHandler } from 'react-error-boundary'
import { LoginPage } from './LoginPage'
import { UnauthorizedComponent } from './components/UnauthorizedComponent'
import Bugsnag from '@bugsnag/js'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { enUS, frFR, LocalizationProvider } from '@mui/x-date-pickers'
import { MainToolbar } from '../../domains/mainToolbar/MainToolbar'
import { AuthStates, useAuth } from './useAuth'
import { useSwitchOrganizationMutation } from '@gqlTypes'
import { OnlyMobile } from './components/OnlyMobile'

interface Props {
	children: ReactNode
}

export const Auth = ({ children }: Props): ReactElement => {
	const dispatch = useDispatch()
	const location = useLocation()
	const history = useHistory()
	const isPublicRoute = isPublicPath(location.pathname)
	const errorHandler = useErrorHandler()
	const { me, language, authState, loadUserContext, setAuthState, setLoadUserContext } = useAuth()
	const isLoginPage = location.pathname.includes('login')
	const [switchOrganization] = useSwitchOrganizationMutation()

	useEffect(() => {
		const unsubscribe = firebaseAuth().onAuthStateChanged(async function (user) {
			if (user != null) {
				setLoadUserContext(true)
				const organizationId = location.pathname.split('/')[2] ?? ''
				if (isLoginPage && organizationId !== '') await switchOrganization({ variables: { organizationId } })

				setAuthState(AuthStates.firebaseAuthenticated)
				Bugsnag.leaveBreadcrumb('Google auth success')
				Bugsnag.setUser(firebaseAuth().currentUser?.uid)
				Bugsnag.addMetadata(
					'Auth',
					'email',
					firebaseAuth().currentUser?.email ?? firebaseAuth().currentUser?.providerData[0]?.email
				)

				// User is signed in.
				await me()
				if (isLoginPage && organizationId !== '') history.push('/')
			} else {
				// No user is signed in.
				// clear all context
				setAuthState(AuthStates.anonymous)
				setLoadUserContext(false)
			}
		})
		return () => {
			if (unsubscribe != null) {
				unsubscribe()
			}
		}
	}, [dispatch, errorHandler])

	if (!isPublicRoute) {
		if (loadUserContext) {
			return (
				<>
					<MainToolbar />
					<LocalLoader />
				</>
			)
		} else if (authState === AuthStates.anonymous || isLoginPage) {
			return <LoginPage />
		} else if (authState === AuthStates.emagemeAuthenticated) {
			// useB authenticated, let the component handle the return
		} else if (authState === AuthStates.firebaseAuthenticated) {
			return <UnauthorizedComponent />
		} else if (authState === AuthStates.onlyMobile) {
			return <OnlyMobile />
		}
	}

	const localText = language === 'fr' ? frFR : enUS

	return (
		<LocalizationProvider
			dateAdapter={AdapterLuxon}
			adapterLocale={language}
			localeText={localText.components.MuiLocalizationProvider.defaultProps.localeText}
		>
			{children}
		</LocalizationProvider>
	)
}

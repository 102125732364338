import { Link } from 'react-router-dom'
import { type ReactElement } from 'react'
import Button from '@mui/material/Button'

interface Props {
	route: string
	label: string
	state?: Record<string, unknown>
}

export const RouterLink = ({ route, label, state = {} }: Props): ReactElement => {
	return (
		<Button component={Link} to={{ pathname: route, state }} fullWidth variant="text">
			{label}
		</Button>
	)
}

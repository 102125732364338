import { LoginLayout } from './LoginLayout'
import { ForgottenPasswordForm } from './components/ForgottenPasswordForm'
import { type ReactElement } from 'react'

export const RequestPasswordPage = (): ReactElement => {
	return (
		<LoginLayout>
			<ForgottenPasswordForm />
		</LoginLayout>
	)
}

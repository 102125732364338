import { type CSSProperties, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

interface Props {
	children?: JSX.Element
	url: string
	style?: CSSProperties
}

export const InternalLink = ({ children, url, style }: Props): ReactElement => {
	return (
		<Link style={{ textDecoration: 'none', ...style }} to={url}>
			{children}
		</Link>
	)
}

import { OrganizationType, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { useTranslation } from 'react-i18next'

interface Props {
	label?: string
	allValueLabel?: string
	isBasedOnOrgaType?: boolean
}

export default function LocationFilter({
	label = '',
	allValueLabel = '',
	isBasedOnOrgaType = false
}: Props): JSX.Element {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return <></>
	const location = data.me.location
	const organizationType = data.me.organization.type
	const availableLocations = data.me.organization.locations.map((loc: string) => ({ value: loc, label: loc }))
	const hasRestriction = location != null && location !== ''

	if (hasRestriction) return <></>
	const key = organizationType === OrganizationType.School ? 'campus' : 'site'
	const normalLabel = isBasedOnOrgaType ? t(`lists:filter.${key}.label`) : label
	const allLabel = isBasedOnOrgaType ? t(`lists:filter.${key}.allLabel`) : allValueLabel

	return (
		<InputFilter keyName="location" operation={filterOperation.EQUAL}>
			<AutoCompleteFilter options={availableLocations} label={normalLabel} allValueLabel={allLabel} width="100%" />
		</InputFilter>
	)
}

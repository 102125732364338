import { ProgressionCard } from '@domains/interviews/Progression/ProgressionCard'
import { ProgressionSection } from '@domains/interviews/Progression/ProgressionSection'
import { ProgressionLegend } from '@domains/interviews/Progression/ProgressionLegend'
import { InterviewCard } from '@domains/interviews/list/InterviewCard'
import { useGetMyInterviewsQuery } from '@gqlTypes'
import { Container, Divider, Stack } from '@mui/material'
import { GraphRadar } from '@shared/components/GraphRadar'
import { Legend } from '@shared/components/GraphRadar/components/Legend'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { InterviewStepList } from '@domains/interviews/list/step/InterviewStepList'
import { GraphRadarSize } from '@shared/components/GraphRadar/GraphRadarSize'

export const MyInterviewsComponent = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading: isLoading } = useGetMyInterviewsQuery({ fetchPolicy: 'cache-and-network' })

	if (data == null) return null
	if (isLoading) return <LocalLoader />

	const { interviewsToDo, interviewsToSign, finishedInterviews, progressions, skills } = data.getMyInterviews

	return (
		<Container maxWidth="xl">
			<Stack spacing={4} sx={{ mt: 5 }}>
				<ProgressionSection
					title={t('interview:myInterviews.section.todo.label')}
					buttonLabel={t('interview:progression.radar.legend.display')}
					isDisplay={interviewsToDo.length > 0}
				>
					<Stack direction="row" alignItems="start" flexWrap="wrap" gap={3}>
						{interviewsToDo.map((interview) => (
							<InterviewCard key={interview.id} interview={interview}>
								<InterviewStepList id={interview.id ?? ''} {...interview} />
							</InterviewCard>
						))}
					</Stack>
				</ProgressionSection>

				<ProgressionSection
					title={t('interview:myInterviews.section.toSign.label')}
					buttonLabel={t('interview:progression.radar.legend.display')}
					isDisplay={interviewsToSign.length > 0}
				>
					<Stack direction="row" alignItems="start" flexWrap="wrap" gap={3}>
						{interviewsToSign.map((interview) => (
							<InterviewCard key={interview.id} interview={interview} />
						))}
					</Stack>
				</ProgressionSection>

				<ProgressionSection
					title={t('interview:myInterviews.section.history.label')}
					buttonLabel={t('interview:progression.radar.legend.display')}
					legend={<Legend skills={skills} />}
					isDisplay={finishedInterviews.length > 0}
				>
					<Stack direction="row" alignItems="start" flexWrap="wrap" gap={3}>
						{finishedInterviews.map((interview) => (
							<InterviewCard key={interview.id} interview={interview}>
								<>
									<Divider />
									<GraphRadar dimensions={interview.graphDimensions} size={GraphRadarSize.small} />
								</>
							</InterviewCard>
						))}
					</Stack>
				</ProgressionSection>

				<ProgressionSection
					title={t('interview:myInterviews.section.progression.label')}
					buttonLabel={t('interview:progression.radar.legend.display')}
					legend={<ProgressionLegend />}
					isDisplay={progressions.length > 0}
				>
					<Stack direction="row" flexWrap="wrap" gap={3} mb={10}>
						{progressions.map((progression) => (
							<ProgressionCard key={progression.id} progression={progression} />
						))}
					</Stack>
				</ProgressionSection>
			</Stack>
		</Container>
	)
}

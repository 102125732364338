import { AutocompleteSelect } from '@domains/form/AutocompleteSelect'
import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const AssessorSelect = (): ReactElement => {
	const { t } = useTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = data?.getAssessors ?? []
	const availableAssessors = assessors
		.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
		.sort((a, b) => a.label.localeCompare(b.label))

	return (
		<AutocompleteSelect
			name="assessorIds"
			items={availableAssessors}
			label={t('interviews:columns.assessor')}
			isMultiple
		/>
	)
}

import { type GetInterviewTemplateListQuery } from '@gqlTypes'
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material'
import { RouterLink } from '@shared/components/buttons/RouterLink'
import { type ReactElement } from 'react'
import { ROUTE_INTERVIEW_TEMPLATE_DETAIL, generateRoute } from '../../router/routes'
import { useTranslation } from 'react-i18next'

interface Props {
	interviewTemplate: GetInterviewTemplateListQuery['getInterviewTemplateList']['nodes'][0]
}

export const InterviewTemplateCard = ({ interviewTemplate }: Props): ReactElement | null => {
	const { t } = useTranslation()

	return (
		<Box sx={{ mt: 1 }}>
			<Card elevation={3} sx={{ width: 270, borderRadius: 3, display: 'inline-block' }}>
				<CardContent sx={{ pb: 0 }}>
					<Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: 'grays.gray5' }}>
						{interviewTemplate.label}
					</Typography>
				</CardContent>
				<CardActions sx={{ pt: 0 }}>
					<RouterLink
						route={generateRoute(ROUTE_INTERVIEW_TEMPLATE_DETAIL, { interviewTemplateId: interviewTemplate.id })}
						label={t('interviewTemplate:list.open')}
					/>
				</CardActions>
			</Card>
		</Box>
	)
}

import { type ReactElement } from 'react'
import { ROUTE_COMMUNITY_MEMBERS } from '../../../router/routes'
import { UserPositionsDetails } from '../../../features/details/pages/userPositions/UserPositionsDetails'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getCommunityMembersTabs } from '@shared/components/layout/menus/menus'
import { useDetails } from '@features/details/hooks/useDetails'
import { INTEGRATION_NAME } from '../../../constants/INTEGRATION_TYPE'

export const CommunityMembersDetails = (): ReactElement => {
	const { t } = useTranslation()
	const { userPositionId } = useParams<{ userPositionId: string }>()
	const subMenuTabs = getCommunityMembersTabs(userPositionId)
	const details = useDetails(userPositionId, subMenuTabs, 'userPositionInfo')
	const infos = { ...details, integrationName: INTEGRATION_NAME.EMPLOYEE }

	return (
		<UserPositionsDetails
			infos={infos}
			userPositionId={userPositionId}
			backToolTip={t('menu.employees')}
			rootPath={ROUTE_COMMUNITY_MEMBERS}
		/>
	)
}

import { type ReactElement, useState } from 'react'
import { Button, Grid, Stack, TableRow, TextField, useTheme } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { useTranslation } from 'react-i18next'
import Avatar from '@mui/material/Avatar'
import { TableLayout } from '@ui/core/TableLayout'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import { BooleanInput } from '@ui/core/BooleanInput'
import LinearProgress from '@ui/progress/LinearProgress'
import StarRatingInput from '@ui/core/StarRatingInput'
import { calculateCompletion } from '../../../../helper/candidacyStatus.helper'
import { useErrorHandler } from 'react-error-boundary'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import InfoValue from './InfoValue'
import { PresentationLink } from './PresentationLink'
import { Link } from '@shared/components/links/Link'
import { useMeQuery } from '@gqlTypes'
import { DateTime } from 'luxon'
import { FeedbackButton } from './FeedbackButton'
import { type useDetailsReturns } from '@features/details/hooks/useDetails'
import { InfoFeedbacksKPI } from './InfoFeedbacksKPI'

interface Props {
	infos: useDetailsReturns
	updateIntegration: (field: string, newValue: string | boolean) => Promise<void>
}

export const Informations = ({ infos, updateIntegration }: Props): ReactElement | null => {
	const { t } = useTranslation(['translation', 'avatar'])
	const theme = useTheme()
	const errorHandler = useErrorHandler()
	const { data } = useMeQuery()
	const organizationType = data?.me?.organization.type ?? ''
	const id = data?.me?.id ?? ''
	const creationDate = data?.me?.creationDate ?? ''

	const timestamp = DateTime.fromISO(creationDate).toMillis()

	useDocumentTitle(
		t('translation:candidates.informations.title', { name: infos.integrationInfo?.userInfo?.displayName })
	)

	const [isEnglish, setIsEnglish] = useState(infos.integrationInfo?.english ?? false)

	if (infos.integrationInfo == null) return null

	const publicCode = infos.integrationInfo.publicCode ?? ''
	const hasPublicCode = publicCode != null && publicCode !== ''
	const location = infos.integrationInfo.location
	const email = infos.integrationInfo.userInfo?.email ?? ''
	const mailLink = email !== '' ? `mailto:${email}` : null
	const slug = infos.integrationInfo?.userInfo?.slug ?? ''
	const urlPrefix = import.meta.env.VITE_WEB_PUBLIC_URL
	const resumeUrl = `${urlPrefix}resume/${slug}`
	const fieldList = infos.fieldList ?? []
	const initials = infos.integrationInfo?.userInfo?.initials ?? ''

	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.profileImage')} sx={{ height: 300 }}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Avatar
							sx={{
								width: 150,
								height: 150,
								marginBottom: 34,
								backgroundColor: theme.palette.secondary.main,
								fontSize: 50
							}}
							src={infos.userInfo?.photoURL ?? ''}
						>
							{initials !== '' ? initials : null}
						</Avatar>
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={5}>
				<CardLayout
					title={t('translation:candidates.informations.cardTitle.generalInfos')}
					footer={
						<Stack direction="row" spacing={1}>
							<Link isExternal url={resumeUrl}>
								<Button variant="contained">{t('translation:candidates.informations.resume')}</Button>
							</Link>
							<FeedbackButton senderId={id} recipientSlug={slug} senderCreationDateTimeStamp={timestamp} />
						</Stack>
					}
				>
					<TableLayout>
						<InfoValue
							title={t('translation:candidates.commons.mobile')}
							value={infos.integrationInfo?.userInfo?.mobile ?? ''}
						/>
						<InfoValue
							title={t('translation:candidates.commons.mail')}
							value={mailLink ?? ''}
							link={infos.integrationInfo?.userInfo?.email ?? undefined}
						/>
						{hasPublicCode && <InfoValue title={t('translation:candidates.commons.publicCode')} value={publicCode} />}
						<InfoValue title={t('candidates.commons.birthdate')} value={infos.userInfo?.formatedBirthdate ?? ''} />
						<InfoValue title={t('candidates.informations.publicTitle')} value={infos.userInfo?.publicTitle} />
						<InfoValue title={t(`candidates.informations.${organizationType}.location`)} value={location} />
						<InfoValue title={t('candidates.informations.currentCompany')} value={infos.userInfo?.currentCompany} />
						<InfoValue
							title={t('candidates.informations.currentFunction')}
							value={infos.userInfo?.currentFunctionObject?.name}
						/>
						<InfoValue
							title={t('candidates.informations.currentSector')}
							value={infos.userInfo?.currentSectorObject?.name ?? ''}
						/>

						{fieldList.map(({ fieldId, labelOrganization, values }) => {
							const userInfo = infos.userInfo
							const fieldMapping: Record<string, string | undefined> = {
								zipCode: userInfo?.zipCode ?? undefined,
								applicationMail: userInfo?.applicationMail ?? undefined,
								acceptCommunications:
									(userInfo?.acceptCommunications ?? false)
										? t('translation:candidates.commons.validateCommunications')
										: t('translation:candidates.commons.refuseCommunications'),
								// TODO
								educationLevel: values.find(({ id }) => id === userInfo?.educationLevel)?.label ?? undefined,
								status: values.find(({ id }) => id === userInfo?.status)?.label ?? undefined,
								link: userInfo?.link
							}

							const value = fieldMapping[fieldId]
							const href = fieldId === 'link' ? userInfo?.link : null

							if (fieldId === 'acceptCommunications') {
								labelOrganization = t('translation:candidates.commons.acceptCommunications')
							}

							return <InfoValue title={labelOrganization} value={value} key={fieldId} link={href} />
						})}
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={4}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.candidacyStatus')}>
					<TableLayout>
						<InfoValue
							title={t('translation:candidates.commons.status')}
							value={infos.integrationInfo?.statusObject?.label ?? ''}
						/>
						<InfoValue
							title={t('translation:candidates.commons.profile')}
							value={
								<LinearProgress
									compact
									withLabel
									labelPosition="left"
									value={calculateCompletion(infos.integrationInfo?.steps)}
									variant="determinate"
								/>
							}
						/>
						<InfoValue
							title={t('translation:candidates.commons.selfEvaluation')}
							value={
								<StarRatingInput
									readOnly
									value={
										infos.integrationInfo?.alignment?.length != null
											? infos.integrationInfo?.alignment?.length - 1
											: null
									}
								/>
							}
						/>
						<InfoValue
							title={t('translation:candidates.commons.interviewEvaluation')}
							value={
								<StarRatingInput
									readOnly
									value={
										infos.integrationInfo?.interview?.score != null ? infos.integrationInfo?.interview?.score : null
									}
								/>
							}
						/>
						<InfoFeedbacksKPI indicators={infos.userInfo?.indicators} />
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.video')}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<PresentationLink link={infos.userInfo?.link} />
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.specificInfos')}>
					<InfoCardContent
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<TableLayout>
							<TableRow>
								<InfoLayoutLabel>{t('translation:candidates.informations.parcour')}</InfoLayoutLabel>
								<InfoLayoutValue>
									<BooleanInput
										value={isEnglish}
										label={t('translation:candidates.informations.english')}
										onChange={() => {
											updateIntegration('english', !isEnglish)
												.then(() => {
													setIsEnglish(!isEnglish)
												})
												.catch(errorHandler)
										}}
									/>
								</InfoLayoutValue>
							</TableRow>
						</TableLayout>
						<TextField
							label={t('translation:candidates.informations.note')}
							variant="outlined"
							multiline
							rows={4}
							fullWidth
							InputLabelProps={{ shrink: true }}
							placeholder={t('translation:candidates.informations.notePlaceHolder')}
							defaultValue={infos.integrationInfo?.comment}
							onBlur={async (event) => {
								await updateIntegration('comment', event.target.value)
							}}
						/>
					</InfoCardContent>
				</CardLayout>
			</Grid>
		</Grid>
	)
}

import { styled } from '@mui/material'
import theme from '@ui/theme'
import { SnackbarProvider, MaterialDesignContent } from 'notistack'
import { type ReactNode, type ReactElement } from 'react'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
	'&.notistack-MuiContent-success': {
		backgroundColor: theme.palette.success.main
	},
	'&.notistack-MuiContent-error': {
		backgroundColor: theme.palette.error.main
	}
}))

interface Props {
	children: ReactNode
}

export const MuiSnackbarProvider = ({ children }: Props): ReactElement => {
	return (
		<SnackbarProvider
			autoHideDuration={5000}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			Components={{
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent
			}}
		>
			{children}
		</SnackbarProvider>
	)
}

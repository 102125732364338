import { useState, type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { ROUTE_INTERVIEW } from '../../../router/routes'
import { type GetInterviewDetailsQuery, useMeQuery, useUpdateInterviewMutation } from '@gqlTypes'
import { StepsOverview } from '../../../features/lists/components/cells/StepsOverview'
import { useSteps } from './useSteps'
import useUserBContext from '@shared/hooks/useUserBContext'
import { Stack, Chip, FormControlLabel, Switch, Tooltip, Box } from '@mui/material'
import BackButton from '@shared/components/buttons/BackButton'
import { InterviewAssessor } from '../list/InterviewAssessor'
import { InterviewLabel } from '../list/InterviewLabel'
import { InterviewTargetDate } from '../list/InterviewTargetDate'
import { SaveDraftButton } from '@features/details/components/interviewTab/SaveDraftButton'
import { LoadingButton } from '@mui/lab'
import { LastUpdate } from './LastUpdate'
import { ErrorHandler } from '#helpers/error.helper'
import { InterviewCustomFieldList } from '../list/InterviewCustomFieldList'
import { FeedbackModal } from './feedbackForm/FeedbackModal'
import { InterviewDetailTutorial } from '../tutorial/InterviewDetailTutorial'
import { useGenerateInterviewPdf } from './useGenerateInterviewPdf'
import { useOrganization } from '@domains/organization/useOrganization'

interface Props {
	interview: GetInterviewDetailsQuery['getInterview']
	isDirty: boolean
	isReadOnly: boolean
	saveDraft: (isDisplayFeedback?: boolean) => void
	isSaving: boolean
	isSavingDraft: boolean
	handleSave: (beforeSaving?: () => void, afterSaving?: () => void) => void
}

export const InterviewTabBar = ({
	interview,
	isDirty,
	isReadOnly,
	saveDraft,
	isSaving,
	isSavingDraft,
	handleSave
}: Props): ReactElement => {
	const { missedInterview, assessedFullName, updateDate, steps, stepsState } = interview
	const { label, customFields, typeLabel, assessorFullName, isDraft } = interview
	const isInterviewLabelAvailable = true
	const registrationString = isDraft ? 'validate' : 'update'
	const { hasPdfExport } = useOrganization()
	const { isGeneratingPdf, generateInterviewPdf } = useGenerateInterviewPdf(interview.id ?? '')
	const hasInterviewSignatureStep = interview.stepsState.interviewSignature != null
	const hasEmptyPdfUrl = interview.pdfUrl === ''

	const { data } = useMeQuery()
	const isExternal = data?.me?.role?.isExternal ?? false
	const interviewId = interview.id ?? ''
	const interviewUserId = interview.userId ?? ''
	const { t } = useTranslation<string>()
	const [updateInterview] = useUpdateInterviewMutation()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const stepsWithState = useSteps({ steps, stepsState })
	const userB = useUserBContext()
	const isUserView = userB.id === interviewUserId
	const { interviewDate, isScheduled, hasStartTime } = interview

	const beforeSaving = (): void => {
		if (isDraft) setIsModalOpen(true)
	}

	const afterSaving = async (): Promise<void> => {
		if (hasPdfExport && !hasInterviewSignatureStep && hasEmptyPdfUrl) {
			await generateInterviewPdf()
		}
	}

	const toogleMissedInterview = (): void => {
		;(async () => {
			const variables = { interviewId, interviewInput: { missedInterview: !(missedInterview ?? false) } }
			await updateInterview({ variables })
		})().catch(ErrorHandler)
	}
	const title = t('translation:interviewDetailPage.interview', { assessedFullName })

	return (
		<Box
			className="print-interview-bar"
			sx={{
				width: '100%',
				backgroundColor: 'backgroundBlue.main',
				borderBottom: '1px solid #0000001F'
			}}
		>
			<Stack direction="row" spacing={1}>
				<Box sx={{ flex: 1 }}>
					<Stack direction="column" sx={{ marginY: 2 }} spacing={1}>
						<Stack direction="row" justifyContent="space-between">
							<Stack direction="row" gap={2} alignItems="center">
								<Stack direction="row" alignItems="center">
									{isUserView ? null : (
										<Box className="no-print">
											<BackButton route={ROUTE_INTERVIEW} tooltipTitle={t('menu.interview')} />
										</Box>
									)}
									<Typography variant="h5" color="primary">
										{title}
									</Typography>
								</Stack>
								<StepsOverview steps={stepsWithState} />
								{isReadOnly ? null : (
									<FormControlLabel
										control={
											<Switch color="warning" checked={missedInterview ?? false} onChange={toogleMissedInterview} />
										}
										label={t('translation:interviewDetailPage.absent')}
										disabled={isUserView}
									/>
								)}
								<InterviewDetailTutorial />
							</Stack>
							<>
								{isReadOnly ? null : (
									<Stack className="no-print" direction="row" justifyContent="center" spacing={2}>
										<SaveDraftButton
											isDirty={isDirty}
											isDraft={interview.isDraft}
											isSavingDraft={isSavingDraft}
											isSaving={isSaving}
											saveDraft={saveDraft}
										/>
										<Tooltip title={t(`translation:interviewDetailPage.${registrationString}Tooltip`)}>
											<LoadingButton
												className="finalize"
												loading={isSaving || isGeneratingPdf}
												onClick={() => {
													handleSave(beforeSaving, afterSaving)
												}}
												variant="contained"
												color="secondary"
											>
												{t(`translation:interviewDetailPage.${registrationString}`)}
											</LoadingButton>
										</Tooltip>
										{isModalOpen && isExternal ? (
											<FeedbackModal
												handleClose={() => {
													setIsModalOpen(false)
												}}
												isModalOpen={isModalOpen}
											/>
										) : null}
									</Stack>
								)}
							</>
						</Stack>
						<Stack direction="row" justifyContent="space-between">
							<Stack direction="row" gap={4} alignItems="center" sx={{ ml: 2 }}>
								<InterviewAssessor assessorFullName={assessorFullName} />
								<InterviewLabel
									typeLabel={typeLabel}
									label={label ?? ''}
									isInterviewLabelAvailable={isInterviewLabelAvailable}
								/>
								<InterviewTargetDate
									{...{
										interviewDate,
										isScheduled,
										targetDate: interview.targetDate ?? '',
										interviewStepState: stepsState.interview ?? false,
										hasStartTime
									}}
								/>
								<InterviewCustomFieldList
									customFields={Object.values((customFields as Record<string, string>) ?? {})}
								/>
							</Stack>
							{isReadOnly ? null : (
								<Stack className="no-print" direction="row" spacing={1} sx={{ marginRight: 1 }}>
									<LastUpdate updateDate={updateDate ?? ''} />
									{isDirty ? <Chip size="small" label={t('interview:form.notSaved')} /> : null}
									{isDraft && !isDirty ? (
										<Chip sx={{ color: '#fff' }} size="small" color="warning" label={t('interview:form.draft')} />
									) : null}
									{!isDraft && !isDirty ? (
										<Chip size="small" color="secondary" label={t('interview:form.saved')} />
									) : null}
								</Stack>
							)}
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Box>
	)
}

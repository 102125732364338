import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const WorkArrangementFilter = (): ReactElement | null => {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	const items = (data?.me?.organization?.matchingRestrictions?.workArrangements ?? []).map((item) => ({
		value: item.id ?? '',
		label: item.label ?? ''
	}))
	if (items.length <= 1 || loading) return null

	return (
		<InputFilter keyName={'workArrangementId'} operation={filterOperation.EQUAL}>
			<AutoCompleteFilter
				options={items}
				label={t('offers:resumeLibrary.filters.workArrangement.label')}
				allValueLabel={t('offers:resumeLibrary.filters.workArrangement.allLabel')}
				width="100%"
				multiple
			/>
		</InputFilter>
	)
}

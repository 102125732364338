import { ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export interface StepItemProps {
	id: string
	label: string
	isValidated: boolean
	onClick?: () => void
}

export const StepItem = ({ label, isValidated, onClick }: StepItemProps): ReactElement => {
	return (
		<ListItem disablePadding onClick={onClick}>
			<ListItemButton disableGutters sx={{ py: 0 }} divider>
				{isValidated ? (
					<CheckCircleRoundedIcon fontSize="small" sx={{ color: 'secondary.main' }} />
				) : (
					<RadioButtonUncheckedIcon fontSize="small" />
				)}
				<ListItemText>
					<Typography ml={1} mr={1} fontSize={15} textAlign="left">
						{label}
					</Typography>
				</ListItemText>
				<ArrowForwardIosIcon fontSize="small" />
			</ListItemButton>
		</ListItem>
	)
}

import { type ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { type OfferInput, useCreateOfferMutation } from '@gqlTypes'
import { OfferForm } from './OfferForm'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'
import { OffersPath } from '../OffersRouter'
import { useTranslation } from 'react-i18next'
import { generateRoute } from '../../../router/routes'

export const OfferCreator = (): ReactElement => {
	const history = useHistory()
	const { t } = useTranslation()
	const { onError, onCompleted } = useGqlSnackBar('offers:details.snackbar.create')
	const [createOfferMutation, { loading: isCreating }] = useCreateOfferMutation({ onError, onCompleted })

	const createOffer = async (offerInput: OfferInput, keepValues: () => void): Promise<void> => {
		const result = await createOfferMutation({ variables: { offerInput } })
		if (result.errors == null) {
			keepValues()
			const matchingsRoute: string = generateRoute(OffersPath.matchings, { trackOfferId: result.data?.createOffer.id })
			history.push(matchingsRoute)
		}
	}
	return (
		<OfferForm
			isSaving={isCreating}
			onSave={createOffer}
			saveLabel={t('offers:details.topBar.button.publish')}
			onSaveError={onError}
		/>
	)
}

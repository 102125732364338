import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom'
import { type ReactNode } from 'react'

interface Props {
	route: string
	icon: ReactNode
	tooltipTitle: string
	disabled?: boolean
}

export default function NavButtonIcon({ route, icon, tooltipTitle, disabled = false }: Props): JSX.Element {
	const color = disabled ? '#BDBDBD' : 'inherit'
	const cursor = disabled ? 'inherit' : 'pointer'

	return (
		<Tooltip title={tooltipTitle}>
			<IconButton
				aria-label={tooltipTitle}
				style={{ cursor, color }}
				to={route}
				component={Link}
				disabled={disabled}
				data-testid="iconButton"
			>
				{icon}
			</IconButton>
		</Tooltip>
	)
}

import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { type ReactElement, useState } from 'react'
import Button from '@mui/material/Button'
interface Props {
	onPressConfirm: () => void
	title: string
	message: string
	confirmLabel: string
	cancelLabel: string
	isLoading: boolean
}

export const ConfirmButton = ({ onPressConfirm, title, message, confirmLabel, cancelLabel }: Props): ReactElement => {
	const [isOpen, setIsOpen] = useState(false)
	const Close = (): void => {
		setIsOpen(false)
	}
	const Open = (): void => {
		setIsOpen(true)
	}

	return (
		<>
			<Dialog fullWidth maxWidth="xs" open={isOpen} onClose={Close}>
				<DialogTitle>
					<Typography variant="h6">{title}</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography>{message}</Typography>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={Close}>
						{cancelLabel}
					</Button>
					<Button variant="contained" color="primary" onClick={onPressConfirm}>
						{confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>
			<Button variant="contained" color="primary" onClick={Open}>
				{title}
			</Button>
		</>
	)
}

import { useState, type ReactElement } from 'react'
import { Dialog, DialogContent, Stack } from '@mui/material'
import { ToConfirmeContent } from './ToConfirmeContent'
import { ConfirmedContent } from './ConfirmedContent'

interface Props {
	isOpen: boolean
	setIsOpen: (state: boolean) => void
	jobMatchingId: string
	route: string
	offername: string
	listRoute: string
	trackOfferId: string
}

export const SelectedModal = ({
	isOpen,
	setIsOpen,
	jobMatchingId,
	route,
	offername,
	listRoute,
	trackOfferId
}: Props): ReactElement | null => {
	const [isSelected, setIsSelected] = useState<boolean>(false)

	if (!isOpen) return null

	return (
		<Dialog open fullWidth maxWidth="lg" PaperProps={{ sx: { borderRadius: 3 } }}>
			<DialogContent>
				<Stack direction="column" justifyContent="center" textAlign="center" gap={2}>
					{!isSelected ? (
						<ToConfirmeContent {...{ jobMatchingId, setIsOpen, setIsSelected, trackOfferId }} />
					) : (
						<ConfirmedContent {...{ setIsOpen, route, offername, jobMatchingId, listRoute }} />
					)}
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import MDIIcon from '@shared/components/Icon/MDIIcon'

interface Props {
	name: string
}

export const CurrentManager = ({ name }: Props): ReactElement | null => {
	if (name === '') return null
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<MDIIcon sx={{ color: 'grays.gray3' }} name="account-tie" size={18} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }} textOverflow="ellipsis" noWrap>
				{name}
			</Typography>
		</Stack>
	)
}

import { type MotivationLabelGroup as MotivationLabelGroupProps } from '@gqlTypes'
import { Box, Grid, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { ChipSelector } from '@shared/components/forms/fields/ChipSelector'
import { ChipList } from '@shared/components/ChipList'

interface Props extends MotivationLabelGroupProps {
	isEditable: boolean
	hasMultipleAnswers: boolean
}

export const MotivationLabelGroup = ({
	id,
	groupLabel,
	labels,
	isEditable,
	hasMultipleAnswers
}: Props): ReactElement | null => {
	if (labels.length === 0 && groupLabel === '') return null

	const selectedLabels = labels.filter(({ isSelected }) => isSelected)

	return (
		<Grid container>
			<Grid item xs={4}>
				<Typography gutterBottom whiteSpace="pre-line" style={{ fontSize: 16, color: '#4F4F4F', fontWeight: 700 }}>
					{groupLabel}
				</Typography>
			</Grid>
			<Grid item xs={8} marginY={1}>
				<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, ml: 5 }}>
					{isEditable ? (
						<ChipSelector
							name={`labels[${id}]`}
							items={labels}
							isEditable={isEditable}
							maxSelectableItem={hasMultipleAnswers ? 0 : 1}
							defaultValue={selectedLabels.map(({ id }) => id)}
						/>
					) : (
						<ChipList items={selectedLabels} selectedItems={selectedLabels.map(({ id }) => id)} />
					)}
				</Box>
			</Grid>
		</Grid>
	)
}

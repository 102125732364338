import { gql } from '@apollo/client'

export const GET_DISCUSSIONUSERS_LIST = gql`
	query GetDiscussionUserList($filters: [FilterInput], $organizationId: String, $cursor: String, $limit: Int) {
		getDiscussionUserList(filters: $filters, organizationId: $organizationId, cursor: $cursor, limit: $limit) {
			hasNextPage
			cursor
			nodes {
				id
				recipientRef {
					userId
					firstName
					lastName
					mobile
				}
				lastMessage
				userRef {
					userId
					displayName
				}
				read
				flagged
				archived
			}
		}
	}
`

import TrackButton from './TrackButton'
import { ROUTE_TRACKS_POSITION } from '../../../../router/routes'
import { useTranslation } from 'react-i18next'

const EmployeeTrackButton = (): JSX.Element => {
	const { t } = useTranslation()

	return <TrackButton title={t('tracks:list.bar.filter.employee')} url={ROUTE_TRACKS_POSITION} />
}

export default EmployeeTrackButton

import { type OldTextFieldProps, TextField } from '@ui/core/TextField'
import { InputFilter } from '@ui/filter/InputFilter'
import { type InputLabelProps } from '@mui/material'
import { type FilterOperation, filterOperation } from '@ui/filter/FilterForm'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	operation?: FilterOperation
	label: string
	type?: string
	InputLabelProps?: Partial<InputLabelProps>
	merged?: OldTextFieldProps['merged']
}

export const TextFilter = ({
	keyName,
	operation = filterOperation.EQUAL,
	label,
	type,
	InputLabelProps,
	merged
}: Props): ReactElement => {
	return (
		<InputFilter keyName={keyName} operation={operation} isTextField>
			<TextField
				label={label}
				variant="standard"
				type={type}
				InputLabelProps={InputLabelProps}
				isBlurChange
				merged={merged}
			/>
		</InputFilter>
	)
}

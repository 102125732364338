import { generatePath } from 'react-router-dom'
import { FeedbackRequestForm } from './FeedbackRequestForm.page'
import { FeedbackRequestThanks } from './FeedbackRequestThanks.page'

export enum FeedbackRequestPath {
	interviewForm = '/feedback-request/interview/:interviewId/:fullrequest?',
	form = '/feedback-request/:fullrequest?',
	thanks = '/feedback-request/thanks/:feedbackCount'
}
export const feedbackRequestRoutes = [
	{
		path: FeedbackRequestPath.thanks,
		children: <FeedbackRequestThanks />
	},
	{
		path: FeedbackRequestPath.interviewForm,
		children: <FeedbackRequestForm />
	},
	{
		path: FeedbackRequestPath.form,
		children: <FeedbackRequestForm />,
		notExact: true
	}
]

export const navigateToThanks = (feedbackCount: number): void => {
	generatePath(FeedbackRequestPath.thanks, { feedbackCount })
}

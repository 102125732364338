import { type UserRef } from '@gqlTypes'
import { Avatar } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	user?: UserRef | null
	size?: number
}

export const UserAvatar = ({ user, size = 65 }: Props): ReactElement | null => {
	if (user == null) return null
	return (
		<Avatar src={user.avatarUrl ?? ''} sx={{ backgroundColor: user.avatarColor, width: size, height: size }}>
			{user.initials}
		</Avatar>
	)
}

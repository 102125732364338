import { type TrackDetailsFragment } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import CheckBoxField from '@shared/components/forms/fields/CheckBoxField'
import { type ReactElement } from 'react'
import { type Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Props {
	control: Control
	fieldName: string
	details?: TrackDetailsFragment
	isReversed?: boolean
	defaultValue?: boolean
}

export const TrackCheckBox = ({
	control,
	fieldName,
	details,
	isReversed = false,
	defaultValue
}: Props): ReactElement => {
	const { t } = useTranslation()
	const computedDefaultValue: boolean = defaultValue ?? (details != null ? details[fieldName] : false)

	return (
		<TableLayoutRow
			title={t(`tracks:details.formFields.${fieldName}.title`)}
			tooltip={t(`tracks:details.formFields.${fieldName}.tooltip`)}
		>
			<CheckBoxField control={control} name={fieldName} defaultValue={computedDefaultValue} isReversed={isReversed} />
		</TableLayoutRow>
	)
}

import React, { type ReactElement, useContext } from 'react'
import { FilterContext, type FilterOperation, filterOperation } from './FilterForm'

interface Props {
	keyName: string
	operation?: FilterOperation
	children: ReactElement
	defaultValue?: unknown
	isDynamicKey?: boolean
	isTextField?: boolean
}

export const InputFilter = ({
	keyName,
	operation = filterOperation.EQUAL,
	children,
	defaultValue,
	isDynamicKey = false,
	isTextField = false
}: Props): ReactElement => {
	const { getFilter, setFilter, makeFilter } = useContext(FilterContext)

	const filter = getFilter()?.find((el) => {
		return isDynamicKey
			? el.key.startsWith(keyName) && el.operation === operation
			: el.key === keyName && el.operation === operation
	})

	const onChange = (newVal: boolean | string | number | string[]): void => {
		const mustReset =
			(newVal === '' && isDynamicKey) ||
			(newVal === '' && isTextField) ||
			newVal === undefined ||
			newVal === 'empty' ||
			(Array.isArray(newVal) && (newVal.length === 0 || newVal.includes('empty')))

		const otherFilters = getFilter()?.filter((el) => {
			const isSimilarKey = isDynamicKey ? el.key.startsWith(`${keyName}.`) : el.key === keyName
			return !(isSimilarKey && el.operation === operation)
		})
		const addFilter = (): void => {
			const key = isDynamicKey ? `${keyName}.${newVal as string}` : keyName
			const val = isDynamicKey ? false : newVal
			setFilter([...otherFilters, makeFilter(key, operation, val, false)])
		}

		if (mustReset) setFilter(otherFilters)
		else addFilter()
	}

	const emptyValue = isTextField || isDynamicKey ? '' : 'empty'
	let startValue = defaultValue ?? emptyValue

	if ([filterOperation.IN_ARRAY, filterOperation.NOT_IN_ARRAY].includes(operation)) {
		startValue = defaultValue != null ? [startValue] : []
	}

	const value =
		filter != null && filter.value !== undefined
			? isDynamicKey
				? filter.key.split('.').slice(-1)[0]
				: filter.value
			: startValue

	const containedInput = React.cloneElement(React.Children.only(children), { value, onChange })
	return <>{containedInput}</>
}

import { Stack, Typography } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'

interface Props {
	icon: ReactNode
	label: string
}

export const CardIconLabel = ({ icon, label }: Props): ReactElement | null => {
	if (label === '') return null
	return (
		<Stack direction="row" gap={1} alignItems="center">
			{icon}
			<Typography
				sx={{ fontSize: '14px', color: 'grays.gray3' }}
				whiteSpace="nowrap"
				overflow="hidden"
				textOverflow="ellipsis"
			>
				{label}
			</Typography>
		</Stack>
	)
}

import { type ReactElement } from 'react'
import { type UserPositionListNodeFragment } from '@gqlTypes'
import { Typography, Stack, Tooltip } from '@mui/material'
import { StepsOverview } from '@features/lists/components/cells/StepsOverview'
import { generateRoute } from '../../../router/routes'
import { UserCard } from '@shared/components/cards/UserCard'
import { CurrentLocation } from '@domains/students/list/CurrentLocation'
import { PositionCardActions } from '@features/lists/positions/PositionCardActions'

interface Props {
	position: UserPositionListNodeFragment
	isSelected?: boolean
	selectRow?: () => void
	children?: ReactElement
	dialogComponent: ReactElement
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	linkLabel: string
	detailRoute: string
	riskTitle: string
	isEditable: boolean
}

export const PositionCard = ({
	position,
	isSelected,
	selectRow,
	children,
	dialogComponent,
	setIsOpen,
	linkLabel,
	detailRoute,
	riskTitle,
	isEditable
}: Props): ReactElement => {
	const renderActions = (isHovered: boolean): ReactElement | null => {
		const isSelectable = selectRow != null && isSelected != null
		if (!isSelectable) return null
		return (
			<PositionCardActions
				position={position}
				isSelected={isSelected}
				selectRow={selectRow}
				isHovered={isHovered}
				dialogComponent={dialogComponent}
				setIsOpen={setIsOpen}
				riskTitle={riskTitle}
				isEditable={isEditable}
			/>
		)
	}

	const trackLabel = position.orgaPosition?.label

	return (
		<UserCard
			userRef={position.userInfo}
			actions={renderActions}
			to={generateRoute(detailRoute, { userPositionId: position.id })}
			linkLabel={linkLabel}
			title={position.reverseFullname}
		>
			<Stack spacing={2}>
				<Tooltip title={trackLabel}>
					<Typography sx={{ fontSize: '16px', color: 'grays.gray5', textOverflow: 'ellipsis' }} noWrap>
						{trackLabel}
					</Typography>
				</Tooltip>
				<StepsOverview steps={position.stepsIntegrations} />
				<Stack spacing={1}>
					<CurrentLocation businessUnit={position.businessUnit ?? ''} location={position.location} />
					{children}
				</Stack>
			</Stack>
		</UserCard>
	)
}

import { LoginLayout } from './LoginLayout'
import { LoginForm } from './components/LoginForm'

export const LoginPage: React.FC = () => {
	return (
		<LoginLayout>
			<LoginForm />
		</LoginLayout>
	)
}

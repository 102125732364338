import {
	GetCareerMatchingsDocument,
	type TrackType,
	useComputeCarrerMatchingsMutation,
	useGetCareerMatchingsQuery
} from '@gqlTypes'
import { Box, Button, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { CareerMatchingCard } from './CareerMatchingCard'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'

interface Props {
	userIntegrationId: string
	trackType: TrackType
}

export const CareerMatchings = ({ userIntegrationId, trackType }: Props): ReactElement | null => {
	const { t } = useTranslation()

	const [computeMatchings, { loading: isComputeLoading }] = useComputeCarrerMatchingsMutation({
		variables: { userIntegrationId, trackType },
		refetchQueries: [GetCareerMatchingsDocument]
	})
	const { data, loading: isMatchingsLoading } = useGetCareerMatchingsQuery({
		variables: { userIntegrationId, trackType }
	})
	const matchings = data?.getCareerMatchings ?? []
	if (isComputeLoading || isMatchingsLoading) return <LocalLoader />
	if (data == null) return null
	return (
		<Box>
			<Button variant="contained" onClick={async () => await computeMatchings()} sx={{ mb: 2 }}>
				{t('careerMatching:list.refresh')}
			</Button>
			<Stack gap={3} sx={{ width: { xs: 400, sm: 500, md: 574, lg: 872, xl: 1160, margin: 'auto' } }}>
				{matchings.length === 0 ? (
					<Typography>{t('careerMatching:list.noData')}</Typography>
				) : (
					<Stack direction="row" flexWrap="wrap" sx={{ gap: 3 }}>
						{matchings.map((matching) => (
							<CareerMatchingCard matching={matching} key={matching.id} />
						))}
					</Stack>
				)}
			</Stack>
		</Box>
	)
}

import { IconButton, Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ExternalLink } from '@shared/components/links/ExternalLink'

interface Props {
	attachment: {
		fileUrl: string
		fileName: string
	}
	hasDeleteButton: boolean
	onDelete: (fileName: string) => void
}

export const Attachment = ({ hasDeleteButton, attachment, onDelete }: Props): ReactElement => {
	const { fileUrl, fileName } = attachment

	return (
		<Stack sx={{ flexDirection: 'row', mt: 3, justifyContent: 'space-between', alignItems: 'center' }}>
			<ExternalLink url={fileUrl}>
				<Stack sx={{ flexDirection: 'row' }}>
					<DescriptionOutlinedIcon sx={{ color: '#0A2E56', mr: 2 }} />
					<Typography sx={{ color: '#0A2E56' }}>{fileName}</Typography>
				</Stack>
			</ExternalLink>
			{hasDeleteButton ? (
				<IconButton
					onClick={() => {
						onDelete(fileName)
					}}
				>
					<DeleteOutlineOutlinedIcon />
				</IconButton>
			) : null}
		</Stack>
	)
}

import { LoadingButton } from '@mui/lab'
import { DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { InputTextFieldForm } from '@shared/components/forms/fields/inputTextFieldForm'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	handleClose: () => void
}

export const FeedbackComment = ({ handleClose }: Props): ReactElement => {
	const { t } = useTranslation('feedbackRequest')
	return (
		<>
			<DialogTitle color="secondary">{t('interview.mailRequest.title')}</DialogTitle>
			<DialogContent sx={{ width: '100%' }}>
				<InputTextFieldForm
					fullWidth
					name="upgrade"
					label={t('interview.mailRequest.increase')}
					size="medium"
					multiline
					rows={4}
					sx={{ marginTop: 4 }}
				/>
			</DialogContent>
			<DialogActions>
				<LoadingButton onClick={handleClose} color="secondary" variant="contained">
					{t('interview.validate')}
				</LoadingButton>
			</DialogActions>
		</>
	)
}

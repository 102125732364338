import Button from '@mui/material/Button'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import NavButtonIcon from '@shared/components/buttons/NavButtonIcon'
import ArrowBack from '@mui/icons-material/ArrowBack'
import Bar from '@shared/components/Bar'
import { styled } from '@mui/styles'
import { Typography } from '@mui/material'

interface Props {
	formIsValid: boolean
	isSaving: boolean
	handleSubmit: () => void
	title: string
	backTooltip: string
	save: string
	route: string
}

export default function DetailBar({
	formIsValid,
	isSaving,
	handleSubmit,
	title,
	backTooltip,
	save,
	route
}: Props): JSX.Element {
	const Title = styled(Typography)({
		flexGrow: 1,
		marginLeft: 5
	})

	return (
		<Bar>
			<NavButtonIcon route={route} icon={<ArrowBack />} tooltipTitle={backTooltip} />
			<Title variant="h6">{title}</Title>
			<Button color="primary" variant="contained" onClick={handleSubmit} disabled={!formIsValid}>
				<LocalLoader load={isSaving} white size={24.5}>
					{save}
				</LocalLoader>
			</Button>
		</Bar>
	)
}

import { LocalDate } from '@ui/core/LocalDate'
import Tooltip from '@mui/material/Tooltip'
import theme from '@ui/theme'
import { type ReactElement } from 'react'

interface Props {
	author?: string
	date?: Date
	children: React.ReactNode
	variant?: 'self' | 'other'
}

export const ConversationMessageContainer = ({ author, date, children, variant = 'self' }: Props): ReactElement => {
	const isSelf = variant === 'self'
	const backgroundColor = isSelf ? theme.palette.messageSelf.main : theme.palette.messageOther.main
	const marginLeft = isSelf ? 0 : 15
	const indicatorColor = isSelf ? theme.palette.messageSelf.main : theme.palette.messageOther.main
	const leftPosition = isSelf ? 'unset' : -15
	const rightPosition = isSelf ? -15 : 'unset'
	const transform = isSelf ? 'scale(-1, 1)' : 'unset'
	const textAlign = isSelf ? 'right' : 'left'

	return (
		<Tooltip
			enterDelay={500}
			placement={variant === 'self' ? 'right' : 'left'}
			title={
				<>
					{date != null ? <LocalDate date={date} dateStyle="short" timeStyle="short" /> : null}
					{author != null ? ` • ${author}` : null}
				</>
			}
		>
			<div
				style={{
					marginTop: 25,
					marginBottom: 25,
					textAlign
				}}
			>
				<div
					style={{
						boxSizing: 'border-box',
						display: 'inline-block',
						padding: '15px 20px',
						maxWidth: '75%',
						position: 'relative',
						marginLeft,
						zIndex: 1,
						textAlign: 'left',
						backgroundColor,
						borderRadius: 10,
						paddingRight: 30,
						marginRight: 15,
						paddingLeft: 30
					}}
				>
					<svg
						style={{
							position: 'absolute',
							top: 0,
							left: leftPosition,
							color: indicatorColor,
							right: rightPosition,
							transform
						}}
						width="24"
						height="15"
						viewBox="0 0 24 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 0H24V15H15L0 0Z" fill="currentcolor" />
					</svg>
					{children}
				</div>
			</div>
		</Tooltip>
	)
}

import { type ReactElement } from 'react'
import { useSelector } from 'react-redux'

export const GlobalLoader = (): ReactElement => {
	const isLoading = useSelector(({ loader }: { loader: { load: boolean } }) => loader.load)
	if (!isLoading) return <></>

	return (
		<div
			style={{
				position: 'absolute',
				opacity: '0.5',
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
				zIndex: 9999
			}}
		>
			&nbsp;
		</div>
	)
}

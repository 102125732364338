import { type ReactElement, type ReactNode } from 'react'
import Twemoji from 'react-twemoji'
import { makeStyles } from 'tss-react/mui'

interface Props {
	children: ReactNode
}

const useStyle = makeStyles()(() => ({
	emoji: {
		height: '1.2em',
		marginLeft: '0.1em',
		marginRight: '0.1em',
		display: 'inline-block',
		verticalAlign: 'middle'
	}
}))

export const Emoji = ({ children }: Props): ReactElement => {
	const { classes } = useStyle()
	return <Twemoji options={{ className: classes.emoji }}>{children}</Twemoji>
}

import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { DataTable } from '@ui/data/DataTable'
import { ButtonMore } from '@shared/components/buttons/ButtonMore'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRows } from '@shared/store/actions/selectedRows.actions'
import { Box, Paper, Stack, type SvgIconTypeMap, Typography } from '@mui/material'
import { type ReactNode, type ReactElement, type Dispatch, type SetStateAction } from 'react'
import { type ApolloQueryResult } from '@apollo/client'
import { type OverridableComponent } from '@mui/material/OverridableComponent'
import { type Cell } from '@domains/communityMembers/list/useCommunityMembersColumns'

interface Props<T> {
	emptyStateLabel: string
	columns: () => Cell[]
	setIsAllRowsSelected: () => Dispatch<SetStateAction<boolean>>
	isAllRowsSelected: boolean
	dataList: T[]
	isLoading: boolean
	fetchMore: (variables: { variables: { cursor: string } }) => Promise<ApolloQueryResult<unknown>>
	cursor: string
	hasNextPage: boolean
	title: string
	Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
	toolbar: ReactNode
}

export const DataGridGQL = <T,>({
	emptyStateLabel,
	columns,
	setIsAllRowsSelected,
	isAllRowsSelected,
	dataList,
	isLoading,
	fetchMore,
	cursor,
	hasNextPage,
	title,
	Icon,
	toolbar
}: Props<T>): ReactElement => {
	const selectedRows = useSelector(({ selectedRows }: { selectedRows: number[] }) => selectedRows)
	const dispatch = useDispatch()

	return (
		<Box sx={{ maxWidth: 1445, width: '100%', ml: 'auto', mr: 'auto', mb: '100px' }}>
			{isLoading ? (
				<Box mt="3" sx={{ height: 300 }}>
					<LocalLoader />
				</Box>
			) : (
				<Stack gap={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
						<Stack direction="row" gap={1} alignItems="center" mt={1}>
							<Icon color="primary" />
							<Typography variant="h5" color="primary">
								<b>{title}</b>
							</Typography>
						</Stack>
						{toolbar}
					</Stack>
					<Paper elevation={3}>
						<DataTable
							rowGetter={(index) => dataList[index]}
							rowCount={dataList.length}
							columns={columns()}
							selectable
							selectedRows={selectedRows}
							onRowSelectionChange={(newRows) => {
								dispatch(setSelectedRows(newRows))
							}}
							emptyStateLabel={emptyStateLabel}
							onSelectAllRows={setIsAllRowsSelected}
							isAllRowsSelected={isAllRowsSelected}
						/>
						<ButtonMore
							hasNextPage={hasNextPage}
							isLoading={isLoading}
							onClick={() => {
								void (async () => {
									await fetchMore({ variables: { cursor } })
								})()
							}}
						/>
					</Paper>
				</Stack>
			)}
		</Box>
	)
}

import { useTranslation } from 'react-i18next'
import { VideoEmbed } from '@ui/core/VideoEmbed'
import { useUrlValidation } from '../../hooks/useUrlValidation'
import { ExternalLink } from '@shared/components/links/ExternalLink'
import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'

interface Props {
	link: string
}

export const PresentationLink = ({ link }: Props): ReactElement => {
	const { t } = useTranslation(['translation'])
	const [isUrl, isYoutubeUrl] = useUrlValidation()

	if (!isUrl(link)) {
		let message = t('translation:candidates.informations.unvalidLink', { link })

		if (link === '') message = t('translation:candidates.informations.noVideo')

		return <Typography style={{ textAlign: 'center', lineHeight: '100px', height: '100px' }}>{message}</Typography>
	}

	if (isYoutubeUrl(link)) return <VideoEmbed src={link} style={{ height: 600 }} />

	return <ExternalLink url={link} />
}

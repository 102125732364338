import { type ApolloQueryResult } from '@apollo/client'
import { Filters } from '@features/lists/components/Filters'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { type ReactElement } from 'react'
import { InterviewLateFilter } from './InterviewLateFilter'
import { TrackOfferFilter } from '@features/lists/components/Filters/TrackOfferFilter'
import { TrackPositionFilter } from '@features/lists/components/Filters/TrackPositionFilter'
import { AssessorsFilter } from '@features/lists/components/Filters/AssessorsFilter'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import LocationFilter from '@features/lists/components/Filters/LocationsFilter'
import BusinessUnitFilter from '@features/lists/components/Filters/BusinessUnitFilter'
import { PromotionFilter } from '@features/lists/components/Filters/PromotionFilter'
import { CustomFieldsFilters } from '@features/lists/components/Filters/customFields/CustomFieldsFilters'
import { InterviewTypeIdFilter } from '@features/lists/components/Filters/InterviewTypeIdFilter'
import { InterviewUserIntegrationStatusFilter } from './filter/InterviewUserIntegrationStatusFilter'
import { filterOperation } from '@ui/filter/FilterForm'
import { useCustomTranslation } from '#translations/useCustomTranslation'
import { useRole } from '@shared/hooks/useRole'

interface Props {
	refetch: () => Promise<ApolloQueryResult<unknown>>
}

export const InterviewsFilters = ({ refetch }: Props): ReactElement => {
	const { t } = useCustomTranslation()
	const { isAssessor } = useRole()

	return (
		<Filters refetch={refetch}>
			<FiltersContent>
				<Fullname firstNameKey="userFirstnameLowercase" lastNameKey="userLastnameLowercase" />
				<TextFilter keyName="userRef.externalId" label={t('lists:filter.externalId')} />
			</FiltersContent>
			<FiltersContent>
				<InterviewLateFilter keyName="hasOnlyLateInterview" />
				<TrackOfferFilter keyName="orgaOfferId" labelAll={t('lists:filter.offer.allLabel')} />
				<TrackPositionFilter keyName="orgaPositionId" />
				{!isAssessor && <AssessorsFilter keyName="juryId" />}
				<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
				<CheckboxInput keyName="stepsState.interview" translationKey="lists:filter.realised" />
				<CheckboxInput keyName="stepsState.interviewSignature" translationKey="lists:filter.signed" />
				<LocationFilter isBasedOnOrgaType />
				<BusinessUnitFilter />
				<PromotionFilter />
				<CustomFieldsFilters />
				<InterviewTypeIdFilter />
				<InterviewUserIntegrationStatusFilter />
				<TextFilter
					type="date"
					keyName="targetDate"
					operation={filterOperation.GREATER_THAN_EQUAL}
					label={t('lists:filter.minTargetDate')}
					InputLabelProps={{ shrink: true }}
				/>
				<TextFilter
					type="date"
					keyName="targetDate"
					operation={filterOperation.LOWER_THAN_EQUAL}
					label={t('lists:filter.maxTargetDate')}
					InputLabelProps={{ shrink: true }}
				/>
			</FiltersContent>
		</Filters>
	)
}

import { INITIALIZE_QUERYCONTEXT, SET_QUERYCONTEXT, SET_QUERY } from './queryContext.actions'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
	limit: 20,
	lastVisible: null,
	isEndOfResults: false,
	query: []
}

const queryContext = function (state = initialState, action) {
	switch (action.type) {
		case SET_QUERYCONTEXT: {
			return action.payload
		}
		case INITIALIZE_QUERYCONTEXT:
		case LOCATION_CHANGE: {
			return initialState
		}
		case SET_QUERY: {
			const copy = state
			copy.query = action.payload
			return copy
		}
		default: {
			return state
		}
	}
}

export default queryContext

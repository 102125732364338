import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import clsx from 'clsx'

import { makeStyles } from 'tss-react/mui'

/// TABLE HEAD ///

const useRowStyle = makeStyles()(() => ({
	root: {},
	cell: {},
	cellContent: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'center',
		width: '100%',
		height: '100%',

		'& > *': {
			flex: 1
		}
	},
	checkboxCell: {
		width: 74,
		maxWidth: 74,
		'& > $cellContent': {
			width: 'min-content',
			maxWidth: 'min-content'
		}
	},
	header: {},
	stickyHeader: {
		position: 'sticky'
	}
}))

export const DataTableHeader = (props) => {
	const { columns, selectable, onSelectAll, allSelected } = props
	const { classes } = useRowStyle(props)
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const colEls = columns.map((el) => (
		<TableCell key={el.key || el.label} align={el.align} style={el.width ? { width: el.width } : undefined}>
			{el.label || el.key}
		</TableCell>
	))

	return (
		<TableHead className={clsx(classes.header)}>
			{selectable && (
				<TableCell align="center" className={clsx(classes.checkboxCell)}>
					<div className={classes.cellContent}>
						<div>
							<Tooltip title={t('lists:dataGrid.selectAll')}>
								<Checkbox onChange={(e) => onSelectAll(e.target.checked)} checked={allSelected} />
							</Tooltip>
						</div>
					</div>
				</TableCell>
			)}
			{colEls}
		</TableHead>
	)
}

import { Avatar } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'
import { AvatarCard } from './AvatarCard'

interface Props {
	title: string
	children?: ReactNode
	actions?: (isHovered: boolean) => ReactNode
	to?: string
	linkLabel?: string
	hasLink?: boolean
	avatarUrl: string
}

export const OrganizationCard = ({
	title,
	actions,
	children,
	to,
	linkLabel = '',
	avatarUrl,
	hasLink = true
}: Props): ReactElement | null => {
	return (
		<AvatarCard
			avatar={<Avatar src={avatarUrl} sx={{ borderRadius: 1, width: 65, height: 65 }} />}
			{...{ actions, to, linkLabel, hasLink, title }}
		>
			{children}
		</AvatarCard>
	)
}

import { type ReactElement } from 'react'
import { Stack, type SxProps, Typography } from '@mui/material'

interface Props {
	question: string
	answer: string
	sx?: SxProps
}

export const MotivationVideoAnswer = ({ question, answer, sx = {} }: Props): ReactElement | null => {
	return (
		<Stack sx={sx}>
			<Typography component="div" sx={{ mb: 1, fontWeight: 'bold' }}>
				{question}
			</Typography>
			{answer !== '' ? (
				<video controls height={350}>
					<source src={answer} />
				</video>
			) : null}
		</Stack>
	)
}

import { Stack, type StackProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import PlaceIcon from '@mui/icons-material/Place'

interface Props extends StackProps {
	location: string
}

export const OfferLocation = ({ location, ...stackProps }: Props): ReactElement => {
	return (
		<Stack direction="row" gap={1} alignItems="center" {...stackProps}>
			<PlaceIcon sx={{ width: 18 }} />
			<Typography sx={{ fontSize: '14px', color: 'grays.gray3' }}>{location}</Typography>
		</Stack>
	)
}

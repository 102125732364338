import { TableCell, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { type ReactElement, type ReactNode } from 'react'

interface Props {
	icon?: ReactNode
	children: ReactNode
	light?: boolean
	verticalAlign?: string
}

export const InfoLayoutLabel = ({ icon, children, light = false, verticalAlign = 'top' }: Props): ReactElement => {
	const fontWeight = light ? 'lighter' : 'bold'

	return (
		<TableCell sx={{ textWrap: 'nowrap', verticalAlign }}>
			<Stack direction="row" spacing={1}>
				<Typography color="primary">{icon}</Typography>
				<Typography color="primary" sx={{ fontWeight, whiteSpace: 'pre' }}>
					{children}
				</Typography>
			</Stack>
		</TableCell>
	)
}

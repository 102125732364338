import { IncludeExcludeCheckbox } from '@ui/core/IncludeExcludeCheckbox'
import { InputFilter } from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
interface Props {
	keyName: string
	translationKey: string
	defaultValue?: boolean
	isReversedValue?: boolean
}

export const CheckboxInput = ({
	keyName,
	translationKey,
	defaultValue,
	isReversedValue = false
}: Props): ReactElement => {
	return (
		<InputFilter keyName={keyName} defaultValue={defaultValue}>
			<IncludeExcludeCheckbox translationKey={translationKey} isReversedValue={isReversedValue} />
		</InputFilter>
	)
}

import { IconButton } from '@mui/material'
import { type ReactElement } from 'react'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
	onClick: () => void
}

export const ModalCloseButton = ({ onClick }: Props): ReactElement => {
	return (
		<IconButton
			aria-label="close"
			onClick={onClick}
			sx={{
				position: 'absolute',
				right: 8,
				top: 8,
				color: 'grays.gray2'
			}}
		>
			<CloseIcon />
		</IconButton>
	)
}

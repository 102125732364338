import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'
import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

interface Props {
	setPassword: (password: string) => void
	password: string
}

export const PasswordInput = ({ setPassword, password }: Props): ReactElement => {
	const { t } = useTranslation()
	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const tooglePasswordVisibility = (): void => {
		setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible)
	}

	return (
		<FormControl fullWidth variant="standard">
			<InputLabel htmlFor="password">{t('auth:passwordInputLabel')}</InputLabel>
			<Input
				id="password"
				autoComplete="new-password"
				fullWidth
				type={isPasswordVisible ? 'text' : 'password'}
				onChange={(e) => {
					setPassword(e.target.value)
				}}
				value={password}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visiblity"
							onClick={tooglePasswordVisibility}
							onMouseDown={tooglePasswordVisibility}
							edge="end"
						>
							{isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	)
}

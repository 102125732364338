import { type ReactElement, useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import { StudentsFilterBar } from './StudentsFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetStudentsListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { StudentCard } from './StudentCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'
import { Mode, ModeToggle } from '@application/dashboard/ModeToggle'
import { generateRoute } from '../../../router/routes'
import { StudentsRouter } from '../Students.router'
import { useHistory } from 'react-router-dom'

export const StudentsList = (): ReactElement => {
	const { t } = useTranslation(['lists', 'students'])
	useDocumentTitle(t('students:title'))
	useCheckRoleSecurity(ScreenId.Students)
	const { filters } = useFilters()
	const history = useHistory()

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetStudentsListQuery({ variables })
	useDefaultFilter({ refetch })

	const { nodes = [], cursor = '', hasNextPage = false, count = 0 } = data?.getStudentsList ?? {}

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('students:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<StudentCard key={item.id} position={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				title={t('students:title', { count })}
				refetch={refetch}
				hasSearch
				toolbar={
					<StudentsFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
				headerLeftComponent={
					<ModeToggle
						mode={Mode.List}
						onToggle={() => {
							history.push(generateRoute(StudentsRouter.paths.dashboard))
						}}
					/>
				}
			/>
		</Container>
	)
}

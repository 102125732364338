import { type ReactElement, useState } from 'react'
import { Slider } from '@ui/core/Slider'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

interface Props {
	score: number
	startLabel: string
	endLabel: string
	description: string
}

export const DimensionSlider = ({ score, startLabel, endLabel, description }: Props): ReactElement => {
	const theme = useTheme()
	const [isExpanded, setExpanded] = useState(false)

	const min = -0.5
	const max = 0.5
	const value = [score, 0].sort((a, b) => a - b)

	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'stretch',
					alignItems: 'center'
				}}
			>
				<div
					style={{
						flex: 1
					}}
				>
					<Slider value={value} readOnly min={min} max={max} startLabel={startLabel} endLabel={endLabel} />
				</div>
				{description !== '' ? (
					<IconButton
						style={{
							marginLeft: 10,
							color: theme.palette.grays.gray2
						}}
						size="small"
						onClick={() => {
							setExpanded(!isExpanded)
						}}
					>
						<HelpOutlineIcon />
					</IconButton>
				) : null}
			</div>
			<Collapse in={isExpanded}>
				<Typography
					variant="body2"
					style={{
						marginTop: 15,
						marginBottom: 15
					}}
				>
					{description}
				</Typography>
			</Collapse>
		</div>
	)
}

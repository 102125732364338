import { ButtonRouter } from '../shared/components/layout/ButtonRouter'
import { ROUTE_HOME } from '../router/routes'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from '../shared/hooks/useDocumentTitle'
import { type ReactElement } from 'react'

export const NotFound = (): ReactElement => {
	const { t } = useTranslation()
	useDocumentTitle(t('translation:notFound.title'))

	return (
		<div
			style={{
				padding: '1rem',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<img
				style={{
					marginBottom: '20px',
					height: '70vh',
					maxHeight: '522px'
				}}
				alt="Page not found"
				src="/assets/img/404.png"
			/>
			<ButtonRouter to={ROUTE_HOME}>{t('notFound.backHome')}</ButtonRouter>
		</div>
	)
}

import { type GetIntegrationsProgressionsQuery } from '@gqlTypes'
import { Stack, Typography, Box } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { GraphRadarLegend } from './legend/GraphRadarLegend'

interface Props {
	skills: GetIntegrationsProgressionsQuery['getIntegrationsProgressions']['skills'][0][]
}

export const Legend = ({ skills }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<Box sx={{ height: '100%', pb: 2 }}>
			<Stack direction="column" spacing={2} sx={{ mb: 5, pt: 2 }}>
				<Stack direction="row" alignItems="center" spacing={1}>
					<Box
						sx={{
							backgroundColor: 'black',
							height: 7,
							width: 7,
							borderRadius: 10
						}}
					/>
					<Typography>{t('interview:progression.radar.legend.autoEvaluation')}</Typography>
				</Stack>
				<Stack direction="row" alignItems="center" spacing={1}>
					<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M14.0597 2.38724C13.8609 2.29129 13.6591 2.19914 13.4544 2.11091C8.98531 0.184561 4.10547 0.547717 0.124756 2.67959C1.2323 4.7919 2.32388 6.76971 3.32836 8.53319C5.56912 7.35133 8.30549 7.15681 10.813 8.23767C11.0449 8.33763 11.2703 8.44651 11.4888 8.56373L14.0597 2.38724Z"
							fill="black"
						/>
					</svg>
					<Typography>{t('interview:progression.radar.legend.evaluation')}</Typography>
				</Stack>
			</Stack>
			<GraphRadarLegend dimensions={skills} columnsNumber={4} noSpace />
		</Box>
	)
}

import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { Alert, AlertTitle, CardContent, Container, Typography } from '@mui/material'
import { CardWithLogo } from '@shared/components/CardWithLogo'

export const InvitationNotFound = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<Container>
			<Stack spacing={2} alignItems="center" mt={2}>
				<Typography variant="h5" pb={3}>
					<b>{t('auth:accountCreation')}</b>
				</Typography>
				<CardWithLogo>
					<CardContent>
						<Alert severity="error">
							<AlertTitle>
								<b>{t('auth:errors.invitationNotFound.title')}</b>
							</AlertTitle>
							{t('auth:errors.invitationNotFound.message')}
						</Alert>
					</CardContent>
				</CardWithLogo>
			</Stack>
		</Container>
	)
}

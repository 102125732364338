import CheckBox from '@shared/components/forms/fields/CheckBoxField'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import { useTranslation } from 'react-i18next'
import { type Control } from 'react-hook-form'

interface Props {
	control: Control
	defaultValue?: boolean
	name: string
}

export default function CheckBoxField({ control, defaultValue, name }: Props): JSX.Element {
	const { t } = useTranslation()
	const title = t(`organizationUsers:details.formFields.${name}.title`)
	const tooltip = t(`organizationUsers:details.formFields.${name}.tooltip`)
	return (
		<TableLayoutRow title={title} tooltip={tooltip}>
			<CheckBox control={control} name={name} defaultValue={defaultValue} />
		</TableLayoutRow>
	)
}

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GeneralInfoCard from './cards/GeneralInfoCard'
import useTracksDetails, { type TrackInputToSave } from './useTrackDetail'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import TrackPublicationCard from './cards/TrackPublicationCard'
import MultiSelectCard from './cards/MultiSelectCard'
import SkillSelector from './popups/SkillSelector'
import { useTranslation } from 'react-i18next'
import DetailBar from './DetailBar'
import { useLocation } from 'react-router-dom'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import TrackSharingCard from './cards/TrackSharingCard'
import TrackMemberCard from './cards/TrackMemberCard'
import TrackInterviewCard from './cards/TrackInterviewCard'
import { Container, Grid, Stack } from '@mui/material'

export default function TrackDetail(): JSX.Element {
	const location = useLocation().pathname
	const isOffer = location.includes('organization-offer')
	const {
		organizationIntegration,
		categories,
		offerTypes,
		userFunctions,
		isLoading,
		isSaving,
		saveTrack,
		offerStatus,
		trackUseCases,
		gqlFormErrors
	} = useTracksDetails(isOffer)
	const { control, errors, formState, register, reset, watch, handleSubmit } = useForm({ mode: 'onChange' })
	const { t } = useTranslation()
	useDocumentTitle(t('tracks:details.document.title'))
	const onSubmit = async (data: TrackInputToSave): Promise<void> => {
		await saveTrack(data)
	}

	useEffect(() => {
		reset({ category: organizationIntegration?.category })
	}, [organizationIntegration])

	const {
		availableSteps,
		availableInterviewSteps,
		selectedSkills,
		availableMotivationsQuestions,
		availableMotivationsLabelsGroups,
		availableInterviewQuestions
	} = organizationIntegration ?? {
		availableSteps: [],
		availableInterviewSteps: [],
		selectedSkills: [],
		availableMotivationsQuestions: [],
		availableMotivationsLabelsGroups: [],
		availableInterviewQuestions: []
	}

	return (
		<Container maxWidth="xl">
			<form
				onSubmit={(e) => {
					e.preventDefault()
					void handleSubmit(onSubmit)()
				}}
			>
				<DetailBar formIsValid={formState.isValid} isSaving={isSaving} />
				<LocalLoader withPadding load={isLoading}>
					<Grid container spacing={3} sx={{ mt: 0 }}>
						<Grid item xs={6}>
							<Stack spacing={3}>
								<GeneralInfoCard
									control={control}
									errors={errors}
									details={organizationIntegration}
									categories={categories}
									offerTypes={offerTypes}
									userFunctions={userFunctions}
									isOffer={isOffer}
									watch={watch}
									gqlFormErrors={gqlFormErrors}
									trackUseCases={trackUseCases}
								/>
								<TrackInterviewCard
									control={control}
									errors={errors}
									details={organizationIntegration}
									offerStatus={offerStatus}
									isOffer={isOffer}
								/>
								<MultiSelectCard
									items={availableInterviewQuestions}
									title={t('tracks:details.cards.interviewQuestions')}
									fieldName="interviewQuestionIds"
									register={register}
								/>
								<MultiSelectCard
									items={availableSteps}
									title={t('tracks:details.cards.trackSteps')}
									fieldName="steps"
									register={register}
								/>
								<MultiSelectCard
									items={availableInterviewSteps}
									title={t('tracks:details.cards.interviewSteps')}
									fieldName="interviewSteps"
									register={register}
								/>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack spacing={3}>
								<TrackPublicationCard
									control={control}
									errors={errors}
									details={organizationIntegration}
									isOffer={isOffer}
								/>
								<TrackSharingCard control={control} errors={errors} details={organizationIntegration} />
								{!isOffer && <TrackMemberCard control={control} errors={errors} details={organizationIntegration} />}
								<SkillSelector fieldName="skills" selectedSkills={selectedSkills} register={register} />
								<MultiSelectCard
									items={availableMotivationsQuestions}
									title={t('tracks:details.cards.motivationQuestion')}
									fieldName="motivationsQuestions"
									register={register}
								/>
								<MultiSelectCard
									items={availableMotivationsLabelsGroups}
									title={t('tracks:details.cards.MotivationGroup')}
									fieldName="motivationsLabelsGroups"
									register={register}
								/>
							</Stack>
						</Grid>
					</Grid>
				</LocalLoader>
			</form>
		</Container>
	)
}

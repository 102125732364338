import { OffersPath } from '@domains/offers/OffersRouter'
import {
	JobMatchingStatusId,
	type GetPublicResumeQuery,
	useUpdateJobStatusMutation,
	GetUserOfferJobMatchingStatusCountDocument
} from '@gqlTypes'
import { IconButton } from '@mui/material'
import { useState, type ReactElement } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { generateRoute } from '../../../../../router/routes'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { LikeByOrgaModal } from '../like/LikeByOrgaModal'
import { MatchedModal } from '../like/MatchedModal'
import { SelectedModal } from '../like/SelectedModal'
import { DislikeButton } from './DislikeButton'
import { LikeButton, type LikeNextStatus } from './LikeButton'
import { useGqlSnackBar } from '@application/snackbar/useGqlSnackBar'

interface Props {
	profiles: GetPublicResumeQuery['getUserOfferJobMatching']
	trackOfferId: string
	browserStatusId: string
	jobMatchingId: string
	jobMatchingStatusId: JobMatchingStatusId
	firstname: string
	offername: string
}

export const JobMatchingBrowserButtons = ({
	jobMatchingId,
	profiles,
	trackOfferId,
	browserStatusId,
	jobMatchingStatusId,
	firstname,
	offername
}: Props): ReactElement => {
	const history = useHistory()
	const { onError } = useGqlSnackBar('offers:jobMatching.browser')
	const [update] = useUpdateJobStatusMutation({
		onError,
		refetchQueries: [{ query: GetUserOfferJobMatchingStatusCountDocument, variables: { trackOfferId } }]
	})

	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [modalJobStatus, setModalJobStatus] = useState<LikeNextStatus | JobMatchingStatusId.Matched>(
		JobMatchingStatusId.Matched
	)

	const currentProfileIndex = profiles?.findIndex(({ jobMatching }) => jobMatching?.id === jobMatchingId) ?? 0
	const previousProfile = profiles[currentProfileIndex - 1]
	const nextProfile = profiles[currentProfileIndex + 1]
	const backRouteProfile: string = generateRoute(OffersPath.matchingBrowser, {
		trackOfferId,
		statusId: browserStatusId,
		jobMatchingId: previousProfile?.jobMatching?.id,
		userId: previousProfile?.userOffer?.userInfo?.userId ?? ''
	})
	const nextRouteProfile: string = generateRoute(OffersPath.matchingBrowser, {
		trackOfferId,
		statusId: browserStatusId,
		jobMatchingId: nextProfile?.jobMatching?.id,
		userId: nextProfile?.userOffer?.userInfo?.userId ?? ''
	})

	const listRoute: string = generateRoute(OffersPath.matchings, { trackOfferId })

	const handleLike = (nextStatus: LikeNextStatus): void => {
		const isMatch =
			jobMatchingStatusId === JobMatchingStatusId.LikedByCandidate &&
			nextStatus === JobMatchingStatusId.LikedByOrganization
		if (nextStatus !== JobMatchingStatusId.Selected) {
			void update({ variables: { jobMatchingId, statusId: nextStatus } })
		}
		setModalJobStatus(isMatch ? JobMatchingStatusId.Matched : nextStatus)
		setIsOpen(true)
	}

	const handleDislike = (): void => {
		void update({ variables: { jobMatchingId, statusId: JobMatchingStatusId.DislikedByOrganization } })
		const route = nextProfile != null ? nextRouteProfile : listRoute
		history.push(route)
	}

	const modalProps = { isOpen, setIsOpen, route: nextProfile != null ? nextRouteProfile : listRoute }

	return (
		<>
			<IconButton
				sx={{ backgroundColor: 'white' }}
				to={backRouteProfile}
				component={Link}
				disabled={previousProfile === undefined}
			>
				<ChevronLeftIcon />
			</IconButton>
			<DislikeButton jobMatchingStatusId={jobMatchingStatusId} onClick={handleDislike} />
			<LikeButton jobMatchingStatusId={jobMatchingStatusId} onClick={handleLike} />
			<IconButton
				sx={{ backgroundColor: 'white' }}
				to={nextRouteProfile}
				component={Link}
				disabled={nextProfile === undefined}
			>
				<ChevronRightIcon />
			</IconButton>
			{
				{
					[JobMatchingStatusId.LikedByOrganization]: <LikeByOrgaModal {...{ ...modalProps, firstname }} />,
					[JobMatchingStatusId.Matched]: <MatchedModal {...{ ...modalProps, firstname }} />,
					[JobMatchingStatusId.Selected]: (
						<SelectedModal {...{ ...modalProps, jobMatchingId, offername, listRoute, trackOfferId }} />
					)
				}[modalJobStatus]
			}
		</>
	)
}

import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'

interface Props {
	row: Record<string, string>
	column: { label: string; key: string }
}

export const DefaultRenderCell = ({ row, column }: Props): ReactElement => {
	if (row == null) return <></>

	return <Typography>{row[column.key ?? column.label]}</Typography>
}
